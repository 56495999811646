import React, { useContext, useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import { Wrapper } from "./style";

import { Breadcrumb, Empty, Modal, Spin, Table, message } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { MakeApiCall } from "../apis";
import { GlobalContext } from "../common-context";
import LoadingRoot from "../components/root-loading";
import PageRouter from "../routes";
import Icons from "../components/icons";
import CarouselView from "../components/progress-view";
import { LOGINURL, RenderTable } from "../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import CredentialsError from "./credential-page";
import { LoadingModal } from "../components/upload-modals";

const Page = () => {
  const history = useHistory();
  const [menuHide, setMenuHide] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [usersView, setUsersView] = useState(false);

  const contextValue = useContext(GlobalContext);
  const user = contextValue?.data?.user_;
  const type = user?.user_type === 1 ? "admin" : "users";

  const [spAPI, setSpAPI] = useState({
    sp: "checking",
    lws: "checking",
  });

  const location = useLocation();

  useEffect(() => {
    setUsersView(false);
    if (
      (location?.pathname === "/callbackads" && location?.search) ||
      (location?.pathname === "/spapi-callback" && location?.search)
    ) {
      // localStorage.setItem("currantUrl", "/setting/spapi-callback");
      return;
    } else {
      localStorage.setItem("currantUrl", location?.pathname);
    }
  }, [location]);

  const switchUser = async (value, email) => {
    message.destroy();

    LoadingModal("Seller User", "Switching User...");
    try {
      const response = await MakeApiCall(
        `seller-user/switch-seller/${value}?email=${
          JSON.parse(localStorage.getItem("admin") || {})?.user_data?.email
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        localStorage.setItem(
          "credentials",
          JSON.stringify(response?.data?.credentials)
        );
        localStorage.setItem("user", JSON.stringify(response?.data?.user_data));
        localStorage.setItem("token", response?.data?.auth_token);
        localStorage.setItem("user-type", response?.data?.user_data?.user_type);

        message.destroy();
        message.success(response?.message);

        contextValue?.updateCommonGlobalVal({
          user_: response?.data?.user_data,
          credentials: response?.data?.credentials,
        });
        window.location.reload();
      } else {
        Modal.destroyAll();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  const clearLocalData = () => {
    localStorage.clear();
    window.location.assign(localStorage.getItem("root") || "/seller/login");
    window.location.reload();
  };
  const getNewToken = async () => {
    if (!localStorage.getItem("token")) {
      clearLocalData();
      return;
    }
    try {
      const response = await MakeApiCall(
        `user/refresh-token`,
        "get",
        null,
        true
      );

      if (response?.status) {
        localStorage.setItem("token", response?.data?.auth_token);
      } else {
        message.warning(response?.message);
      }
    } catch (error) {}
  };

  function capitalizeFirstLetterEachWord(str) {
    // Split the string into an array of words
    let words = str.split("-")?.join(" ")?.split(" ");

    // Capitalize the first letter of each word
    let capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a string
    let result = capitalizedWords.join(" ");

    return result;
  }
  const breadcrumb = [
    {
      title: (
        <Link to="/dashboard">
          <Icons type="home" />
        </Link>
      ),
    },
  ].concat(
    (location?.pathname?.split("/")?.filter((d) => d) || []).map(
      (d, i, rest) => {
        if (type === "admin") {
          return {
            title:
              capitalizeFirstLetterEachWord(d) === "Spapi Callback"
                ? "Connections"
                : capitalizeFirstLetterEachWord(d),
          };
        }
        const find = list?.find((f) => f?.url === rest?.[0]);
        const arrayOfArrays = list
          ?.map((g) => g?.children) // Map to get arrays of children
          ?.map((arr) => arr) // Optional map to simply return the array
          ?.reduce((acc, val) => acc?.concat(val), []);

        const submenu = arrayOfArrays?.find((r) => r?.url === rest?.[0]);

        if (i === 0) {
          if (find?.url) {
            return {
              title: <Link to={`/${find?.url}`}>{find?.title || ""}</Link>,
            };
          }
          return {
            title: find?.title || submenu?.title || "",
          };
        }

        return {
          title:
            find?.children?.find((r) => r?.url === d)?.title ||
            submenu?.title ||
            "",
        };
      }
    )
  );

  const getList = async () => {
    try {
      const Status = JSON.parse(localStorage.getItem("user"));
      const obj = {
        sp: parseInt(Status?.amazon_connected),
        lws: parseInt(Status?.amazon_ads_connected),
      };

      setSpAPI(obj);
      contextValue?.updateCommonGlobalVal({
        credentialsStatus: obj,
      });
    } catch (error) {
      message.warning(error?.message);
    }
  };
  const logoutAction = async () => {
    if (parseInt(contextValue?.data?.user_?.user_type) === 1) {
      const LOGINURL_ = LOGINURL;
      localStorage.clear();
      window.location.href = LOGINURL_;
      return;
    }
    const response = await MakeApiCall(`seller-logout`, "post", null, true);

    if (response?.status) {
      const LOGINURL_ = LOGINURL;

      localStorage.clear();
      history.push(LOGINURL_);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (type !== "admin") {
      const obj = {
        sp: "checking",
        lws: "checking",
      };
      setSpAPI(obj);
      getList();
    } else {
      const obj = {
        sp: "checking",
        lws: "checking",
      };
      setSpAPI(obj);
    }
  }, [type]);

  const handleStorageChange = (e) => {
    e.stopPropagation();
    if (e.url === window.location.href) {
      if (!e.key) {
        clearLocalData();
      } else {
        localStorage.setItem(e.key, e.oldValue);
      }
    }
  };
  useEffect(() => {
    // Add event listener for the storage event
    window.addEventListener("storage", handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    getNewToken();
    const min = 60; // 60
    const sessionTimeout = min * 60 * 1000; // 15 minutes in milliseconds
    let timer;

    const min1 = 55; // 60
    const sessionTimeout1 = min1 * 60 * 1000; // 15 minutes in milliseconds
    let timer1;

    let timerAPI;
    let timerAPI1;

    timerAPI1 = setInterval((e) => {
      getNewToken();
    }, sessionTimeout1);

    const resetTimer = () => {
      clearTimeout(timer);

      timer = setTimeout((e) => {
        clearInterval(timerAPI);
        logoutAction();
      }, sessionTimeout);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    // Attach event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keypress", handleUserActivity);

    // Initialize the timer
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timer);
      clearTimeout(timer1);
      // clearTimeout(timerAPI);
      // clearTimeout(timerAPI);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    };
  }, []);

  const allList = [
    ...list
      ?.map((g) => {
        return g?.children?.map((d) => {
          return {
            ...d,
            is_restricted: g?.is_restricted === 0 ? 0 : d?.is_restricted,
          };
        });
      })
      ?.map((arr) => arr)
      ?.reduce((acc, val) => acc?.concat(val), []),
    ...list,
  ];

  if (!localStorage?.getItem("token")) {
    sessionStorage.setItem("currantUrl", window.location?.pathname);
    return <Redirect to={LOGINURL} />;
  }
  if (
    (spAPI?.sp === "checking" || spAPI?.lws === "checking") &&
    type !== "admin"
  ) {
    return <LoadingRoot />;
  }

  return (
    <Wrapper className="d-flex flex-column flex-root">
      <div
        className={`${
          type === "admin" ? "admin-menu" : "user-menu"
        } page d-flex flex-row flex-column-fluid`}
        style={{
          filter: contextValue?.data?.dropdownOpen ? "blur(4px)" : "blur(0px)",
        }}
      >
        <Sidebar
          user={user}
          type={type}
          setMenuHide={setMenuHide}
          menuHide={menuHide}
          setList={setList}
          list={list}
          allList={allList}
          setUsersView={setUsersView}
          setLoading={setLoading}
          loading={loading}
        />
        {menuHide && (
          <div
            style={{ zIndex: 109 }}
            onClick={() => setMenuHide(false)}
            className="drawer-overlay"
          />
        )}
        <div
          className={`wrapper d-flex flex-column flex-row-fluid ${
            menuHide ? "expandView" : ""
          }`}
          style={{ height: "100vh", overflow: "auto" }}
          id="kt_wrapper"
        >
          <Topbar
            user={user}
            type={type}
            setMenuHide={setMenuHide}
            menuHide={menuHide}
            setUserList={setUserList}
            userList={userList}
            setUsersView={setUsersView}
            usersView={usersView}
            switchUser={switchUser}
          />
          {usersView ? (
            <div
              className="content d-flex flex-column flex-column-fluid pt-2"
              id="kt_content"
              style={{ position: "relative" }}
            >
              <div className="post px-5 mt-5" id="kt_post">
                <div className="card">
                  <div className="row">
                    <div className="col-12 py-3 px-7 d-flex justify-content-end ">
                      <Icon
                        onClick={() => {
                          setUsersView(false);
                        }}
                        icon={"radix-icons:cross-2"}
                        style={{ cursor: "pointer" }}
                        width={15}
                        height={15}
                      />
                    </div>
                  </div>
                  <div className="card-body custom-ui user-table-list pt-0">
                    <Table
                      columns={[
                        {
                          title: "ID",
                          align: "center",
                          render: (_, __, i) => {
                            return <div className="w-100">{i + 1}</div>;
                          },
                        },
                        {
                          title: "Seller Name",
                          key: "seller_name",
                          dataIndex: "seller_name",
                          // filterIndex: "seller_name",
                          align: "left",

                          sorter: (a, b) =>
                            a.seller_name?.localeCompare(b.seller_name),
                          render: (e) => {
                            return <span>{e}</span>;
                          },
                        },
                        {
                          title: "SIP Account Holder",
                          align: "left",
                          filterIndex: "name",
                          sorter: (a, b) => a.name?.localeCompare(b.name),
                          render: (d) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <a href>
                                    <div
                                      className="symbol-label fs-3 fw-bolder bg-light-danger text-danger"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {d?.name?.[0]}
                                    </div>
                                  </a>
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-gray-800 text-hover-primary mb-1 fw-bolder"
                                  >
                                    {d?.name || "-"}
                                  </a>
                                  <span>{d?.email || "-"}</span>
                                </div>
                              </div>
                            );
                          },
                        },

                        {
                          title: "Select Seller",

                          render: (d) => {
                            return (
                              <div class=" pe-2 ">
                                <a
                                  onClick={() => switchUser(d?.id, d?.email)}
                                  className=" add-btn fs-7 fw-bolder"
                                  style={{ width: "fit-content" }}
                                >
                                  <i
                                    className="ki-outline ki-arrow-right-left fs-3  me-2"
                                    style={{ color: "#FFF" }}
                                  ></i>{" "}
                                  Select Seller
                                </a>
                              </div>
                            );
                          },
                        },
                      ]?.map((d) => ({
                        ...d,

                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      dataSource={userList}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      scroll={{ x: "max-content" }}
                      pagination={false}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="content d-flex flex-column flex-column-fluid pt-2 pb-2"
              id="kt_content"
              style={{ position: "relative" }}
            >
              {loading && type !== "admin" ? (
                <LoadingRoot title="Get Menus..." />
              ) : list?.length === 0 && type !== "admin" ? (
                <Empty
                  style={{
                    inset: 0,
                    margin: "auto",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                />
              ) : (
                <>
                  <div className="mx-10 mt-2">
                    <Breadcrumb items={breadcrumb} />
                  </div>

                  {type === "users" && <CarouselView />}

                  {spAPI?.sp === 0 ||
                  spAPI?.lws === 0 ||
                  ((spAPI?.sp === 2 || spAPI?.lws === 2) &&
                    type !== "admin") ? (
                    <>
                      {location?.pathname !== "/spapi-callback" &&
                      location?.pathname !== "/callbackads" ? (
                        <CredentialsError show={true} type="dashboard" />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <PageRouter
                      pageTitle={breadcrumb?.slice(-1)[0]?.title}
                      type={type}
                      menusList={allList}
                    />
                  </div>
                </>
              )}
            </div>
          )}

          <Footer />
        </div>
      </div>
    </Wrapper>
  );
};

export default Page;
