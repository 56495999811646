import { Spin, Tabs, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import InfoVideo from "../../../components/header-icons";
import CredentialsError from "../../credential-page";
import { Wrapper } from "./style";
import { useHistory, useLocation } from "react-router-dom";

const { TabPane } = Tabs;

const Resources = ({ menusList }) => {
  const contextValue = useContext(GlobalContext);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { lws, sp } = contextValue?.data?.credentialsStatus;

  const [locationData, setLocationData] = useState({});
  const [selectedTab, setSelectedTab] = useState("1");
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=11`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (location?.search) {
      history.push(`/resources?tab=${selectedTab}`);
    }
  }, [selectedTab]);

  useEffect(() => {
    const queryString = location?.search;
    const params = new URLSearchParams(queryString);
    const obj = {};

    params.forEach((value, key) => {
      obj[key] = value;
    });
    if (!location?.search) {
      history.push(`/resources?tab=${selectedTab}`);
    } else {
      setSelectedTab(obj?.tab);
    }

    return () => {};
  }, []);

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const getList = async () => {
    const response = await MakeApiCall(`seller-resource`, "get", null, true);

    if (response?.status) {
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();

    return () => {};
  }, []);

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "resources")?.is_restricted === 1
  ) {
    return <CredentialsError type="resources" />;
  }
  return (
    <Wrapper id="kt_content_container" className="container-fluid pt-5">
      <>
        <Spin spinning={loading} tip="Loading...">
          <div className="row g-2">
            <div className="col-xxl-12">
              {/*begin::Tables Widget 9*/}
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                {/*begin::Header*/}
                <Tabs
                  onChange={(e) => {
                    setSelectedTab(e);
                  }}
                  activeKey={selectedTab}
                  tabBarStyle={{ padding: "0px 0px 0px" }}
                >
                  {list
                    ?.filter((d) => d?.title)
                    ?.map((d, i) => (
                      <TabPane
                        key={(i + 1)?.toString()}
                        tab={
                          <div className="px-5 d-flex">
                            {d?.title}
                            <InfoVideo
                              data={locationData}
                              title={d?.slug}
                              className={"ms-3"}
                            />
                          </div>
                        }
                      >
                        {/* Content for Welcome Page */}
                        <div className="px-5">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: d?.description,
                            }}
                          />
                        </div>
                      </TabPane>
                    ))}
                </Tabs>
              </div>
            </div>
          </div>
        </Spin>
      </>
    </Wrapper>
  );
};

export default Resources;
