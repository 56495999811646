import {
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Popconfirm,
  Popover,
  Row,
  Segmented,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { NumberWithCommas, RenderTable } from "../../../config";
import { Wrapper } from "../data-sources/style";
import { CustomColumns } from "./style";
import dayjs from "dayjs";

import weekOfYear from "dayjs/plugin/weekOfYear";
import { Icon } from "@iconify/react/dist/iconify.js";
// const DatePickerView = React.lazy(() =>
//   import("../data-sources/lib/date-picker-view")
// );
import DatePickerView from "../data-sources/lib/date-picker-view";

dayjs.extend(weekOfYear);

export default function KeywordTracking(props) {
  const {
    presets,
    setKeywordViewAll,
    setKeywordFilterList,
    keywordFilterList,
    dropdownList,
    dropdownLoading,
    keywordData,
    getKeywordTracking,
    keywordLoading,
    setExpandKeyword,
    expandKeyword,
  } = props;

  const columnsView = [
    {
      title: "",
      dataIndex: "keywordIndex",
      key: "keywordIndex",
      className: "highlight-column",

      render: (e, row) => {
        if (!row?.keywordIndex) {
          return (
            <Button
              onClick={() => {
                setExpandKeyword((prev) => {
                  if (prev === row?.cIndex) {
                    return "";
                  }
                  return row?.cIndex;
                });
              }}
              size="small"
            >
              {row?.cIndex === expandKeyword ? "-" : "+"}
            </Button>
          );
        }
        return <div>{""}</div>;
      },
    },
    {
      title: "#",
      dataIndex: "keywordIndex",
      key: "keywordIndex",
      className: "highlight-column",

      render: (e, row) => {
        if (!e) return;
        if (row?.keywordIndex) {
          return (
            <div style={{ fontWeight: 500 }}>{row?.keywordIndex || ""}</div>
          );
        }
        return <div>{""}</div>;
      },
    },
    {
      title: "Keyword/Search Term",

      className: "highlight-column",
      width: 250,
      render: (e, row) => {
        if (row?.label) {
          return (
            <div style={{ color: "#009ffb", fontWeight: 500 }}>
              {row?.label || ""}
            </div>
          );
        }
        return <div>{row?.search_term || ""}</div>;
      },
    },
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      className: "highlight-column",
      width: 350,
      render: (e) => {
        if (!e) return;
        if (e?.split(",")?.length === 1) {
          return e;
        }
        return (
          <Popover
            content={
              <>
                {e?.split(",")?.map((d) => (
                  <div>{d}</div>
                ))}
              </>
            }
            trigger={["hover"]}
          >
            <div className="one-line-ellipsis">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "match_type",
      key: "match_type",
      className: "highlight-column",
      width: 100,
      onFilter: (value, record) => record.match_type.includes(value),
      render: (e) => {
        if (!e) return;
        return (
          <Flex align="baseline">
            <Tooltip title={e} placement="left">
              {(e || "")?.charAt(0)}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: "Traffic Performance",
      width: 250,
      render: (e, row) => {
        if (row?.keywordIndex) return;
        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Impressions
                </span>
                <span style={{ color: "#009ffb" }}>
                  {NumberWithCommas(row?.impressions || 0)}
                </span>
              </Flex>
            </Col>
            {row?.cIndex === expandKeyword && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Clicks
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.clicks || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      CTR
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.click_through_rate || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg Bid
                    </span>
                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.keyword_bid || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: "Listing Performance",

      width: 250,
      render: (e, row) => {
        if (row?.keywordIndex) return;

        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Sales
                </span>

                <span style={{ color: "#009ffb" }}>
                  ${NumberWithCommas(row?.sales || 0)}
                </span>
              </Flex>
            </Col>
            {row?.cIndex === expandKeyword && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Purchases
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.purchases || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CR
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.conversion_rate || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CPC
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      ${NumberWithCommas(row?.cost_per_click || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: "Ad Performance",

      width: 250,

      render: (e, row) => {
        if (row?.keywordIndex) return;

        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Ad Spend
                </span>

                <span style={{ color: "#009ffb" }}>
                  ${NumberWithCommas(row?.spend || 0)}
                </span>
              </Flex>
            </Col>
            {row?.cIndex === expandKeyword && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      ACOS
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.acos || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      ROAS
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.roas || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CPA
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      ${NumberWithCommas(row?.cpa || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const getDays = (start_date, end_date) => {
    const date1 = new Date(start_date);
    const date2 = new Date(end_date);

    const timeDifference = date1 - date2; // Difference in milliseconds
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days

    return dayDifference;
  };

  return (
    <CustomColumns>
      <Flex justify="space-between">
        <Flex gap={6}>
          <Select
            className="w-200px"
            allowClear
            loading={dropdownLoading}
            size="middle"
            options={dropdownList}
            value={keywordFilterList?.sort_by || null}
            onChange={(e) => {
              const obj = {
                ...keywordFilterList,
                sort_by: e,
              };
              setKeywordFilterList(obj);
              getKeywordTracking(obj);
            }}
            placeholder={"Sort By"}
          />
          <Input
            value={keywordFilterList?.search_term}
            className="w-200px"
            onChange={(e) => {
              const obj = {
                ...keywordFilterList,
                search_term: e.target.value,
              };
              setKeywordFilterList(obj);
            }}
            onPressEnter={() => {
              getKeywordTracking(keywordFilterList);
            }}
            placeholder="Search by Search Term"
          />
        </Flex>
        <Flex gap={6}>
          {" "}
          <Wrapper
            style={{ width: "auto", display: "flex", alignItems: "center" }}
          >
            <Tag color="blue-inverse">{keywordFilterList?.reporting_type}</Tag>
            <DatePickerView
              // allowClear
              onChange={(e) => {
                const reporting_type =
                  getDays(
                    dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                    dayjs(e?.start_date)?.format("YYYY-MM-DD")
                  ) === 0 ||
                  isNaN(
                    getDays(
                      dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                      dayjs(e?.start_date)?.format("YYYY-MM-DD")
                    )
                  )
                    ? null
                    : getDays(
                        dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                        dayjs(e?.start_date)?.format("YYYY-MM-DD")
                      ) > 15
                    ? "Monthly"
                    : "Weekly";

                const obj = {
                  ...keywordFilterList,

                  start_date: e?.start_date
                    ? dayjs(e?.start_date)?.format("YYYY-MM-DD")
                    : null,
                  end_date: e?.end_date
                    ? dayjs(e?.end_date)?.format("YYYY-MM-DD")
                    : null,
                  reporting_type: reporting_type,
                  month_no:
                    reporting_type === "Monthly"
                      ? dayjs(e?.start_date)?.format("M")
                      : null,
                  report_year: dayjs(e?.start_date)?.format("YYYY"),
                  week_no:
                    reporting_type === "Weekly"
                      ? dayjs(e?.end_date).week()
                      : null,
                };

                getKeywordTracking(obj);
                setKeywordFilterList(obj);
              }}
              value={
                keywordFilterList?.start_date && keywordFilterList?.end_date
                  ? [keywordFilterList?.start_date, keywordFilterList?.end_date]
                  : null
              }
            />
          </Wrapper>
          <div
            className="d-flex align-items-center  text-hover-primary cursor-pointer"
            onClick={() => {
              setKeywordViewAll(true);
            }}
          >
            View All{" "}
            <div
              className="bg-primary ms-2"
              style={{
                borderRadius: "100%",
                postion: "relative",
                width: "15px",
                height: "15px",
              }}
            >
              {icons}
            </div>
          </div>
        </Flex>
      </Flex>

      {!keywordLoading && (
        <Table
          columns={columnsView?.map((d) => {
            return {
              ...d,
              className: d?.className + "minWidth-table",
              render: (props, row, index) =>
                RenderTable(props, row, index, d, 2),
            };
          })}
          scroll={{ x: "max-content" }}
          dataSource={keywordData}
          pagination={false}
          loading={keywordLoading}
          bordered
          rowKey={"keywordIndex"}
          size="small"
          style={{ marginTop: "10px", marginBottom: "10px" }}
          rowClassName={(_, index) => {
            return index % 2 === 0 ? "even-row" : "odd-row";
          }}
          expandable={{
            defaultExpandAllRows: true,
            expandIcon: () => <></>,
          }}
        />
      )}
      {keywordLoading && (
        <Table
          columns={columnsView?.map((d) => {
            return {
              ...d,
              className: d?.className + "minWidth-table",
            };
          })}
          scroll={{ x: "max-content" }}
          dataSource={[]}
          pagination={false}
          style={{ marginTop: "10px", marginBottom: "10px" }}
          loading={true}
          bordered
          size="small"
        />
      )}
    </CustomColumns>
  );
}
