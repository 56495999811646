import { Button, Form, Input, Modal, Select, message } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { useEffect } from "react";
import { configModal } from "../../../../../config";
import { LoadingModal } from "../../../../../components/upload-modals";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const [metricGroupList, setMetricGroupList] = useState([]);
  const [metricGroupLoading, setMetricGroupLoading] = useState(true);

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      form.setFieldsValue({
        metric_name: selectedRow?.metric_name,
        metric_group_id: selectedRow?.metric_group_id,
        metric_type: selectedRow?.metric_type,
        metric_unit: selectedRow?.metric_unit,
        description: selectedRow?.description,
        param_type: selectedRow?.param_type,
        default_lower_value: selectedRow?.default_lower_value,
        default_upper_value: selectedRow?.default_upper_value,
      });
    }
  }, [selectedRow]);

  const UpdateAction = async (data) => {
    try {
      LoadingModal("Metrics", "Updating...");
      const response = await MakeApiCall(
        `metric/${selectedRow?.id}`,
        "put",
        data,
        true
      );

      if (response?.status) {
        message.destroy();
        Modal.destroyAll();
        modal
          .success(configModal("Update Metric", response?.message))
          .then(() => {
            callAPI();
            onClose();
          });
      } else {
        Modal.destroyAll();
        message.destroy();
        await modal.warning(configModal("Update Metric", response?.message));
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  const AddUpdate = async (data) => {
    try {
      LoadingModal("Metrics", "Adding...");
      const response = await MakeApiCall(`metric`, "post", data, true);

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();

        modal
          .success(configModal("Create Metric", response?.message))
          .then(() => {
            callAPI();
            onClose();
          });
      } else {
        Modal.destroyAll();
        message.destroy();
        modal.warning(configModal("Create Metric", response?.message));
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const getGroupList = async () => {
    setMetricGroupLoading(true);
    const response = await MakeApiCall(
      `metric-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricGroupList(response?.data?.records || []);
      setMetricGroupLoading(false);
    } else {
      setMetricGroupList([]);
      setMetricGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getGroupList();
    return () => {};
  }, []);
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        message.destroy();

        if (modalType === "Add") {
          AddUpdate(values);
        } else {
          UpdateAction(values);
        }
      })
      .catch((error) => {});
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={`${modalType} Metric`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        {selectedRow?.id && (
          <Form.Item label="ID">
            <Input disabled style={{ width: "100%" }} value={selectedRow?.id} />
          </Form.Item>
        )}
        <Form.Item
          label="Metric Group"
          name="metric_group_id"
          rules={[{ required: true, message: "Please Enter Metric Group" }]}
        >
          <Select
            placeholder="Enter Metric Group"
            loading={metricGroupLoading}
            style={{ width: "100%" }}
            options={metricGroupList?.map((d) => ({
              label: d?.group_name,
              value: d?.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Metric Name"
          name="metric_name"
          rules={[{ required: true, message: "Please Enter Metric Name" }]}
        >
          <Input placeholder="Enter Metric Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          label="Format"
          name="metric_unit"
          rules={[{ required: true, message: "Please Select Format" }]}
        >
          <Select placeholder="Select Type" style={{ width: "100%" }}>
            <Select.Option value="$">$</Select.Option>
            <Select.Option value="%">%</Select.Option>
            <Select.Option value="int">int</Select.Option>
            <Select.Option value="2">2</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Type"
          name="metric_type"
          rules={[{ required: true, message: "Please Select Type" }]}
        >
          <Select placeholder="Select Type" style={{ width: "100%" }}>
            <Select.Option value="Company">Company</Select.Option>
            <Select.Option value="Product">Product</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Parameter Type"
          name="param_type"
          rules={[
            { required: true, message: "Please input your Parameter Type!" },
          ]}
        >
          <Select
            options={[
              // {
              //   label: "Text",
              //   value: "Text",
              // },
              {
                label: "Range%",
                value: "Range%",
              },
              {
                label: "Range$",
                value: "Range$",
              },
              {
                label: "RangeInt",
                value: "RangeInt",
              },
              // {
              //   label: "Single%",
              //   value: "Single%",
              // },
              // {
              //   label: "Single$",
              //   value: "Single$",
              // },
              // {
              //   label: "SingleInt",
              //   value: "SingleInt",
              // },
            ]}
            onChange={() => {
              form.setFieldsValue({
                default_value: "",
                default_upper_value: "",
                default_lower_value: "",
              });
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const paramType = getFieldValue("param_type");
            return (
              <>
                {/* {["Text", "Single%", "Single$", "SingleInt"].includes(
                          paramType
                        ) && (
                          <Form.Item
                            label="Default Value"
                            name="default_value"
                            dependencies={["param_type"]}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const paramType = getFieldValue("param_type");
                                  if (!value || paramType === "Text") {
                                    return Promise.resolve();
                                  }
                                  const regex = /^(?:\d+|\d+\.\d+)$/;
                                  if (!regex.test(value)) {
                                    return Promise.reject(
                                      new Error("Invalid Value, please input a number!")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        )} */}
                {!["Text", "Single%", "Single$", "SingleInt"].includes(
                  paramType
                ) && (
                  <Form.Item
                    label="Default Lower Value"
                    name="default_lower_value"
                    rules={[
                      {
                        pattern: /^(?:\d+|\d+\.\d+)$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                {!["Text", "Single%", "Single$", "SingleInt"].includes(
                  paramType
                ) && (
                  <Form.Item
                    label="Default Upper Value"
                    name="default_upper_value"
                    rules={[
                      {
                        pattern: /^(?:\d+|\d+\.\d+)$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AddData;
