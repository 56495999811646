import Highcharts from "highcharts";
import React, { useEffect } from "react";

const StackedBarLineChart = ({
  updated_data,
  colorList,
  selectedGraph,
  graphLabels,
  series,
}) => {
  const titleNames = {
    sales_sponsored_products: {
      yAxis: 0,
      type: "column",
    },
    sales_sponsored_brands: {
      yAxis: 0,
      type: "column",
    },
    sales_sponsored_brands_video: {
      yAxis: 0,
      type: "column",
    },
    sales_sponsored_display: {
      yAxis: 0,
      type: "column",
    },
    sales_sponsored_television: {
      yAxis: 0,
      type: "column",
    },
    cost_sponsored_products: {
      yAxis: 1,
      type: "column",
    },
    cost_sponsored_brands: {
      yAxis: 1,
      type: "column",
    },
    cost_sponsored_brands_video: {
      yAxis: 1,
      type: "column",
    },
    cost_sponsored_display: {
      yAxis: 1,
      type: "column",
    },

    cost_sponsored_television: {
      yAxis: 1,
      type: "column",
    },
    acos_sponsored_brands: {
      yAxis: 2,
      type: "line",
    },
    acos_sponsored_brands_video: {
      yAxis: 2,
      type: "line",
    },
    acos_sponsored_display: {
      yAxis: 2,
      type: "line",
    },
    acos_sponsored_products: {
      yAxis: 2,
      type: "line",
    },
    acos_sponsored_television: {
      yAxis: 2,
      type: "line",
    },
  };
  const signFind = {
    "SP Products Sales": "$",
    "SP Ad Cost": "$",
    "SP ACOS": "%",
    "SB Brands Sales": "$",
    "SB Brands Ad Cost": "$",
    "SB ACOS": "%",
    "SBV Brand Video Sales": "$",
    "SBV Brand Video Ad Cost": "$",
    "SBV ACOS": "%",

    "SD Display Sales": "$",
    "SD Display Ad Cost": "$",
    "SD ACOS": "%",
    "ST Television Sales": "$",
    "ST Television Ad Cost": "$",
    "ST ACOS": "%",
  };
  useEffect(() => {
    if (Object?.keys(updated_data?.chartData || {})?.length === 0) return;

    Highcharts.chart("line-chart-ads", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "430px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
        type: "column",
      },

      title: "",
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      xAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            fontWeight: 400,
            color: "#656565",
            fontSize: "14px",
          },
        },
        categories: updated_data?.chartDate,
      },
      yAxis: [
        {
          title: {
            text: "Sales",
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return this.stack;
            },
            style: {
              fontWeight: "bold",
              color:
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                "gray",
            },
          },
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },

        {
          title: {
            text: "Cost",
            style: {
              fontWeight: 400,
              color: "#656565",

              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "ACOS",
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],

      series: Object.entries(updated_data?.chartData || {})
        ?.filter((d) => selectedGraph?.includes(graphLabels?.[d?.[0]]))
        ?.map(([key, value], i) => {
          return {
            // ...titleNames?.[key],
            name: graphLabels?.[key],
            yAxis: titleNames?.[key]?.yAxis,
            type: titleNames?.[key]?.type,
            data: Object.values(value),
            color: series?.filter((d) => d?.name?.toLowerCase() === key)?.[0]
              ?.color,
          };
        }),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontWeight: 400,
          color: "#656565",

          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
        },
        // pointFormatter: function () {
        //   return (
        //     '<div style="margin-bottom: 5px"><span style="color:' +
        //     this.color +
        //     '">\u25CF</span> ' +
        //     '<span style="text-transform: capitalize">' +
        //     this.series.name +
        //     "</span>" +
        //     ": <b>" +
        //     (signFind?.[this.series.name] === "%"
        //       ? ""
        //       : signFind?.[this.series.name]) +
        //     Math.round(this.y || 0)?.toLocaleString() +
        //     (signFind?.[this.series.name] === "%" ? "%" : "") +
        //     "</b><br/></div>"
        //   );
        // },
        formatter: function () {
          return (
            `<div style="margin-bottom: 5px"><b>` +
            '<span style="font-weight: bold">' + // Make x-axis value bold
            this.x +
            "</span></b><br/></b><br/>" +
            this.points
              ?.filter((d) => d?.y)
              ?.map(
                (point) =>
                  '<div style="margin-bottom: 5px"><span style="color:' +
                  point.color +
                  '">■</span> ' +
                  '<span style="text-transform: capitalize; font-size: 14px;">' +
                  point.series.name +
                  "</span>" +
                  ": <b>" +
                  (signFind?.[point.series.name] === "%"
                    ? ""
                    : signFind?.[point.series.name]) +
                  Math.round(point.y || 0)?.toLocaleString() +
                  (signFind?.[point.series.name] === "%" ? "%" : "") +
                  "</b><br/></div>"
              )
              .join("") +
            "</div>"
          );
        },
      },
      legend: {
        enabled: false, // Hide legends
      },
    });
  }, [updated_data, selectedGraph]);

  return (
    <div id="line-chart-ads" style={{ width: "100%", height: "420px" }}></div>
  );
};

export default StackedBarLineChart;
