import {
  Button,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
  theme,
} from "antd";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import React, { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";

import {
  CaretDownOutlined,
  CaretRightOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import * as XLSX from "xlsx";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import { ENDPOINT, RenderTable } from "../../../../config";
import { COGSWrapper } from "../style";
import {
  ErrorModal,
  LoadingModal,
  SuccessModal,
} from "../../../../components/upload-modals";
import { useHistory } from "react-router-dom";
dayjs.extend(quarterOfYear);
const { useToken } = theme;

const COGS = ({ locationData }) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);

  const [file, setFile] = useState({
    fileName: null,
    file: null,
  });
  const [filters_, setFilters_] = useState({
    search_key: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [cogsUpdateLoading, setCogsUpdateLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState({});

  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const [hideDropdown, setHideDropdown] = useState(false);
  const [editRow, setEditRow] = useState({
    sku: null,
    asin: null,
    manufacturing_cost: 0,
    total_cogs: 0,
    effective_date: dayjs().format("YYYY-MM-DD"),
    inspection_cost: 0,
    shipping_cost: 0,
    import_taxes: 0,
    other_costs: 0,
    id: null,
  });
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });
  const [filters, setFilters] = useState({
    search_key: null,
    filter: "",
  });

  const columns = [
    // Table.EXPAND_COLUMN,

    {
      title: "SKU",
      dataIndex: "seller_sku",
      key: "seller_sku",
      editable: true,
      width: 130,
    },
    {
      title: "ASIN",
      dataIndex: "asin1",
      key: "asin1",
      editable: true,
      width: 150,
      render: (e) => {
        return <Tag color="blue">{e}</Tag>;
      },
    },
    {
      title: "Item",
      dataIndex: "image_url",
      key: "image_url",
      align: "center",
      editable: true,
      render: (images) => (
        <img src={images} style={{ width: "50px", height: "50px" }} />
      ),
    },
    {
      title: "Items Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 600,
      editable: true,
    },

    {
      title: "Manufacturer Cost",
      dataIndex: "manufacturing_cost",
      key: "manufacturing_cost",
      editable: true,
      align: "right",
      render: (e) => {
        return parseFloat(e || 0).toFixed(2);
      },
    },
    {
      title: "Total COGS",
      dataIndex: "item_cogs",
      key: "item_cogs",
      align: "right",
      editable: true,
      render: (e) => {
        return parseFloat(e || 0).toFixed(2);
      },
    },
    {
      title: "Effective Date",
      dataIndex: "cogs_effective_date",
      key: "cogs_effective_date",
      editable: true,
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
  ];

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const getExport = async (data) => {
    try {
      LoadingModal("COGS", "Exporting...");
      const response = await MakeApiCall(
        `cogs/export?search_key=${
          data?.search_key || filters?.search_key || ""
        }&filter=${data?.filter || filters?.filter || ""}&start_date=${
          filters_?.start_date || ""
        }&end_date=${filters_?.end_date || ""}&is_export=1`,
        "get",
        null,
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Add a worksheet to the workbook
        const ws = XLSX.utils.json_to_sheet(response?.data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

        // Convert the workbook to a binary Excel file (binary string)
        const excelBinaryString = XLSX.write(wb, {
          bookType: "xlsx",
          type: "binary",
        });
        message.destroy();
        // Convert the binary string to a Blob
        const blob = new Blob([s2ab(excelBinaryString)], {
          type: "application/octet-stream",
        });

        // Create a download link and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `cogs.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        Modal.destroyAll();
        ErrorModal(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `cogs?search_key=${
        data?.search_key || filters?.search_key || ""
      }&filter=${data?.filter || ""}&field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}&start_date=${
        data?.start_date || ""
      }&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTableLoading(false);
      setTotalPage(0);
      setLoading(false);
      ErrorModal(response?.message);
    }
  };

  const handleExpand = (record) => {
    if (record?.id === expandedRowKeys?.id) {
      setExpandedRowKeys({});
    } else {
      setExpandedRowKeys(record);
    }
  };
  const getHistory = async (sku, record) => {
    setHistoryLoading(true);
    const response = await MakeApiCall(
      `cogs/history?sku=${sku}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setHistoryList(response?.data?.records);
      setHistoryLoading(false);
    } else {
      setHistoryList([]);
      setHistoryLoading(false);
      message.destroy();
      ErrorModal(response?.message);
    }
  };
  useEffect(() => {
    setLoading(true);
    localStorage.removeItem("prev_upload_page");
    getList({ ...filters_, ...sortFilters });
    return () => {};
  }, []);

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.download = "SKU-Template.xlsx";
    link.href = "SKU-Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const ImportCOGS = async (data) => {
    LoadingModal("COGS", "Uploading...");
    const getToken = () => localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getToken()}`);

    var formdata = new FormData();
    formdata.append("file_type", "cogs");
    formdata.append(
      "cogs_data_file",
      data?.uploadedFile,
      data?.uploadedFile?.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(ENDPOINT + "cogs/import", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        message.destroy();
        setFile({
          fileName: null,
          file: null,
        });
        // setPage(1);
        // setLoading(true);
        // getList({ page: 1, ...filters_ });

        SuccessModal(result?.message);
        setTimeout(() => {
          localStorage.setItem(
            "prev_upload_page",
            JSON.stringify({
              key: "/data-sources?tabs=16",
              title: "COGS",
            })
          );
          history.push("/upload-manager");
        }, 1000);
      })
      .catch((error) => {
        message.destroy();
        ErrorModal(error?.message);
      });
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const onUpdateValue = (e, type) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setEditRow({
      ...editRow,
      [type]: newValue,
    });
  };

  const getTotalCOGS = () => {
    const {
      manufacturing_cost,
      inspection_cost,
      shipping_cost,
      import_taxes,
      other_costs,
    } = editRow;

    const total =
      parseFloat(manufacturing_cost || 0) +
      parseFloat(inspection_cost || 0) +
      parseFloat(shipping_cost || 0) +
      parseFloat(import_taxes || 0) +
      parseFloat(other_costs || 0);

    return total;
  };

  const resetCOGS = () => {
    setEditRow({
      sku: null,
      asin: null,
      manufacturing_cost: 0,
      total_cogs: 0,
      effective_date: dayjs().format("YYYY-MM-DD"),
      inspection_cost: 0,
      shipping_cost: 0,
      import_taxes: 0,
      other_costs: 0,
      id: null,
    });
  };

  const saveCOGS = async () => {
    setCogsUpdateLoading(true);
    const {
      id,
      sku,
      asin,
      manufacturing_cost,
      effective_date,
      inspection_cost,
      shipping_cost,
      import_taxes,
      other_costs,
    } = editRow;

    const response = await MakeApiCall(
      `cogs/update/${id}`,
      "put",
      {
        sku,
        asin,
        manufacturing_cost,
        total_cogs: getTotalCOGS(),
        effective_date,
        inspection_cost,
        shipping_cost,
        import_taxes,
        other_costs,
      },
      true
    );

    if (response?.status) {
      setCogsUpdateLoading(false);
      getHistory(sku);
      // resetCOGS();
    } else {
      setCogsUpdateLoading(false);
      ErrorModal(response?.message);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters_ });
  };

  const ExportBTN = (
    <div
      onClick={() => {
        if (!(filters_?.start_date && filters_?.end_date)) return;
        message.destroy();

        getExport();
      }}
      className="me-5"
      style={{
        background: "#00B660",
        borderRadius: 6,
        padding: "6px 15px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          color: "#FAFAFB",
          fontSize: 13,

          fontWeight: 500,
          letterSpacing: 0.1,
          wordWrap: "break-word",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          className="me-3"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
            fill="white"
          />
        </svg>
        Export
      </div>
    </div>
  );
  return (
    <COGSWrapper className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          COGS
          <InfoVideo data={locationData} title={"cogs"} className={"ms-3"} />
          <Input
            placeholder="Search by SKU, ASIN"
            style={{ width: "200px", marginLeft: "10px" }}
            value={filters?.search_key}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      search_key: e.target.value,
                    },
                    ...sortFilters,
                    page: page,
                    pageSize: pageSize,
                  });
                }
                return {
                  ...prev,
                  search_key: e.target.value,
                };
              });
            }}
            // onChange={(e) => {
            //   setFilters({
            //     ...filters,
            //     search_key: e.target.value,
            //   });
            // }}
            onPressEnter={() => {
              getList({ ...filters, ...sortFilters });
            }}
          />
          <button
            onClick={() => {
              getList({ ...filters, ...sortFilters });
            }}
            className="btn btn-sm ms-3 btn-search me-3"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            placeholder="Select"
            className="w-150px ms-3"
            onChange={(e) => {
              const obj = {
                ...filters,
                filter: e || "",
              };
              getList({ ...obj, ...sortFilters });
              setFilters(obj);
            }}
            value={filters?.filter}
            options={[
              {
                label: "All",
                value: "",
              },
              {
                label: "Cogs Added",
                value: "ca",
              },
              {
                label: "Cogs Not Added",
                value: "cna",
              },
            ]}
          />
          <b className="ms-3 fs-5">
            {filters?.filter === "cna" ? "Show missing COGS only" : ""}
          </b>
        </div>
        <div className="card-toolbar">
          <DatePicker.RangePicker
            allowClear
            presets={presetRanges}
            value={
              filters_?.start_date && filters_?.end_date
                ? [
                    dayjs(filters_?.start_date, "YYYY-MM-DD"),
                    dayjs(filters_?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters_,
                start_date: e?.[0] ? dayjs(e?.[0])?.format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1])?.format("YYYY-MM-DD") : null,
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters_(obj);
            }}
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
            className="w-250px me-3"
          />
          <Space>
            {ExportBTN}
            <Dropdown
              menu={{
                items: [],
              }}
              trigger={["click"]}
              onOpenChange={(e) => setHideDropdown(e)}
              open={hideDropdown}
              className="me-5"
              placement="bottomRight"
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  {React.cloneElement(menu, {
                    style: menuStyle,
                  })}

                  <div className="px-7 py-5">
                    <div className="mb-10">
                      <input
                        className="form-control form-control-sm form-control-solid"
                        placeholder
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        value={file?.fileName}
                        onChange={(e) => {
                          setFile({
                            fileName: e.target.value,
                            file: e.target.files?.[0],
                          });
                        }}
                        id="formFile"
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-sm btn-dark"
                        onClick={() => {
                          const obj = {
                            uploadedFile: file?.file,
                            file_type: "cogs",
                          };

                          setHideDropdown(false);
                          message.destroy();

                          ImportCOGS(obj);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            >
              <Button onClick={(e) => e.preventDefault()} type="dashed">
                Upload
              </Button>
            </Dropdown>

            <Button
              type="link"
              onClick={downloadTemplate}
              icon={<CloudDownloadOutlined />}
            >
              Download Template
            </Button>
          </Space>
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
              pagination={false}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              onChange={handleChange}
              loading={tableLoading}
              rowKey={"id"}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
              expandable={{
                expandedRowRender: (record, test) => (
                  <table className="">
                    <thead>
                      <tr>
                        <th className="min-w-175px ps-2">Manufacturing Cost</th>
                        <th className="min-w-150px">Inspection</th>
                        <th className="min-w-150px">Shipping</th>
                        <th className="min-w-150px">Taxes</th>
                        <th className="min-w-150px">Other Costs</th>
                        <th className="min-w-150px">Total COGS</th>
                        <th className="min-w-150px">Date</th>
                        <th className="min-w-150px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="textbox-view pe-5 py-0">
                          <Input
                            onChange={(e) => {
                              onUpdateValue(e, "manufacturing_cost");
                            }}
                            value={editRow?.manufacturing_cost}
                          />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <Input
                            onChange={(e) => {
                              onUpdateValue(e, "inspection_cost");
                            }}
                            value={editRow?.inspection_cost}
                          />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <Input
                            onChange={(e) => {
                              onUpdateValue(e, "shipping_cost");
                            }}
                            value={editRow?.shipping_cost}
                          />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <Input
                            onChange={(e) => {
                              onUpdateValue(e, "import_taxes");
                            }}
                            value={editRow?.import_taxes}
                          />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <Input
                            onChange={(e) => {
                              onUpdateValue(e, "other_costs");
                            }}
                            value={editRow?.other_costs}
                          />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <Input value={getTotalCOGS()} disabled />
                        </td>
                        <td className="textbox-view pe-5 py-0">
                          <DatePicker
                            value={dayjs(editRow?.effective_date, "YYYY-MM-DD")}
                            onChange={(e) => {
                              setEditRow({
                                ...editRow,
                                effective_date: dayjs(e).format("YYYY-MM-DD"),
                              });
                            }}
                            disabledDate={(current) => {
                              return current && current < moment().endOf("day");
                            }}
                            format={"YYYY-MMM-DD"}
                          />
                        </td>
                        <td
                          className="textbox-view pe-5  d-flex"
                          style={{ padding: "14px 18px" }}
                        >
                          <Button
                            disabled={getTotalCOGS() === 0}
                            onClick={() => {
                              saveCOGS();
                            }}
                            size="small"
                            className="me-2"
                            loading={cogsUpdateLoading}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => {
                              resetCOGS();
                            }}
                            size="small"
                          >
                            Reset
                          </Button>
                        </td>
                      </tr>
                      {historyLoading ? (
                        <tr>
                          <td colSpan={8}>
                            <TableLoading
                              id="test-table"
                              columns={[1, 2, 3, 4, 5]}
                              checkBoxVal={true}
                              actions={[1, 2]}
                            />{" "}
                          </td>{" "}
                        </tr>
                      ) : (
                        historyList?.map((d, i) => (
                          <tr key={i}>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.manufacturing_cost}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.inspection_cost}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.shipping_cost}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.import_taxes}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.other_costs}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              ${d?.cogs}
                            </td>
                            <td
                              style={{ paddingLeft: "14px" }}
                              className="t-data mt-5"
                            >
                              {d?.effective_date}
                            </td>
                            <td className="t-data mt-5"></td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                ),
                expandedRowKeys: expandedRowKeys ? [expandedRowKeys?.id] : [],

                expandIcon: ({ expanded, onExpand, record }) => (
                  <Button
                    type="link"
                    icon={
                      expanded ? <CaretDownOutlined /> : <CaretRightOutlined />
                    }
                    onClick={() => {
                      const { asin1, id, seller_sku } = record;
                      setEditRow({
                        sku: seller_sku,
                        asin: asin1,
                        manufacturing_cost: 0,
                        total_cogs: 0,
                        effective_date: dayjs().format("YYYY-MM-DD"),
                        inspection_cost: 0,
                        shipping_cost: 0,
                        import_taxes: 0,
                        other_costs: 0,
                        id: id,
                      });
                      handleExpand(record);
                      if (!expanded) {
                        message.destroy();

                        getHistory(record?.seller_sku, record);
                      }
                    }}
                  />
                ),
              }}
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters_,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              ...sortFilters,
              page: e,
              ...filters_,
            });
          }}
        />
      </div>
    </COGSWrapper>
  );
};

export default COGS;
