import { Input, Modal, Tag, message } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { useEffect } from "react";
import { LoadingModal } from "../../../../../components/upload-modals";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  console.log(selectedRow?.id, "selectedRow");

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      setInputValue(selectedRow?.group_name);
    }
  }, [selectedRow]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const UpdateAction = async (data) => {
    try {
      LoadingModal("Metric Groups", "Updating...");
      const response = await MakeApiCall(
        `metric-group/${selectedRow?.id}`,
        "put",
        {
          group_name: inputValue,
        },
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        setTimeout(() => {
          callAPI();
          onClose();
        }, 1500);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  const AddUpdate = async (data) => {
    try {
      LoadingModal("Metric Groups", "Adding...");
      const response = await MakeApiCall(
        `metric-group`,
        "post",
        {
          group_name: inputValue,
        },
        true
      );

      if (response?.status) {
        message.destroy();
        Modal.destroyAll();
        message.success(response?.message);
        setTimeout(() => {
          callAPI();
          onClose();
        }, 1500);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const handleOk = () => {
    if (!inputValue) {
      message.destroy();
      return message.warning("Please Enter Group Name");
    }
    message.destroy();

    if (modalType === "Add") {
      AddUpdate(inputValue);
    } else {
      UpdateAction(inputValue);
    }
  };

  const handleCancel = () => {
    onClose();
  };
  // selectedRow?.id
  return (
    <Modal
      title={`${modalType} Metric Groups`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {selectedRow?.id && (
        <div className="mb-5">
          <label className="mt-3 mb-1">
            <span>ID</span>
          </label>
          <Input disabled value={selectedRow?.id} />
        </div>
      )}
      <div className="mb-5">
        <label className="mt-3 mb-1">
          <span>Group Name</span>
        </label>
        <Input
          placeholder="Enter Metric Groups"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </Modal>
  );
};

export default AddData;
