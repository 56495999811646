import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Input,
  Modal,
  Spin,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { configModal } from "../../../../../config";

const Config = (props) => {
  const {
    onClose,
    open,
    data,
    metricSettingLoading,
    metricSettingList,
    onSave,
  } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [checkedMetrics, setCheckedMetrics] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setCheckedMetrics(metricSettingList);
    return () => {};
  }, []);

  const onChange = (checkedValues) => {
    console.log(checkedValues, "checkedValues");

    const metric_list = checkedMetrics?.map((d) => ({
      ...d,
      user_metric_status: checkedValues?.includes(d?.id) ? 1 : 0,
    }));
    console.log(metric_list, "checkedValues");
    setCheckedMetrics(metric_list);
  };

  const filteredData = checkedMetrics?.filter((item) =>
    item?.metric_name?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const messageView = async (title, message) => {
    const confirmed = await modal.warning(configModal(title, message));
  };
  return (
    <Drawer
      styles={{
        header: {
          padding: "10px 24px",
        },
      }}
      title={
        <div className="d-grid">
          <span>Choose Metrics</span>
          <span style={{ fontSize: "14px", fontWeight: 300 }}>
            You can select up to 8 SIP Metrics to be placed on the home page of
            each module.
          </span>
        </div>
      }
      placement="right"
      onClose={onClose}
      closeIcon={
        <i
          onClick={() => onClose()}
          className="ki-outline ki-cross-square fs-2"
        />
      }
      footer={
        <div className="card-footer  py-3 justify-content-start d-flex">
          <div className="me-3">
            <Button
              size="large"
              onClick={() => {
                if (
                  checkedMetrics?.filter((d) => d?.user_metric_status == 1)
                    ?.length > 8
                ) {
                  message.destroy();
                  message.warning("Select only 8 cards");
                  return;
                }

                onSave(
                  checkedMetrics?.map((d) => ({
                    metric_id: d?.id,
                    menu_item_id: d?.menu_item_id,
                    metric_group_id: d?.metric_group_id,
                    sort_order: d?.sort_order,
                    metric_name: d?.metric_name,
                    user_metric_status: d?.user_metric_status,
                  }))
                );
              }}
              className="btn btn-primary fs-7"
            >
              Apply
            </Button>
          </div>
          <div>
            <button
              onClick={() => onClose()}
              className="btn btn-light-primary fs-7"
            >
              Close
            </button>
          </div>

          {/*end::Dismiss button*/}
        </div>
      }
      open={open}
    >
      <Input
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="mb-7"
        placeholder="Search..."
      />
      <div className="card-body hover-scroll-overlay-y config-checkbox">
        {metricSettingLoading && (
          <div
            style={{
              height: "calc(100vh - 150px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        )}
        {metricSettingLoading ? (
          <></>
        ) : data?.length === 0 ? (
          filteredData
            ?.sort((a, b) => a.metric_name.localeCompare(b.metric_name))
            ?.map((metric, i) => (
              <>
                <Checkbox
                  className="mb-2"
                  key={i}
                  checked={
                    checkedMetrics
                      ?.filter((d) => parseInt(d?.user_metric_status) === 1)
                      ?.find((d) => d?.id === metric?.id) === undefined
                      ? false
                      : true
                  }
                  disabled={parseInt(metric?.status) === 0}
                  onChange={(e) => {
                    console.log(
                      checkedMetrics?.filter,
                      metric.id,
                      e.target.checked,
                      "checkedMetrics"
                    );

                    if (
                      checkedMetrics?.filter(
                        (d) => parseInt(d?.user_metric_status) === 1
                      )?.length +
                        1 >
                        8 &&
                      e.target.checked
                    ) {
                      messageView("Warning", "Select only 8 cards");
                      // message.destroy();
                      // message.warning("Select only 8 cards");
                      return;
                    }

                    setCheckedMetrics(
                      checkedMetrics?.map((d) => {
                        if (d?.id === metric.id) {
                          return {
                            ...d,
                            user_metric_status: e.target.checked ? 1 : 0,
                          };
                        }
                        return d;
                      })
                    );
                  }}
                >
                  <div className="d-flex">
                    <Tooltip
                      placement="right"
                      title={
                        metric?.user_metric_status &&
                        parseInt(metric?.user_metric_status) === 0
                          ? metric.metric_name
                          : null
                      }
                    >
                      <div
                        style={{
                          width:
                            metric?.user_metric_status &&
                            parseInt(metric?.user_metric_status) === 0
                              ? "95px"
                              : "250px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginLeft: "11px",
                        }}
                      >
                        {metric.metric_name}
                      </div>
                    </Tooltip>
                    &nbsp;&nbsp;
                    {metric?.status && parseInt(metric?.status) === 0 && (
                      <>
                        <b className="position-absolute " style={{ right: 0 }}>
                          (Currently Unavailable)
                        </b>
                      </>
                    )}
                  </div>
                </Checkbox>
                <div className="separator separator-dashed my-3" />
              </>
            ))
        ) : (
          // </Checkbox.Group>
          data?.map((d, i) => (
            <>
              <div
                key={i}
                className="form-check form-check-custom form-check-solid"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  // checked
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label fw-bold text-dark fs-6"
                  htmlFor="flexCheckDefault"
                >
                  {d}
                </label>
              </div>
              <div className="separator separator-dashed my-3" />
            </>
          ))
        )}
      </div>
      {contextHolder}
    </Drawer>
  );
};

export default Config;
