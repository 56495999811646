import { Modal, Popconfirm, Table, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";
import * as XLSX from "xlsx";
import Pagination from "../../../../components/pagination";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import AddCaralog from "./lib/add";
import { Wrapper } from "./style";
import dayjs from "dayjs";
import { LoadingModal } from "../../../../components/upload-modals";

const CantentCatelog = () => {
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: "title",
    sort_by: "asc",
  });

  const getList = async (data) => {
    try {
      // `content-catalog?page=${data?.page || page || 1}&per-page=${
      //   data?.pageSize || pageSize
      // }&isAll=0&field_name=${data?.field_name || ""}&sort_by=${
      //   data?.sort_by || ""
      // }`,
      const response = await MakeApiCall(
        `content-catalog?isAll=1`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setList(response?.data?.records || []);
        // setTotalPage(response?.data?.pagination?.totalCount || 0);
        setLoading(false);
      } else {
        setTotalPage(0);
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...sortFilters });
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      align: "center",
      render: (_, __, i) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {(page - 1) * pageSize + 1 + i}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <span>
          <a onClick={() => handleEdit(record)} className=" me-2">
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete the catalog"
            description="Are you sure to delete this catalog?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filterIndex: "type",
      align: "center",
      render: (e) => {
        return (
          <span color="cyan">
            {e === 1 ? (
              <span>
                <svg
                  style={{ width: "15px" }}
                  aria-hidden="true"
                  viewBox="0 0 512 512"
                  className=" fas fa-info-circle"
                >
                  <path
                    fill="#6d6d6d"
                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                  />
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  width={22}
                  height={22}
                  fill="#3c71b8"
                  className="cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7Z" />
                </svg>
              </span>
            )}
          </span>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Info Heading",
      dataIndex: "info_heading",
      key: "info_heading",
      filterIndex: "info_heading",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Info",
      dataIndex: "info_text",
      filterIndex: "info_text",
      key: "info_text",
      align: "center",
      render: (e) => {
        return (
          <Tooltip title={e} placement="bottom">
            <span>
              <svg
                style={{ width: "15px", cursor: "pointer" }}
                aria-hidden="true"
                viewBox="0 0 512 512"
                className=" fas fa-info-circle"
              >
                <path
                  fill="#6d6d6d"
                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                />
              </svg>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Font Style",
      dataIndex: "font_style",
      filterIndex: "font_style",
      key: "font_style",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Font Size",
      dataIndex: "font_size",
      key: "font_size",
      filterIndex: "font_size",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Font Weight",
      dataIndex: "font_weight",
      key: "font_weight",
      filterIndex: "font_weight",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Video URL",
      dataIndex: "video_url",
      key: "video_url",
      filterIndex: "video_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? "Video" : "-"}
        </a>
      ),
    },
    {
      title: "Showcase URL",
      dataIndex: "showcase_url",
      key: "showcase_url",
      filterIndex: "showcase_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? "Showcase" : "-"}
        </a>
      ),
    },
    // {
    //   title: "Created (ET)",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   filterIndex: "created_at",
    //   render: (e) => {
    //     return moment(new Date(parseInt(e) * 1000))
    //       .tz("America/New_York")
    //       .format("MM/DD/YYYY");
    //   },
    // },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY");
      },
    },
    // {
    //   title: "Created by",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Modified By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
  ];
  // Function to handle the Add button
  const handleAdd = () => {
    // setVisible(true);

    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=add`
    );
  };

  // Functions to handle edit and delete actions
  const handleEdit = (row) => {
    // setSelectedRow(row);
    // handleAdd();

    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${row?.id}`
    );
    // Implement your edit logic here
  };

  const deleteRow = async (id) => {
    try {
      const response = await MakeApiCall(
        `content-catalog/${id}`,
        "delete",
        null,
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        setList((pre) => pre.filter((d) => d?.id !== id));
        message.success(response?.message);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const handleDelete = (id) => {
    LoadingModal("Content Catalog", "Deleting...");

    deleteRow(id);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadExcel = (data) => {
    const wb = XLSX.utils.book_new();
    message.destroy();
    const ws = XLSX.utils.json_to_sheet(
      data?.map((d, i) => ({
        ID: i + 1,
        Type: d?.type === 1 ? "Info" : "Video",
        Title: d?.title,
        "Info Heading": d?.info_heading,
        Info: d?.info_text,
        "Font Style": d?.font_style,
        "Font Size": d?.font_size,
        "Font Weight": d?.font_weight,
        "Video URL": d?.video_url,
        "Showcase URL": d?.showcase_url,
        "Created (ET)": moment(new Date(parseInt(d?.created_at) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY"),
        "Updated At": moment(new Date(parseInt(d?.updated_at) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY"),
      }))
    );
    XLSX.utils.book_append_sheet(wb, ws, "Content Catalog");

    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Content Catalog.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.destroy();
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  if (pageType) {
    return (
      <AddCaralog
        pageType={pageType}
        id={selectedID}
        setList={() => setList({ ...sortFilters })}
        // selectedRow={selectedRow}
        submit={() => {
          setLoading(true);
          setPage(1);
          getList({
            page: 1,
          });
        }}
        setVisible={() => {
          history.push(updatedUrl);
        }}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="custom-ui ">
      <div className="row g-2">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch">
            <div className="card-header">
              <div className="card-title">Content Catalog</div>
              <div className="card-toolbar">
                <a onClick={handleAdd} className="add-btn me-3 ">
                  <svg
                    width={16}
                    height={16}
                    className="me-2"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                  </svg>
                  Add
                </a>
                <div
                  style={{
                    background: "#00B660",
                    borderRadius: 6,
                    padding: "8px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    downloadExcel(list);
                  }}
                >
                  <div
                    style={{
                      color: "#FAFAFB",
                      fontSize: 13,

                      fontWeight: 500,
                      letterSpacing: 0.1,
                      wordWrap: "break-word",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      className="me-3"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                        fill="white"
                      />
                    </svg>
                    Export
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0 scroll-show">
              <Table
                dataSource={list}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                // onChange={handleChange}
                columns={columns?.map((d) => ({
                  ...d,
                  className:
                    d?.title === "#" ? "maxWidth-table" : "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                loading={loading}
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                  defaultPageSize: 100,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content", y: "calc(100vh - 410px)" }}
              />
            </div>
            <div className="card-footer d-none pt-0 pb-5">
              <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    page: 1,
                    pageSize: e,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);
                  getList({
                    page: e,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CantentCatelog;
