import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Input,
  Popconfirm,
  Popover,
  Row,
  Segmented,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useMemo, useState } from "react";
import { NumberWithCommas, RenderTable } from "../../../config";
import {
  AppstoreOutlined,
  BarsOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
// import DatePickerView from "../data-sources/lib/date-picker-view";
import { Wrapper } from "../data-sources/style";
import { CustomColumns, TableWrapper } from "./style";
import dayjs from "dayjs";

import weekOfYear from "dayjs/plugin/weekOfYear";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const DatePickerView = React.lazy(() =>
  import("../data-sources/lib/date-picker-view")
);

dayjs.extend(weekOfYear);

const getUniqueFilters = (data, key) => {
  const uniqueValues = [
    ...new Set(data.map((item) => item[key]).filter(Boolean)),
  ];

  return uniqueValues.map((value) => ({
    text: value,
    value,
  }));
};
export default function SearchTermsViewLineItems(props) {
  const {
    setKeywordViewAll,
    presets,
    setExpandKeywordSearchView,
    expandKeywordSearchView,
    setSearchTermFilterList,
    setApiCallSearchView,
    getSearchKeyword,
    searchTermFilterList,
    setSearchTermLineItemView,
    dropdownList,
    dropdownLoading,
    searchTermData,
    getKeywordTracking,
    searchTermLoading,
    downloadFileLineItems,
    setFilters,
  } = props;
  const [searchText, setSearchText] = useState("");
  const filterableColumns = ["search_term", "campaign", "match_type"];
  const [searchTermValExist, setSearchTermValExist] = useState("");
  const [allSearchTermView, setAllSearchTermView] = useState("Folder");
  // Generate filter options for each column dynamically
  const columnFilters = useMemo(() => {
    return filterableColumns.reduce((acc, key) => {
      acc[key] = getUniqueFilters(searchTermData, key);
      return acc;
    }, {});
  }, [searchTermData]);

  const dynamicOnFilter = (key) => (value, record) => {
    return Array.isArray(value)
      ? value?.some((selected) =>
          typeof value === "string"
            ? record?.[key]?.includes(selected)
            : record?.[key]?.toString()?.includes(value?.toString())
        )
      : record?.[key]?.toString()?.includes(value?.toString());
  };

  const filterDropdownFilter =
    (key) =>
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const handleSelect = (value) => {
        const newSelectedKeys = selectedKeys.includes(value)
          ? selectedKeys.filter((key) => key !== value)
          : [...selectedKeys, value];
        setSelectedKeys(newSelectedKeys);
      };

      const opsList = getUniqueFilters(searchTermData, key)
        ?.filter((r) => r?.text)
        .filter((option) =>
          typeof option?.text === "string"
            ? option?.text
                ?.toString()
                ?.toLowerCase()
                ?.includes((searchText || "").toLowerCase())
            : option?.text
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchText || "")
        );
      return (
        <div style={{ padding: 8, width: 300 }}>
          <Input
            placeholder={`Search ${key?.split("_")?.join(" ")}...`}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={() => confirm()}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
              padding: 4,
              borderRadius: 4,
              border: "1px solid #d9d9d9",
            }}
          />
          <div style={{ maxHeight: 200, overflowY: "auto" }}>
            <div
              style={{
                padding: "6px 12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                onChange={() => {
                  setSelectedKeys(
                    opsList?.length === selectedKeys?.length
                      ? []
                      : opsList?.map((d) => d?.text)
                  );
                }}
                checked={opsList?.length === selectedKeys?.length}
              >
                <b
                  style={{
                    position: "relative",
                    top: "1px",
                  }}
                >
                  {opsList?.length === selectedKeys?.length
                    ? "Deselect All"
                    : "Select All"}
                </b>
              </Checkbox>
            </div>
            {opsList?.map((option) => (
              <div
                key={option.value}
                style={{
                  padding: "6px 12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selectedKeys.includes(option.value)}
                  onChange={() => handleSelect(option.value)}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "220px",
                      display: "inline-block",
                      position: "relative",
                      top: "3px",
                    }}
                  >
                    {option.text}
                  </span>
                </Checkbox>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <Button
              color="primary"
              variant="solid"
              size="small"
              onClick={() => {
                confirm();
                setSearchText("");
              }}
              style={{ padding: "4px 8px", width: "90px" }}
            >
              Apply
            </Button>
          </div>
        </div>
      );
    };

  const columnsView = [
    {
      title: (
        <>
          <Button
            onClick={() => {
              setExpandKeywordSearchView((prev) => {
                if (prev.length === 0) {
                  return searchTermData?.map((d, i) => i + 1);
                }
                return [];
                // if (prev === row?.cIndex) {
                //   return "";
                // }
                // return row?.cIndex;
              });
            }}
            size="small"
          >
            {expandKeywordSearchView.length > 0 ? "-" : "+"}
          </Button>
        </>
      ),
      dataIndex: "keywordIndex",
      key: "keywordIndex",
      className: "highlight-column",

      render: (e, row) => {
        if (!row?.keywordIndex) {
          return (
            <Button
              onClick={() => {
                setExpandKeywordSearchView((prev) => {
                  // if the row is already expanded, collapse it
                  if (prev.includes(row?.cIndex)) {
                    return prev.filter((d) => d !== row?.cIndex);
                  }
                  // otherwise, expand it
                  return [...prev, row?.cIndex];

                  // if (prev === row?.cIndex) {
                  //   return "";
                  // }
                  // return row?.cIndex;
                });
              }}
              size="small"
            >
              {expandKeywordSearchView.includes(row?.cIndex) ? "-" : "+"}
            </Button>
          );
        }
        return <div>{""}</div>;
      },
    },
    {
      title: "#",
      dataIndex: "cIndex",
      key: "cIndex",
      className: "highlight-column",
      render: (e) => {
        return <>#{e}</>;
      },
    },
    {
      title: "Search Term",
      width: 250,
      className: "highlight-column",
      filters: columnFilters["search_term"],
      onFilter: dynamicOnFilter("search_term"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("search_term"),
      sorter: (a, b) => a?.search_term?.localeCompare(b?.search_term),
      render: (e, row) => {
        return (
          <div className="one-line-ellipsis">{row?.search_term || ""}</div>
        );
      },
    },
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      className: "highlight-column",
      width: 300,
      filters: columnFilters["campaign"],
      onFilter: dynamicOnFilter("campaign"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("campaign"),
      sorter: (a, b) => a?.campaign?.localeCompare(b?.campaign),
      render: (e, row) => {
        if (!e) return;
        if (e?.split(",")?.length === 1) {
          return e;
        }
        return (
          <Popover
            content={
              <>
                {e?.split(",")?.map((d) => (
                  <div>{d}</div>
                ))}
                <div
                  onClick={() => {
                    setSearchTermLineItemView({
                      show: true,
                      data: row,
                    });
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#009ef7",
                    cursor: "pointer",
                  }}
                >
                  View All
                </div>
              </>
            }
            trigger={["hover"]}
          >
            <div className="one-line-ellipsis">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "match_type",
      key: "match_type",
      className: "highlight-column",
      filters: columnFilters["match_type"],
      onFilter: dynamicOnFilter("match_type"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("match_type"),
      sorter: (a, b) => a?.match_type?.localeCompare(b?.match_type),
      width: 100,
      // onFilter: (value, record) => record.match_type.includes(value),
      render: (e) => {
        if (!e) return;
        return (
          <Flex align="baseline">
            <Tooltip title={e} placement="left">
              {(e || "")?.charAt(0)}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: "Traffic Performance",
      width: 200,
      render: (e, row) => {
        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Impressions
                </span>
                <span style={{ color: "#009ffb" }}>
                  {NumberWithCommas(row?.impressions || 0)}
                </span>
              </Flex>
            </Col>
            {/* {row?.cIndex === expandKeywordSearchView && ( */}
            {expandKeywordSearchView.includes(row.cIndex) && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Clicks
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.clicks || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      CTR
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.click_through_rate || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg Bid
                    </span>
                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.keyword_bid || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: "Listing Performance",
      width: 200,
      render: (e, row) => {
        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Sales
                </span>

                <span style={{ color: "#009ffb" }}>
                  ${NumberWithCommas(row?.sales || 0)}
                </span>
              </Flex>
            </Col>
            {/* {row?.cIndex === expandKeywordSearchView && ( */}
            {expandKeywordSearchView.includes(row?.cIndex) && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Purchases
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.purchases || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CR
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.conversion_rate || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CPC
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      ${NumberWithCommas(row?.cost_per_click || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: "Ad Performance",
      width: 200,
      render: (e, row) => {
        return (
          <Row style={{ width: "100%" }} gutter={[5, 5]}>
            <Col span={24}>
              <Flex justify="space-between">
                <span style={{ color: "#676767", marginRight: "5px" }}>
                  Ad Spend
                </span>

                <span style={{ color: "#009ffb" }}>
                  ${NumberWithCommas(row?.spend || 0)}
                </span>
              </Flex>
            </Col>
            {/* {row?.cIndex === expandKeywordSearchView && ( */}
            {expandKeywordSearchView.includes(row?.cIndex) && (
              <>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      ACOS
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.acos || 0)}%
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      ROAS
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      {NumberWithCommas(row?.roas || 0)}
                    </span>
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex justify="space-between">
                    <span style={{ color: "#676767", marginRight: "5px" }}>
                      Avg CPA
                    </span>

                    <span style={{ color: "#009ffb" }}>
                      ${NumberWithCommas(row?.cpa || 0)}
                    </span>
                  </Flex>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  //create column for the List view
  const columnsViewList = [
    {
      title: "Search Term",
      dataIndex: "search_term",
      key: "search_term",
      className: "highlight-column",
      width: 250,
      filters: columnFilters["search_term"],
      onFilter: dynamicOnFilter("search_term"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("search_term"),
      sorter: (a, b) => a?.search_term?.localeCompare(b?.search_term),
      render: (e, row) => {
        return (
          <div className="one-line-ellipsis">{row?.search_term || ""}</div>
        );
      },
    },
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      className: "highlight-column",
      width: 250,
      filters: columnFilters["campaign"],
      onFilter: dynamicOnFilter("campaign"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("campaign"),
      sorter: (a, b) => a?.campaign?.localeCompare(b?.campaign),
      render: (e, row) => {
        if (!e) return;
        if (e?.split(",")?.length === 1) {
          return e;
        }
        return (
          <Popover
            content={
              <>
                {e?.split(",")?.map((d) => (
                  <div>{d}</div>
                ))}
                <div
                  onClick={() => {
                    setSearchTermLineItemView({
                      show: true,
                      data: row,
                    });
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#009ef7",
                    cursor: "pointer",
                  }}
                >
                  View All
                </div>
              </>
            }
            trigger={["hover"]}
          >
            <div className="one-line-ellipsis">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "match_type",
      key: "match_type",
      className: "highlight-column",
      filters: columnFilters["match_type"],
      onFilter: dynamicOnFilter("match_type"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("match_type"),
      sorter: (a, b) => a?.match_type?.localeCompare(b?.match_type),
      width: 100,
      render: (e) => {
        if (!e) return;
        return (
          <Flex align="baseline">
            <Tooltip title={e} placement="left">
              {(e || "")?.charAt(0)}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      filters: columnFilters["impressions"],
      onFilter: dynamicOnFilter("impressions"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("impressions"),
      sorter: (a, b) => (a?.impressions || 0) - (b?.impressions || 0),
      width: 150,
      render: (e) => {
        return <Flex align="baseline">{NumberWithCommas(e || 0)}</Flex>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      filters: columnFilters["clicks"],
      onFilter: dynamicOnFilter("clicks"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("clicks"),
      sorter: (a, b) => (a?.clicks || 0) - (b?.clicks || 0),
      width: 150,
      render: (e) => {
        return <div>{NumberWithCommas(e || 0)}</div>;
      },
    },
    {
      title: "CTR",
      dataIndex: "click_through_rate",
      key: "click_through_rate",
      filters: columnFilters["click_through_rate"],
      onFilter: dynamicOnFilter("click_through_rate"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("click_through_rate"),
      sorter: (a, b) =>
        (a?.click_through_rate || 0) - (b?.click_through_rate || 0),
      width: 150,
      render: (e) => {
        return <div>{NumberWithCommas(e || 0)}%</div>;
      },
    },
    {
      title: "Avg Bid",
      dataIndex: "keyword_bid",
      key: "keyword_bid",
      filters: columnFilters["keyword_bid"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("keyword_bid"),
      onFilter: dynamicOnFilter("keyword_bid"),
      sorter: (a, b) => (a?.keyword_bid || 0) - (b?.keyword_bid || 0),
      width: 150,
      render: (e) => {
        return <div>{NumberWithCommas(e || 0)}</div>;
      },
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      filters: columnFilters["sales"],
      filterDropdown: filterDropdownFilter("sales"),
      onFilter: dynamicOnFilter("sales"),
      sorter: (a, b) => (a?.sales || 0) - (b?.sales || 0),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      width: 150,
      render: (e) => {
        return (
          <div>${NumberWithCommas(e || 0)}</div>
        );
      },
    },
    {
      title: "Purchases",
      dataIndex: "purchases",
      key: "purchases",
      filters: columnFilters["purchases"],
      filterDropdown: filterDropdownFilter("purchases"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("purchases14d"),
      sorter: (a, b) => (a?.purchases14d || 0) - (b?.purchases14d || 0),
      width: 150,
      render: (e) => {
        return (
          <div>{NumberWithCommas(e || 0)}</div>
        );
      },
    },
    {
      title: "Avg CR",
      dataIndex: "conversion_rate",
      key: "conversion_rate",
      filters: columnFilters["conversion_rate"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("conversion_rate"),
      onFilter: dynamicOnFilter("conversion_rate"),
      sorter: (a, b) => (a?.conversion_rate || 0) - (b?.conversion_rate || 0),
      width: 150,
      render: (e) => {
        return (
          <div>{NumberWithCommas(e || 0)}%</div>
        );
      },
    },
    {
      title: "Avg CPC",
      dataIndex: "cost_per_click",
      key: "cost_per_click",
      filterDropdown: filterDropdownFilter("cost_per_click"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filters: columnFilters["cost_per_click"],
      onFilter: dynamicOnFilter("cost_per_click"),
      sorter: (a, b) => (a?.cost_per_click || 0) - (b?.cost_per_click || 0),
      width: 150,
      render: (e) => {
        return <div>${NumberWithCommas(e || 0)}</div>;
      },
    },
    {
      title: "Ad Spend",
      dataIndex: "spend",
      key: "spend",
      filters: columnFilters["spend"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("spend"),
      width: 150,
      onFilter: dynamicOnFilter("spend"),
      sorter: (a, b) => (a?.ad_spend || 0) - (b?.ad_spend || 0),
      render: (e) => {
        return <div>${NumberWithCommas(e || 0)}</div>;
      },
    },
    {
      title: "ACOS",
      dataIndex: "acos",
      key: "acos",
      filters: columnFilters["acos"],
      onFilter: dynamicOnFilter("acos"),
      filterDropdown: filterDropdownFilter("acos"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        } 
      },
      sorter: (a, b) => (a?.acos || 0) - (b?.acos || 0),
      width: 150,
      render: (e) => {
        return <div>{NumberWithCommas(e || 0)}%</div>;
      },
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      key: "roas",
      filters: columnFilters["roas"],
      filterDropdown: filterDropdownFilter("roas"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("roas"),
      sorter: (a, b) => (a?.roas || 0) - (b?.roas || 0),
      render: (e) => {
        return <div>{NumberWithCommas(e || 0)}</div>;
      },
      width: 150,
    },
    {
      title: "Avg CPA",
      dataIndex: "cpa",
      key: "cpa",
      filters: columnFilters["cpa"],
      onFilter: dynamicOnFilter("cpa"),
      filterDropdown: filterDropdownFilter("cpa"),
      sorter: (a, b) => (a?.cpa || 0) - (b?.cpa || 0),
      render: (e) => {
        return <div>${NumberWithCommas(e || 0)}</div>;
      },
      width: 150,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
    },
  ];

  const getDays = (start_date, end_date) => {
    const date1 = new Date(start_date);
    const date2 = new Date(end_date);

    const timeDifference = date1 - date2; // Difference in milliseconds
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days

    return dayDifference;
  };

  return (
    <TableWrapper
      id="kt_content_container"
      className="container-fluid custom-ui mt-5"
    >
      <div className="card">
        <div
          className="card-header"
          style={{
            background: "white",
            zIndex: 9,
            top: "0px",
            position: "sticky",
          }}
        >
          <div className="card-title">
            <Flex justify="space-between">
              <span style={{ textTransform: "capitalize" }}>
                All Search Terms
              </span>
            </Flex>
          </div>
          <div className="card-toolbar">
            <Flex align="center" gap={6}>
              {" "}
              <Tabs
                className="me-3"
                type="line"
                tabBarStyle={{ marginBottom: 0 }}
                onChange={(e) => {
                  const obj = {
                    ...searchTermFilterList,
                    source_type: e,
                  };
                  setSearchTermFilterList(obj);
                  getSearchKeyword(obj);
                }}
                activeKey={searchTermFilterList?.source_type}
                items={[
                  {
                    key: "SPONSORED_PRODUCTS",
                    label: "Sponsored Products",
                  },
                  {
                    key: "SPONSORED_BRANDS",
                    label: "Sponsored Brands",
                  },
                ]}
              />
              <Wrapper
                style={{
                  width: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tag color="blue-inverse">
                  {searchTermFilterList?.reporting_type}
                </Tag>
                <DatePickerView
                  onChange={(e) => {
                    const reporting_type =
                      getDays(
                        dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                        dayjs(e?.start_date)?.format("YYYY-MM-DD")
                      ) === 0 ||
                      isNaN(
                        getDays(
                          dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                          dayjs(e?.start_date)?.format("YYYY-MM-DD")
                        )
                      )
                        ? null
                        : getDays(
                            dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                            dayjs(e?.start_date)?.format("YYYY-MM-DD")
                          ) > 15
                        ? "Monthly"
                        : "Weekly";

                    const obj = {
                      ...searchTermFilterList,

                      start_date: e?.start_date
                        ? dayjs(e?.start_date)?.format("YYYY-MM-DD")
                        : null,
                      end_date: e?.end_date
                        ? dayjs(e?.end_date)?.format("YYYY-MM-DD")
                        : null,
                      reporting_type: reporting_type,
                      month_no:
                        reporting_type === "Monthly"
                          ? dayjs(e?.start_date)?.format("M")
                          : null,
                      report_year: dayjs(e?.start_date)?.format("YYYY"),
                      week_no:
                        reporting_type === "Weekly"
                          ? dayjs(e?.end_date).week()
                          : null,
                    };
                    setFilters(obj);
                    getKeywordTracking(obj);
                    setSearchTermFilterList(obj);
                  }}
                  value={
                    searchTermFilterList?.start_date &&
                    searchTermFilterList?.end_date
                      ? [
                          searchTermFilterList?.start_date,
                          searchTermFilterList?.end_date,
                        ]
                      : null
                  }
                />
              </Wrapper>
            </Flex>
            <div
              style={{
                background: "#00B660",
                borderRadius: 6,
                padding: "6px 15px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "#FAFAFB",
                  fontSize: 13,

                  fontWeight: 500,
                  letterSpacing: 0.1,
                  wordWrap: "break-word",
                }}
                onClick={() => downloadFileLineItems()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  className="me-3"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                    fill="white"
                  />
                </svg>
                Export
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          <CustomColumns>
            <Flex align="center" justify="space-between" gap={8}>
              <Flex align="center" gap={6}>
                <Select
                  className="w-200px"
                  allowClear
                  loading={dropdownLoading}
                  size="middle"
                  options={dropdownList?.map((d) => ({
                    label: "sales" === d?.value ? "Sales (Default)" : d?.label,
                    value: d?.value,
                  }))}
                  value={searchTermFilterList?.sort_by || null}
                  onChange={(e) => {
                    const obj = {
                      ...searchTermFilterList,
                      sort_by: e,
                    };
                    setSearchTermFilterList(obj);
                    getKeywordTracking(obj);
                  }}
                  placeholder={"Sort By"}
                />
                <Input
                  value={searchTermFilterList?.search_term}
                  className="w-200px"
                  allowClear
                  onChange={(e) => {
                    const obj = {
                      ...searchTermFilterList,
                      search_term: e.target.value,
                    };
                    setSearchTermFilterList(obj);
                  }}
                  onPressEnter={async () => {
                    await getKeywordTracking(searchTermFilterList);
                    setSearchTermValExist(searchTermFilterList?.search_term);
                  }}
                  placeholder="Search by Search Term"
                />
                {searchTermValExist && (
                  <div style={{ fontSize: "11px" }}>
                    {searchTermData?.length} Result(s) Found
                  </div>
                )}
              </Flex>
              <Flex align="center" gap={12}>
                <Segmented
                  selected={"Folder"}
                  onChange={(e) => {
                    setAllSearchTermView(e);
                  }}
                  options={[
                    {
                      label: null,
                      value: "Folder",
                      icon: <FolderOpenOutlined />,
                    },
                    {
                      label: null,
                      value: "List",
                      icon: <BarsOutlined />,
                    },
                  ]}
                />
              </Flex>
            </Flex>

            {!searchTermLoading && (
              <>
                {allSearchTermView == "Folder" ? (
                  <Table
                    // columns={columnsView?.map((d) => {
                    //   return {
                    //     ...d,
                    //     // className: d?.className + "minWidth-table",
                    //     render: (props, row, index) =>
                    //       RenderTable(props, row, index, d, 2),
                    //   };
                    // })}
                    columns={columnsView}
                    scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
                    dataSource={searchTermData?.map((r, i) => ({
                      ...r,
                      cIndex: i + 1,
                    }))}
                    pagination={{
                      defaultPageSize: 50,
                      showSizeChanger: true,
                    }}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    loading={searchTermLoading}
                    bordered
                    rowKey={"keywordIndex"}
                    size="small"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    rowClassName={(_, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    // expandable={{
                    //   defaultExpandAllRows: true,
                    //   expandIcon: () => <></>,
                    // }}
                  />
                ) : (
                  <Table
                    // columns={columnsView?.map((d) => {
                    //   return {
                    //     ...d,
                    //     // className: d?.className + "minWidth-table",
                    //     render: (props, row, index) =>
                    //       RenderTable(props, row, index, d, 2),
                    //   };
                    // })}
                    columns={columnsViewList}
                    scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
                    dataSource={searchTermData?.map((r, i) => ({
                      ...r,
                      cIndex: i + 1,
                    }))}
                    pagination={{
                      defaultPageSize: 50,
                      showSizeChanger: true,
                    }}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    loading={searchTermLoading}
                    bordered
                    rowKey={"keywordIndex"}
                    size="small"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    rowClassName={(_, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    // expandable={{
                    //   defaultExpandAllRows: true,
                    //   expandIcon: () => <></>,
                    // }}
                  />
                )}
              </>
            )}
            {searchTermLoading && (
              <Table
                columns={columnsView?.map((d) => {
                  return {
                    ...d,
                    className: d?.className + "minWidth-table",
                  };
                })}
                scroll={{ x: "max-content" }}
                dataSource={[]}
                pagination={false}
                style={{ marginTop: "10px", marginBottom: "10px" }}
                loading={true}
                bordered
                size="small"
              />
            )}
          </CustomColumns>
        </div>
      </div>
    </TableWrapper>
  );
}
