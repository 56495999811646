import { message, Modal } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../apis";
import SearchTermsViewLineItems from "../search-terms-line-items";
import { WrapperContant } from "../style";
import { useEffect } from "react";
import SearchTermsLine from "../search-terms-line";
import { LoadingModal } from "../../../../components/upload-modals";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AllSearchTermsView() {
  const history = useHistory();
  const lastMonthStartDate = dayjs()
    .subtract(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");
  const lastMonthEndDate = dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format("YYYY-MM-DD");

  const [expandKeywordSearchView, setExpandKeywordSearchView] = useState(
    // null
    []
  );
  const [searchTermFilterList, setSearchTermFilterList] = useState({
    source_type: "SPONSORED_PRODUCTS",
    reporting_type: "Monthly", // Weekly
    start_date: lastMonthStartDate,
    end_date: lastMonthEndDate,
    week_no: null,
    month_no: dayjs(lastMonthEndDate, "YYYY-MM-DD").format("M"),
    report_year: dayjs(lastMonthEndDate, "YYYY-MM-DD").format("YYYY"),
    sort_by: null,
    search_term: null,
  });
  const [searchTermLineItemView, setSearchTermLineItemView] = useState({
    show: false,
  });
  const [searchTermData, setSearchTermData] = useState([]);
  const [searchTermLoading, setSearchTermLoading] = useState(true);
  const [keywordSort, setKeywordSort] = useState([]);
  const [keywordSortLoading, setKeywordSortLoading] = useState(true);

  const [filters, setFilters] = useState({
    reporting_type: "Weekly", // Weekly
    start_date: lastMonthStartDate,
    end_date: lastMonthEndDate,
    week_no: null,
    month_no: dayjs(lastMonthEndDate, "YYYY-MM-DD").format("M"),
    report_year: dayjs(lastMonthEndDate, "YYYY-MM-DD").format("YYYY"),
  });

  const getSearchKeyword = async (data) => {
    setSearchTermLoading(true);
    setSearchTermData([]);
    try {
      const response = await MakeApiCall(
        `ads/search-terms?source_type=${
          data?.source_type || ""
        }&view_all=1&reporting_type=${data?.reporting_type || ""}&week_no=${
          data?.week_no || ""
        }&month_no=${data?.month_no || ""}&report_year=${
          data?.report_year || ""
        }&sort_by=${data?.sort_by || ""}&search_term=${
          data?.search_term || ""
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setSearchTermData(response?.data || []);
        setSearchTermLoading(false);
      } else {
        setSearchTermLoading(false);
        setSearchTermData([]);
        message.warning(response?.message);
      }
    } catch (error) {
      setSearchTermLoading(false);
      setSearchTermData([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const adsKeywordSortAction = async () => {
    setKeywordSortLoading(true);
    setKeywordSort([]);
    try {
      const response = await MakeApiCall(`ads/keyword-sort`, "get", null, true);
      if (response?.status) {
        setKeywordSort(
          Object.entries(response?.data || {})?.map(([key, value]) => ({
            value: key,
            label: value,
          }))
        );

        if (
          Object.entries(response?.data || {})
            ?.map(([key, value]) => ({
              value: key,
              label: value,
            }))
            ?.findIndex((e) => e?.value === "sales") !== -1
        ) {
          setSearchTermFilterList({
            ...searchTermFilterList,
            sort_by: "sales",
          });
          getSearchKeyword({ ...searchTermFilterList, sort_by: "sales" });
        }

        setKeywordSortLoading(false);
      } else {
        getSearchKeyword({ ...searchTermFilterList });
        setKeywordSort([]);
        setKeywordSortLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      getSearchKeyword({ ...searchTermFilterList });
      setKeywordSort([]);
      setKeywordSortLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const presetRanges_ = [
    // {
    //   label: "Yesterday",
    //   value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    // },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs()],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs()],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs()],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];

  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  useEffect(() => {
    adsKeywordSortAction();
    return () => {};
  }, []);

  if (searchTermLineItemView?.show) {
    return (
      <SearchTermsLine
        searchTermFilterList={searchTermFilterList}
        searchTermLineItemView={searchTermLineItemView}
        onClose={() => {
          setSearchTermLineItemView({ show: false });
        }}
      />
    );
  }

  // DOWNLOAD LINE SEARCH ITEMS
  const downloadFileLineItems = async () => {
    try {
      message.destroy();
      LoadingModal("Line Items", "Exporting...");
      const response = await MakeApiCall(
        `download-manager/export`,
        "post",
        {
          source_type: "Ads 360",
          record_type: "All Search Terms",
          start_date: filters?.start_date,
          end_date: filters?.start_date,
          param_filter: {
            source_type: searchTermFilterList?.source_type,
            month_no: filters?.month_no,
            week_no: filters?.week_no,
            report_year: filters?.report_year,
            reporting_type: searchTermFilterList?.reporting_type,
            search_term: searchTermLineItemView?.data?.search_term || "",
          },
        },
        true
      );
      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        history.push("/download-manager");
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  return (
    <WrapperContant
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <>
        {" "}
        <SearchTermsViewLineItems
          searchTermFilterList={searchTermFilterList}
          setSearchTermFilterList={setSearchTermFilterList}
          setKeywordViewAll={() => {}}
          setExpandKeywordSearchView={setExpandKeywordSearchView}
          getKeywordTracking={getSearchKeyword}
          expandKeywordSearchView={expandKeywordSearchView}
          dropdownList={keywordSort}
          dropdownLoading={keywordSortLoading}
          searchTermData={searchTermData}
          searchTermLoading={searchTermLoading}
          presets={presetRanges}
          getSearchKeyword={getSearchKeyword}
          setSearchTermLineItemView={setSearchTermLineItemView}
          setApiCallSearchView={() => {}}
          downloadFileLineItems={downloadFileLineItems}
          filters={filters}
          setFilters={setFilters}
        />
      </>
    </WrapperContant>
  );
}
