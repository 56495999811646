import React, { useContext, useEffect } from "react";
import { Wrapper } from "./style";
import { useState } from "react";
import { Alert, Button, Input, message, Modal } from "antd";
import { GlobalContext } from "../../common-context";
import { Redirect } from "react-router-dom";
import { MakeApiCall } from "../../apis";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Icons from "../../components/icons";
import styled from "styled-components";
import { LoadingModal } from "../../components/upload-modals";

const LearnBTNWrapper = styled(Button)`
  border-color: #0057d3;
  position: absolute;
  bottom: 20px;
  color: #0057d3;
`;
const Login = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({
    username: null,
    password: null,
  });
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState("");

  const [errorView, setErrorView] = useState({
    show: false,
    message: "",
    type: "",
  });

  const contextValue = useContext(GlobalContext);
  const CheckToken = async (token) => {
    try {
      message.destroy();
      LoadingModal("Auth", "User Verification...");
      const response = await MakeApiCall(`user-verify${token}`, "get", null);

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();

        if (response?.message?.page_redirect === "register-step2") {
          setRedirect(
            `/seller-sign-up?email=${token?.split("email=")?.[1] || ""}`
          );
        }
        setErrorView({
          show: true,
          message: response?.message?.message,
          type: "success",
        });
      } else {
        Modal.destroyAll();
        message.destroy();
        setErrorView({
          show: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  useEffect(() => {
    if (location?.pathname === "/user-verify") {
      CheckToken(location.search);
    }
  }, [location?.pathname]);

  const submit = async () => {
    setLoading(true);
    const response = await MakeApiCall("seller-login", "post", { ...userInfo });
    if (response?.status) {
      localStorage.setItem("root", location?.pathname);
      localStorage.setItem("user", JSON.stringify(response?.data?.user_data));
      contextValue?.updateCommonGlobalVal({
        user_: response?.data?.user_data,
        credentials: response?.data?.credentials,
      });
      localStorage.setItem("token", response?.data?.auth_token);
      localStorage.setItem(
        "credentials",
        JSON.stringify(response?.data?.credentials)
      );
      localStorage.setItem("user-type", response?.data?.user_data?.user_type);
      setErrorView({
        show: true,
        message: response?.message,
        type: "success",
      });
      setRedirect(
        response?.data?.user_data?.user_type === 1
          ? "/dashboard"
          : sessionStorage.getItem("currantUrl") || "/dashboard"
      );
      setLoading(false);
      sessionStorage.clear();
    } else {
      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  if ((redirect || localStorage?.getItem("token")) && !loading) {
    return (
      <Redirect
        to={
          redirect
            ? redirect
            : localStorage.getItem("admin")
            ? "/dashboard"
            : "/dashboard"
        }
      />
    );
  }

  return (
    <Wrapper className="hide-scrollbar d-flex flex-column flex-root">
      <div className="">
        <div className="row">
          <div
            className="col-lg-5 col-md-12 p-10 hide-scrollbar"
            style={{ overflow: "auto", height: "100vh" }}
          >
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 ">
              <div className="py-9 login-frame">
                <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                  <div className="card-body pt-0 pb-0 px-2">
                    <div className="heading-info mb-8">
                      <div className="logo-section mt-2">
                        <img
                          style={{ height: "auto", width: "45%" }}
                          src="/assets/media/ArgoMetrix_logo_SCREEN.png"
                          alt="homepage"
                          className="dark-logo"
                        />
                      </div>
                      <div
                        id="portal_name_color"
                        className="seller-name-section mt-2"
                      >
                        <p>Seller Intelligence platform</p>
                      </div>
                    </div>

                    <div className="heading-text">
                      <h2 className="heading-text-head">Seller Log in</h2>
                      <p>
                        Sign in to continue.
                        <br />
                        <Icons type="info-sign-in" />
                        <span style={{ color: "#424242" }} className="ms-1">
                          Select seller once logged in if multiple seller exist.{" "}
                        </span>
                      </p>
                    </div>
                    {errorView?.show && (
                      <Alert
                        message={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errorView?.message,
                            }}
                          />
                        }
                        type={errorView?.type}
                        closable
                        className="mb-5"
                        afterClose={() => {
                          setErrorView({ show: false, message: "" });
                        }}
                      />
                    )}
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label style={{ color: "#A8A8A8" }}>Email</label>
                      <Input
                        placeholder="Email"
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            username: e.target.value,
                          })
                        }
                        value={userInfo?.username}
                        size="large"
                        name="email"
                        autoComplete="off"
                      />
                    </div>

                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      <label style={{ color: "#A8A8A8" }}>Password</label>
                      <Input
                        size="large"
                        placeholder="Password"
                        type="password"
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            password: e.target.value,
                          })
                        }
                        value={userInfo?.password}
                        name="password"
                        autoComplete="off"
                      />
                      {/*end::Password*/}
                    </div>
                    <p className="text-end">
                      <Link to="/forgot-password"> Forgot password ?</Link>
                    </p>
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      <button
                        id="kt_sign_in_submit"
                        className="btn btn-primary me-2 flex-shrink-0 w-100"
                        onClick={() => {
                          message.destroy();
                          if (!userInfo?.username || !userInfo?.password) {
                            return message.warning("Please fill input");
                          }
                          submit();
                        }}
                      >
                        {!loading ? (
                          <span
                            className="indicator-label"
                            data-kt-translate="sign-in-submit"
                          >
                            Login
                          </span>
                        ) : (
                          <span>
                            <span data-kt-translate="general-progress">
                              Please wait...
                            </span>
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        )}
                      </button>
                    </div>
                    <div className="end-login-section">
                      <p>
                        {" "}
                        Don't have an account?{" "}
                        <Link to="/seller-sign-up">Register</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="m-0"></div>
            </div>
            <LearnBTNWrapper
              onClick={() => window.open("https://argometrix.com/platform")}
              size="large"
            >
              <Icons type="Learn-More-sign-in" />
              <span className="ms-2">Learn More</span>
            </LearnBTNWrapper>
          </div>

          <div className="col-lg-7 col-md-12 d-lg-flex flex-lg-row-fluid w-lg-50 w-md-100 bgi-size-cover bgi-position-y-center bgi-position-x-center bgi-no-repeat">
            <div className="img-wrapper">
              <img src="/assets/media/Seller_Login_Image.png" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Login;
