import { Empty, Skeleton, Spin } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CardView from "./lib/card-view";

function CardsView({
  cardList,
  setSelectedCard,
  backgroundColor,
  getBorderColor,
  overviewData,
  borderColor,
  signConvert,
  getOp,
  getFooter,
  getArrowColor,
  selectedCard,
  overviewLoading,
  setCardList,
  type,
  setMetricGraphLoading,
}) {
  const changeTextColors = {
    "#FDC71E": "#925252",
    "#E31715": "#E31715",
    "#00B55D": "#00532a",
  };
  const footerLeftTextColors = {
    "#FDC71E": "#925252",
    "#E31715": "#ffd2d1",
    "#00B55D": "#00532a",
  };
  const colorsName = {
    "#00B55D": "green",
    "#E31715": "red",
    "#FDC71E": "yellow",
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedCard = cardList[dragIndex];
      const newCards = [...cardList];
      newCards.splice(dragIndex, 1);
      newCards.splice(hoverIndex, 0, draggedCard);
      setCardList(newCards);
    },
    [cardList]
  );

  if (overviewLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (cardList?.length === 0) {
    return (
      <div className="row g-5 g-xl-5">
        <Empty />
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="row g-5 g-xl-5 crt">
        {cardList?.map((d, i) => {
          return (
            <CardView
              i={i}
              setMetricGraphLoading={setMetricGraphLoading}
              colorsName={colorsName}
              borderColor={borderColor}
              getBorderColor={getBorderColor}
              overviewData={overviewData}
              d={d}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              backgroundColor={backgroundColor}
              overviewLoading={overviewLoading}
              signConvert={signConvert}
              changeTextColors={changeTextColors}
              getOp={getOp}
              getFooter={getFooter}
              getArrowColor={getArrowColor}
              footerLeftTextColors={footerLeftTextColors}
              key={d.id}
              index={i}
              id={d.id}
              type={type}
              moveCard={moveCard}
            />
          );
        })}
      </div>
    </DndProvider>
  );
}

export default CardsView;
