import { message, Modal, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { PropsFilter, RenderTable } from "../../../../config";
import { Wrapper } from "./style";
import { LoadingModal } from "../../../../components/upload-modals";

const PendingLineItem = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "#",
      align: "center",
      render: (row) => {
        return (
          <div
            className="d-flex justify-content-center w-100"
            style={{ width: "100%" }}
          >
            {row?.key}
          </div>
        );
      },
    },

    {
      title: "Menu Item",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Top Level Heading",
      dataIndex: "top_level_heading",
      key: "top_level_heading",
      filterIndex: "top_level_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Level 2nd Heading",
      dataIndex: "level_2nd_heading",
      key: "level_2nd_heading",
      filterIndex: "level_2nd_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Level 3rd Heading",
      dataIndex: "level_3rd_heading",
      key: "level_3rd_heading",
      filterIndex: "level_3rd_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Level 4th Heading",
      dataIndex: "level_4th_heading",
      key: "level_4th_heading",
      filterIndex: "level_4th_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Level 5th Heading",
      dataIndex: "level_5th_heading",
      key: "level_5th_heading",
      filterIndex: "level_5th_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Line Item Heading",
      dataIndex: "line_item_heading",
      key: "line_item_heading",
      filterIndex: "line_item_heading",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Line Item Slug",
      dataIndex: "line_item_slug",
      key: "line_item_slug",
      filterIndex: "line_item_slug",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Is Pending",
      dataIndex: "status",
      render: (e, row) => {
        return (
          <Tag color={e == 0 ? "orange" : "success"}>
            {e == 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },

    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   filterIndex: "type",
    // },
  ];

  const getList = async () => {
    setLoading(false);
    const response = await MakeApiCall(
      `line-items?status=0`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(
        response?.data?.map((d, i) => ({
          ...d,
          key: i + 1,
          title: d?.menuItem?.title || d?.top_level_heading,
        })) || []
      );
      setLoading(false);
    } else {
      setList([]);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const pendingLineItemsAction = async (data) => {
    try {
      message.destroy();
      LoadingModal("Pending Line Items", "Changing status...");
      const response = await MakeApiCall(
        `line-items/update-status`,
        "post",
        data,
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        setSelectedRowKeys([]);
        getList();
        message.success(response?.message);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {
      setLoading(true);
      setList([]);
    };
  }, []);
  return (
    <Wrapper>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            Pending Line Item
            <span
              style={{
                fontSize: "13px",
                color: "#000000e0",
                fontWeight: 400,
                marginLeft: "4px",
                position: "relative",
                top: "1px",
              }}
            >
              {selectedRowKeys?.length
                ? `- ${selectedRowKeys?.length} Menu Item selected`
                : ""}
            </span>
          </div>
          <div className="card-toolbar">
            <a
              className="add-btn"
              style={{
                opacity: selectedRowKeys?.length === 0 ? 0.5 : 1,
                cursor:
                  selectedRowKeys?.length === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                const line_item_slug_ids = selectedRowKeys?.filter(
                  (d) => d?.menuItem
                );
                const finance_line_items_ids = selectedRowKeys?.filter(
                  (d) => !d?.menuItem
                );

                pendingLineItemsAction({
                  line_item_slug_ids: line_item_slug_ids?.map((d) => d?.id),
                  finance_line_items_ids: finance_line_items_ids?.map(
                    (d) => d?.id
                  ),
                });
              }}
            >
              Save
            </a>
          </div>
        </div>
        <div className="card-body p-0 pt-0 px-5">
          <Table
            dataSource={list}
            columns={columns?.map((d) => ({
              ...d,
              className: d?.title === "#" ? "maxWidth-table" : "minWidth-table",
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            loading={loading}
            rowKey={"key"}
            pagination={{
              size: "default",
              showSizeChanger: true,
              defaultPageSize: 100,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            rowSelection={{
              selectedRowKeys: selectedRowKeys?.map((d) => d.key),
              onChange: (_, row) => {
                setSelectedRowKeys(row);
              },
            }}
            bordered
            size="small"
            scroll={{ x: "max-content", y: "calc(100vh - 410px)" }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default PendingLineItem;
