import { DatePicker, Modal, Table, Tag, Tooltip } from "antd";
import React from "react";
import { numberformat, PropsFilter, RenderTable } from "../../../../config";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { LoadingModal } from "../../../../components/upload-modals";

const Top10ItemPerformance = ({
  onLineItem,
  setViewAllTop10Items,
  viewAllTop10Items,
  topTenItemsPerformanceLoading,
  topTenItemsPerormance,
  getDataAction,
  selectedRange,
  presetRanges,
  getSalesItemPerformanceData,
  filters,
  handleDateRangeChange,
  actionCall,
}) => {
  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const dataSource =
    topTenItemsPerormance?.item_data?.map((d, i) => ({
      ...d,
      key: i + 1,
      unit_sold: parseFloat(d?.unit_sold),
      last_period: parseFloat(d?.last_period),
      session: parseFloat(d?.session),
    })) || [];

  // Define columns
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      align: "center",
      ...PropsFilter("key"),
      render: (e) => {
        return <div className="d-flex justify-content-center w-100">{e}</div>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ...PropsFilter("asin"),
      render: (e, row) => {
        return (
          <div
            onClick={() => {
              console.log(row, e, "asdasdasd");

              onLineItem({ asin_: e, sku: row?.sku, title: row?.product_name });
            }}
            className="d-flex align-items-center justify-content-between w-100 text-hover-primary cursor-pointer"
          >
            {e}
            <div
              className="bg-primary ms-2"
              style={{
                borderRadius: "100%",
                postion: "relative",
                width: "15px",
                height: "15px",
              }}
            >
              {icons}
            </div>
          </div>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "sku",
      key: "sku",
      ...PropsFilter("sku"),
    },
    {
      title: "Title",
      dataIndex: "product_name",
      key: "product_name",
      ...PropsFilter("product_name"),
      width: 200,
      render: (e) => {
        return (
          <Tooltip title={e}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "300px",
                display: "block",
              }}
            >
              {e}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Units Sold",
      dataIndex: "unit_sold",
      key: "unit_sold",
      ...PropsFilter("unit_sold"),
      render: (text) => `${text?.toLocaleString()}`,
      align: "right",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      ...PropsFilter("amount"),
      align: "right",
      render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Sessions",
      dataIndex: "session",
      key: "session",
      align: "right",
      ...PropsFilter("session"),
      render: (text) => `${(text || 0).toLocaleString()}`,
    },
    {
      title: "USP (%)",
      dataIndex: "usp",
      key: "usp",
      align: "right",
      ...PropsFilter("usp"),
      render: (text) => `${text}%`,
    },
    {
      title: "BB (%)",
      dataIndex: "buy_box_pect",
      key: "buy_box_pect",
      ...PropsFilter("buy_box_pect"),
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "Velocity",
      dataIndex: "velocity",
      key: "velocity",
      align: "right",
      ...PropsFilter("velocity"),
    },
    {
      title: "LP",
      dataIndex: "last_period",
      key: "last_period",
      align: "right",
      ...PropsFilter("last_period"),
    },
    {
      title: "LP Velocity",
      dataIndex: "last_period_velocity",
      key: "last_period_velocity",
      ...PropsFilter("last_period_velocity"),
      align: "right",
    },
    {
      title: "Variance (%)",
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...PropsFilter("variance"),
      render: (text) =>
        `${(text || 0).toLocaleString("en-US", { maximumFractionDigits: 2 })}%`,
    },
    {
      title: "Share (%)",
      dataIndex: "share",
      key: "share",
      ...PropsFilter("share"),
      align: "right",
      render: (text) =>
        `${(text || 0).toLocaleString("en-US", { maximumFractionDigits: 2 })}%`,
    },
  ];
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const downloadExcel = (filename) => {
    LoadingModal(filename, "Exporting...");
    const wb = XLSX.utils.book_new();

    const changeKeyList = dataSource?.map((d) => {
      const dataObject = {};

      Object?.keys(d || {}).forEach((key) => {
        const indexTitle = columns?.filter((g) => g?.dataIndex === key);
        const { title, dataIndex } = indexTitle?.[0] || [];

        dataObject[title] = d?.[dataIndex]; // You can set initial values to null if needed
      });
      return dataObject;
    });

    // Add a worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet(changeKeyList);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Convert the workbook to a binary Excel file (binary string)
    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    document.body.appendChild(link);
    link.click();
    Modal.destroyAll();
    document.body.removeChild(link);
  };
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          {viewAllTop10Items && (
            <div className="me-3">
              <span
                className="svg-icon svg-icon-muted svg-icon-2hx"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setViewAllTop10Items(false);
                  getDataAction(0);
                }}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                  />
                  <path
                    d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          )}
          <b>{`${
            viewAllTop10Items ? "Catalog" : "Top10 Item"
          }  Performance`}</b>
        </div>
        <div className="card-toolbar">
          {viewAllTop10Items ? (
            <>
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>

              <DatePicker.RangePicker
                presets={presetRanges}
                allowClear={false}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={(current) => {
                  // Disable future dates
                  return current && current >= dayjs().endOf("day");
                }}
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };

                  actionCall(obj, e, _, 1);
                }}
              />
              <div
                style={{
                  background: "#00B660",
                  borderRadius: 6,
                  padding: "6px 15px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <div
                  onClick={() => downloadExcel("Catalog Performance")}
                  style={{
                    color: "#FAFAFB",
                    fontSize: 13,

                    fontWeight: 500,
                    letterSpacing: 0.1,
                    wordWrap: "break-word",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    className="me-3"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                      fill="white"
                    />
                  </svg>
                  Export
                </div>
              </div>
            </>
          ) : (
            <>
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>

              <DatePicker.RangePicker
                presets={presetRanges}
                allowClear={false}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={(current) => {
                  return current && current >= dayjs().endOf("day");
                }}
                onChange={(e, _) => {
                  // const obj = {
                  // start_date: e?.[0]
                  //   ? dayjs(e?.[0]).format("YYYY-MM-DD")
                  //   : null,
                  //   end_date: e?.[1]
                  //     ? dayjs(e?.[1]).format("YYYY-MM-DD")
                  //     : null,
                  // };

                  const pathData = `start_date=${
                    e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : ""
                  }&end_date=${
                    e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : ""
                  }&selection_type=${handleDateRangeChange(e, _)
                    ?.toLowerCase()
                    ?.split(" ")
                    ?.join("_")
                    ?.toLowerCase()}`;
                  getSalesItemPerformanceData(pathData, 0);

                  // getSalesItemPerformanceData
                  // selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                  console.log(pathData, "pathDatapathDatapathData");

                  // actionCall(obj, e, _, 1);
                }}
              />
            </>
          )}
        </div>
      </div>

      <div
        className="card-body pt-0"
        style={{ width: "100%", position: "relative" }}
      >
        <Table
          size="small"
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "even-row" : "odd-row";
          }}
          scroll={{ x: "max-content" }}
          columns={columns?.map((d) => ({
            ...d,
            className: d?.title === "#" ? "maxWidth-table" : "minWidth-table",
            render: (props, row, index) => RenderTable(props, row, index, d),
          }))}
          dataSource={dataSource}
          loading={topTenItemsPerformanceLoading}
          bordered
          pagination={false}
          summary={() => {
            const {
              amount,
              buy_box_pect,
              last_period,
              last_period_velocity,
              session,
              share,
              unit_sold,
              usp,
              variance,
              velocity,
            } = topTenItemsPerormance?.total || {};
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ background: "#e6eaf1", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell index={0} colSpan={4}>
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={1}>
                    {numberformat(unit_sold || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={2}>
                    ${numberformat(amount || 0, 2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={3}>
                    {numberformat(session || 0, 0)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell align="right" index={9}>
                    {numberformat(usp || 0, 0)}%
                  </Table.Summary.Cell>

                  <Table.Summary.Cell align="right" index={10}>
                    {numberformat(buy_box_pect || 0, 0)}%
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={11}>
                    {numberformat(velocity || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={12}>
                    {numberformat(last_period || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(last_period_velocity || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(variance || 0, 0)}%
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(share || 0, 0)}%
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        {!viewAllTop10Items && (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end align-items-center"
              style={{ position: "relative", top: "10px" }}
            >
              <div
                className="d-flex align-items-center  text-hover-primary cursor-pointer"
                onClick={() => {
                  setViewAllTop10Items(true);
                  getDataAction(1);
                }}
              >
                View All{" "}
                <div
                  className="bg-primary ms-2"
                  style={{
                    borderRadius: "100%",
                    postion: "relative",
                    width: "15px",
                    height: "15px",
                  }}
                >
                  {icons}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Top10ItemPerformance;
