import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { message, Modal, Table, Tag } from "antd";
import { MakeApiCall } from "../../../../apis";
import moment from "moment";
import { PropsFilter, RenderTable } from "../../../../config";
import { LoadingModal } from "../../../../components/upload-modals";

const PendingLocation = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "#",
      align: "center",
      render: (row) => {
        return (
          <div
            className="d-flex justify-content-center w-100"
            style={{ width: "100%" }}
          >
            {row?.key}
          </div>
        );
      },
    },

    {
      title: "Menu item",
      dataIndex: "menu_item",
      key: "menu_item",
      filterIndex: "menu_item",

      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      filterIndex: "slug",
      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },

    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (e, row) => {
        return (
          <Tag color={e == 1 ? "orange" : "success"}>
            {e == 1 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
  ];

  const getList = async () => {
    const response = await MakeApiCall(
      `location?isAll=1&is_pending=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(
        response?.data?.records?.map((d, i) => ({ ...d, key: i + 1 })) || []
      );
      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const pendingLocationAction = async (data) => {
    try {
      message.destroy();
      LoadingModal("Pending Location", "Changeing status...");
      const response = await MakeApiCall(
        `pending-location`,
        "post",
        data,
        true
      );

      if (response?.status) {
        message.destroy();
        setSelectedRowKeys([]);
        Modal.destroyAll();
        getList();
        message.success(response?.message);
      } else {
        message.destroy();
        Modal.destroyAll();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  return (
    <Wrapper>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            Pending Location{" "}
            <span
              style={{
                fontSize: "13px",
                color: "#000000e0",
                fontWeight: 400,
                marginLeft: "4px",
                position: "relative",
                top: "1px",
              }}
            >
              {selectedRowKeys?.length
                ? `- ${selectedRowKeys?.length} Menu Item selected`
                : ""}
            </span>
          </div>
          <div className="card-toolbar">
            <a
              className="add-btn"
              style={{
                opacity: selectedRowKeys?.length === 0 ? 0.5 : 1,
                cursor:
                  selectedRowKeys?.length === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                pendingLocationAction({
                  location_ids: selectedRowKeys?.map((d) => d.id),
                });
              }}
            >
              Save
            </a>
          </div>
        </div>
        <div className="card-body p-0 pt-0 px-5">
          <Table
            dataSource={list}
            columns={columns?.map((d) => ({
              ...d,
              className: d?.title === "#" ? "maxWidth-table" : "minWidth-table",
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            loading={loading}
            rowKey={"key"}
            pagination={{
              size: "default",
              showSizeChanger: true,
              defaultPageSize: 100,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            rowSelection={{
              selectedRowKeys: selectedRowKeys?.map((d) => d.key),
              onChange: (_, row) => {
                setSelectedRowKeys(row);
              },
            }}
            bordered
            size="small"
            scroll={{ x: "max-content", y: "calc(100vh - 410px)" }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default PendingLocation;
