import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  Select,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import dayjs from "dayjs";
import moment from "moment";
import ExportFile from "./export-file";
import { NumberWithCommas, RenderTable } from "../../../../config";
import InfoVideo from "../../../../components/header-icons";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { ErrorModal } from "../../../../components/upload-modals";
dayjs.extend(quarterOfYear);
const Portfolios = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const [sortFilters, setSortFilters] = useState({
    field_name: "",
    sort_by: "desc",
  });

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },

    {
      title: "Portfolio ID",
      dataIndex: "portfolio_id",
      key: "portfolio_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "In Budget",
      dataIndex: "in_budget",
      key: "in_budget",
    },
    {
      title: "Budget Amount",
      dataIndex: "budget_amount",
      key: "budget_amount",
    },
    {
      title: "Budget Currency Code",
      dataIndex: "budget_currency_code",
      key: "budget_currency_code",
    },
    {
      title: "Budget Start Date",
      dataIndex: "budget_start_date",
      key: "budget_start_date",
    },
    {
      title: "Budget End Date",
      dataIndex: "budget_end_date",
      key: "budget_end_date",
    },
    {
      title: "Budget Policy",
      dataIndex: "budget_policy",
      key: "budget_policy",
    },
    {
      title: "Extended Creation Date Time",
      dataIndex: "extended_creation_date_time",
      key: "extended_creation_date_time",
    },
    {
      title: "Extended Last Update Date Time",
      dataIndex: "extended_last_update_date_time",
      key: "extended_last_update_date_time",
    },
    {
      title: "Extended Serving Status",
      dataIndex: "extended_serving_status",
      key: "extended_serving_status",
    },
    {
      title: "Extended Status Reasons",
      dataIndex: "extended_status_reasons",
      key: "extended_status_reasons",
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Profile ID",
      dataIndex: "profile_id",
      key: "profile_id",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
    },
  ];

  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `advertising/ad-portfolios?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      console.log(response, "response");

      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTableLoading(false);
      setLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };

  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Portfolios
          <InfoVideo
            data={locationData}
            title="portfolios"
            className={"ms-3"}
          />
        </div>

        <div className="card-toolbar">
          <ExportFile
            paramsBody={{
              source_type: "Data Source",
              record_type: "Portfolios",
            }}
            title="Portfolios"
            api={`advertising/ad-portfolios?is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0" style={{ padding: "10px" }}>
        {tableLoading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <div className="table-container">
              <Table
                dataSource={list}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                loading={tableLoading}
                onChange={handleChange}
                scroll={{ x: "max-content", y: "calc(100vh - 405px)" }} // , y: "calc(100vh - 460px)"
                columns={columns?.map((d) => {
                  return {
                    ...d,
                    className: "minWidth-table",
                    ...PropsFilter(d?.dataIndex),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d, -1.3),
                  };
                })}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
              />
            </div>
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Portfolios;
