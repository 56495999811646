import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Select,
  Table,
  theme,
  message,
  Empty,
  Modal,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ENDPOINT, RenderTable } from "../../../../config";
import {
  ErrorModal,
  LoadingModal,
  OnOkDeleteModal,
} from "../../../../components/upload-modals";
import DatePickerView from "./date-picker-view";
dayjs.extend(quarterOfYear);
const TopSearchTerms = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { useToken } = theme;
  const [file, setFile] = useState({
    fileName: null,
    file: null,
  });
  const fileInputRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });
  const [messageView, setMessageView] = useState({
    type: null,
    message: "",
  });

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: "asin_wise",
    start_date: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  });
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };
  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "select_year",
      key: "select_year",
    },
    {
      title: "Week Number",
      dataIndex: "week_number",
      key: "week_number",
    },
    {
      title: "Month Number",
      dataIndex: "month_number",
      key: "month_number",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Search Frequency Rank",
      dataIndex: "search_frequency_rank",
      key: "search_frequency_rank",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Search Term",
      dataIndex: "search_term",
      key: "search_term",
    },
    {
      title: "Top Clicked Brand #1",
      dataIndex: "top_clicked_brand_1",
      key: "top_clicked_brand_1",
    },
    {
      title: "Top Clicked Brands #2",
      dataIndex: "top_clicked_brands_2",
      key: "top_clicked_brands_2",
    },
    {
      title: "Top Clicked Brands #3",
      dataIndex: "top_clicked_brands_3",
      key: "top_clicked_brands_3",
    },
    {
      title: "Top Clicked Category #1",
      dataIndex: "top_clicked_category_1",
      key: "top_clicked_category_1",
    },
    {
      title: "Top Clicked Category #2",
      dataIndex: "top_clicked_category_2",
      key: "top_clicked_category_2",
    },
    {
      title: "Top Clicked Category #3",
      dataIndex: "top_clicked_category_3",
      key: "top_clicked_category_3",
    },
    {
      title: "Top Clicked Product #1: ASIN",
      dataIndex: "top_clicked_product_1asin",
      key: "top_clicked_product_1asin",
    },
    {
      title: "Top Clicked Product #1: Product Title",
      dataIndex: "top_clicked_product_1product_title",
      key: "top_clicked_product_1product_title",
    },
    {
      title: "Top Clicked Product #1: Click Share",
      dataIndex: "top_clicked_product_2click_share",
      key: "top_clicked_product_2click_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Top Clicked Product #1: Conversion Share",
      dataIndex: "top_clicked_product_2conversion_share",
      key: "top_clicked_product_2conversion_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Top Clicked Product #2: ASIN",
      dataIndex: "top_clicked_product_3asin",
      key: "top_clicked_product_3asin",
    },
    {
      title: "Top Clicked Product #2: Product Title",
      dataIndex: "top_clicked_product_2product_title",
      key: "top_clicked_product_2product_title",
    },
    {
      title: "Top Clicked Product #2: Click Share",
      dataIndex: "top_clicked_product_2click_share",
      key: "top_clicked_product_2click_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Top Clicked Product #2: Conversion Share",
      dataIndex: "top_clicked_product_2conversion_share",
      key: "top_clicked_product_2conversion_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Top Clicked Product #3: ASIN",
      dataIndex: "top_clicked_product_3asin",
      key: "top_clicked_product_3asin",
    },
    {
      title: "Top Clicked Product #3: Product Title",
      dataIndex: "top_clicked_product_3product_title",
      key: "top_clicked_product_3product_title",
    },
    {
      title: "Top Clicked Product #3: Click Share",
      dataIndex: "top_clicked_product_3click_share",
      key: "top_clicked_product_3click_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Top Clicked Product #3: Conversion Share",
      dataIndex: "top_clicked_product_3conversion_share",
      key: "top_clicked_product_3conversion_share",
      render: (e) => {
        return CommaAndFloat(e || 0);
      },
    },
    {
      title: "Reporting Date",
      dataIndex: "reporting_date",
      key: "reporting_date",
    },
  ];
  const getList = async (data) => {
    setTableLoading(true);

    const response = await MakeApiCall(
      `top-search-term?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_term=${data?.campaign_name || ""}&start_date=${
        data?.start_date || filters?.start_date || ""
      }&end_date=${data?.end_date || filters?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTableLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      setTotalPage(0);
      setTableLoading(false);
      ErrorModal(response?.message);
    }
  };
  const ImportSQP = async (data) => {
    LoadingModal("Top Search Terms", "Importing...");
    const getToken = () => localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getToken()}`);

    var formdata = new FormData();
    formdata.append("top_search_type", "brand");
    formdata.append(
      "top_search_terms_file",
      data?.scp_data_file,
      data?.scp_data_file?.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(ENDPOINT + "top-search-term/import", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        Modal.destroyAll();
        message.destroy();
        if (result?.status === true) {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          setFile({
            fileName: null,
            file: null,
          });
          setPage(1);
          setLoading(true);
          getList({ page: 1, ...sortFilters, ...filters });

          ErrorModal(result?.message);
          setOpenDropdown(false);
        } else {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          ErrorModal(result?.message);
        }
      })
      .catch((error) => {
        Modal.destroyAll();
        message.destroy();
        fileInputRef.current.value = null;
        setFile({
          fileName: null,
          file: null,
        });

        ErrorModal(error?.message);
      });
  };
  const DeleteSCP = async (data) => {
    try {
      message.destroy();
      LoadingModal("Top Search Terms", "Deleting...");
      const response = await MakeApiCall(
        `top-search-term/delete-all`,
        "post",
        {
          scp_ids: data?.map((d) => d?.id),
        },
        true
      );
      if (response?.status === true) {
        Modal.destroyAll();
        setSelectedRowKeys([]);
        message.destroy();
        OnOkDeleteModal(response?.message || "");
        setLoading(true);
        getList({ ...sortFilters, ...filters });
      } else if (response?.status === false) {
        Modal.destroyAll();
        message.destroy();
        ErrorModal(response?.message || "");
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  useEffect(() => {
    // setLoading(true);
    getList({ ...filters, page: page, pageSize: pageSize, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters, page: page, pageSize: pageSize });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRows);
    },
    selectedRowKeys: selectedRowKeys?.map((d) => d?.id), // This binds the selected keys with the component state
  };
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Top Search Terms
          <InfoVideo
            data={locationData}
            title="top_search_terms"
            className={"ms-3"}
          />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            value={filters?.campaign_name}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      campaign_name: e.target.value,
                    },
                    ...sortFilters,
                    page: page,
                    pageSize: pageSize,
                  });
                }
                return {
                  ...prev,
                  campaign_name: e.target.value,
                };
              });
            }}
            placeholder="Search Term"
            className="w-175px ms-3"
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            className="btn btn-sm ms-3 btn-search me-3"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
        </div>
        <div className="card-toolbar">
          <DatePickerView
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.start_date)?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.end_date)?.format("YYYY-MM-DD"),
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters(obj);
            }}
            value={[filters?.start_date, filters?.end_date]}
          />
          <ExportFile
            paramsBody={{
              source_type: "Data Source",
              record_type: "Top Search Terms",
              param_filter: {
                campaign_name: filters?.campaign_name,
              },
            }}
            title="Top Search Terms"
            api={`top-search-term?field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&search_term=${
              filters?.campaign_name || ""
            }&is_export=1`}
          />
          <Dropdown
            menu={{
              items: [],
            }}
            trigger={["click"]}
            className="me-5"
            open={openDropdown}
            onOpenChange={(e) => {
              setOpenDropdown(e);
              if (!e) {
                fileInputRef.current.value = null;
                setFile({
                  fileName: null,
                  file: null,
                });
              }
            }}
            placement="bottomRight"
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu, {
                  style: menuStyle,
                })}

                <div className="px-7 py-5">
                  <div
                    className="mb-10 position-relative"
                    style={{
                      border: "1px solid #c9c9c9",
                      padding: "8px 30px 8px 8px",
                      borderRadius: "10px",
                    }}
                  >
                    <input
                      // className="form-control form-control-sm form-control-solid"
                      // style={{
                      //   backgroundColor: "#b8e7ff",
                      // }}
                      placeholder
                      type="file"
                      accept="text/csv"
                      value={file?.fileName}
                      ref={fileInputRef}
                      onChange={(e) => {
                        setFile({
                          fileName: e.target.value,
                          file: e.target.files?.[0],
                        });
                      }}
                      id="formFile"
                    />
                    <i
                      onClick={() => {
                        fileInputRef.current.value = null;
                        setFile({
                          fileName: null,
                          file: null,
                        });
                      }}
                      className="ki-outline ki-cross-square fs-1 "
                      style={{
                        position: "absolute",
                        right: "4px",
                        top: "11px",
                        bottom: "0px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <Button
                      type="primary"
                      // className="btn btn-sm btn-dark"
                      onClick={() => {
                        const obj = {
                          scp_data_file: file?.file,
                          scp_type: filters.campaign_status,
                        };

                        message.destroy();

                        ImportSQP(obj);
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                  {/*end::Actions*/}
                </div>
              </div>
            )}
          >
            <Button onClick={(e) => e.preventDefault()} type="dashed">
              Upload
            </Button>
          </Dropdown>

          <Button
            onClick={() => DeleteSCP(selectedRowKeys)}
            danger
            disabled={selectedRowKeys?.length === 0}
            type="dashed"
          >
            Delete {selectedRowKeys?.length === list?.length ? "All" : ""}
          </Button>
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              rowKey={"id"}
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              pageSize: pageSize,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default TopSearchTerms;
