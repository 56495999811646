import {
  Button,
  Form,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const { Option, OptGroup } = Select;

const AddCaralog = ({
  setVisible,
  setList,
  pageType,
  id,
  catalogList,
  catalogLocationList,
  catalogLoading,
  catalogLocationLoading,
  getCatalogList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const [metricList, setMetricList] = useState([]);
  const [metricLoading, setMetricLoading] = useState(true);

  const [selectedType, setSelectedType] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);

  const [lineItemList, setLineItemList] = useState([]);
  const [lineItemLoading, setLineItemLoading] = useState(true);

  const [selectedOption, setSelectedOption] = useState("location");

  const addAccessMapping = async (data) => {
    const response = await MakeApiCall(
      `content-access-mapping${
        Object.keys(selectedRow)?.length === 0 ? "" : `/${selectedRow?.id}`
      }`,
      Object.keys(selectedRow)?.length === 0 ? "post" : "put",
      data,
      true
    );

    if (response?.status) {
      setList((prev) => {
        let found = false;
        const newList = prev.map((item) => {
          if (item.id === response.data.id) {
            found = true; // Mark as found and update the item
            return response.data;
          }
          return item; // Return the item unmodified if not matched
        });

        if (!found) {
          // If the item wasn't found in the list, add it
          return [...newList, response.data];
        }

        // Return the updated list if the item was found and updated
        return newList;
      });
      setLoading(false);
      form.resetFields();
      setVisible(false); // Close modal after form submission
    } else {
      setLoading(false);
      message.warning(
        Object.values(response?.data || {})?.[0] || response?.message
      );
    }
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `content-access-mapping/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const { metric_id, location_id, line_item_slug_id, content_catalog_id } =
        response?.data;
      // console.log(response, "response");
      if (metric_id) {
        //   setSelectedType(true);
        //   getCatalogList("", true, metric_id);
        //   form.setFieldsValue({
        //     metric_id: metric_id,
        //     content_catalog_id: content_catalog_id,
        //   });
        // } else {
        //   form.setFieldsValue({
        //     location_id: location_id,
        //     content_catalog_id: content_catalog_id,
        //   });
        //   setSelectedType(false);
        //   getCatalogList(location_id, true);

        // setSelectedType(true);
        // setIsMetricSelected(true);
        setSelectedOption("metric");
        getCatalogList("", true, metric_id);
        form.setFieldsValue({
          metric_id: metric_id,
          content_catalog_id: content_catalog_id,
        });
      } else if (location_id) {
        form.setFieldsValue({
          location_id: location_id,
          content_catalog_id: content_catalog_id,
        });
        // setSelectedType(false);
        setSelectedOption("location");
        getCatalogList(location_id, true);
      } else {
        form.setFieldsValue({
          line_item_slug_id: line_item_slug_id,
          content_catalog_id: content_catalog_id,
        });
        // setSelectedType(false);
        setSelectedOption("lineItem");
        getCatalogList(line_item_slug_id, true);
      }

      setSelectedRow(response?.data || {});

      setPageLoading(false);
    } else {
      setPageLoading(false);
      setSelectedRow({});
    }
  };

  const getMetricList = async () => {
    const response = await MakeApiCall(
      `metric?isAll=1&is_ready=1`,
      "get",
      null,
      true
    );
    setMetricLoading(true);
    if (response?.status) {
      setMetricLoading(false);
      setMetricList(response?.data?.records || []);
    } else {
      setMetricLoading(false);
      setMetricList([]);
      message.warning(response?.message);
    }
  };
  const getLocationList = async () => {
    setLocationLoading(true);
    const response = await MakeApiCall(
      `location?isAll=1&field_name=title&sort_by=asc&pending=0`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationLoading(false);

      setLocationList(response?.data?.records || []);
    } else {
      setLocationLoading(false);
      setLocationList([]);
      message.warning(response?.message);
    }
  };

  const getLineTtemsList = async () => {
    const response = await MakeApiCall(
      `line-items/list?status=1`,
      "get",
      null,
      true
    );
    setLocationLoading(true);
    if (response?.status) {
      setLineItemLoading(false);

      setLineItemList(response?.data || []);
    } else {
      setLineItemLoading(false);
      setLineItemList([]);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    getLocationList();
    getMetricList();
    getLineTtemsList();
    // setLoading(true);
    return () => {};
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (values?.line_item_slug_id) {
          const line_item_slug =
            values?.line_item_slug_id?.split("_line-item-slug");
          const finance_line_item =
            values?.line_item_slug_id?.split("_finance-line-item");
          const data = {
            ...values,
            metric_id: values?.metric_id || null,
            location_id: values?.location_id || null,
            line_item_slug_id:
              line_item_slug?.length === 2 ? line_item_slug?.[0] : null,
            finance_line_item_id:
              finance_line_item?.length === 2 ? finance_line_item?.[0] : null,
          };
          addAccessMapping(data);
        } else {
          const data = {
            ...values,
            metric_id: values?.metric_id || null,
            location_id: values?.location_id || null,
            line_item_slug_id: values?.line_item_slug_id || null,
            finance_line_item_id: values?.finance_line_item_id || null,
          };
          addAccessMapping(data);
        }

        // console.log(values, data, "values");
        // addAccessMapping(data);
        // addAccessMapping({ ...values });
        // addAccessMapping({
        //   ...values,
        //   [values?.metric_id ? "location_id" : "metric_id"]: null,
        // });
      })
      .catch((info) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    // form.setFieldsValue({
    //   ...selectedRow,
    // });
  }, [catalogList, catalogLocationList]);

  // console.log(selectedType ? , "selectedType");

  // Determine which switch is active

  // Dynamic form field properties
  const formFieldProps = {
    location: {
      name: "location_id",
      label: "Location Catalog",
      message: "Please select the Location Catalog!",
    },
    metric: {
      name: "metric_id",
      label: "Metric",
      message: "Please select the Metric!",
    },
    lineItem: {
      name: "line_item_slug_id",
      label: "Line Items",
      message: "Please select the Line Item!",
    },
  };

  const currentFieldProps = formFieldProps[selectedOption] || {};
  // const currentFieldProps = formFieldProps[activeType] || {};

  console.log(locationList, "locationListlocationList");

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            /{" "}
            {Object.keys(selectedRow)?.length === 0
              ? "Content Access Catalog"
              : "Edit Content Access Catalog"}
            {/* <Switch
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e);
                form.setFieldsValue({
                  [e ? "location_id" : "metric_id"]: null,
                  content_catalog_id: "",
                });
              }}
              style={{ position: "relative", top: "1px", left: "10px" }}
              checkedChildren="Metric"
              unCheckedChildren="Location"
            /> */}
            <Radio.Group
              onChange={(e) => {
                setSelectedOption(e.target.value);
                form.resetFields();
              }}
              value={selectedOption}
              style={{ marginLeft: "10px" }}
            >
              <Radio value="location">Location</Radio>
              <Radio value="metric">Metric</Radio>
              <Radio value="lineItem">Line Items</Radio>
            </Radio.Group>
            {/* Individual Switches */}
          </div>
        </div>
        <div className="px-10">
          <Form form={form} layout="vertical">
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const optionsList =
                  selectedOption === "location"
                    ? locationList?.map((d) => ({
                        label: d?.title,
                        value: d?.id,
                      }))
                    : selectedOption === "lineItem"
                    ? lineItemList?.map((d) => ({
                        label: d?.heading,
                        value: `${d?.id}_${d?.type}`,
                        ...d,
                      }))
                    : metricList?.map((d) => ({
                        label: d?.metric_name,
                        value: d?.id,
                      }));
                return (
                  <>
                    <Form.Item
                      // shouldUpdate
                      // name={selectedType ? "metric_id" : "location_id"}
                      // label={selectedType ? "Metric" : "Location Catalog"}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: `Please select the ${
                      //       selectedType ? "Metric" : "Location Catalog"
                      //     }!`,
                      //   },
                      // ]}
                      name={currentFieldProps.name}
                      label={currentFieldProps.label}
                      rules={[
                        {
                          required: true,
                          message: currentFieldProps.message,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        options={optionsList}
                        value={getFieldValue(currentFieldProps.name)}
                        onChange={(e, row) => {
                          if ("lineItem" === selectedOption) {
                            form.setFieldsValue({
                              content_catalog_id: "",
                            });
                          } else {
                            form.setFieldsValue({
                              content_catalog_id: "",
                            });
                          }

                          getCatalogList(e, false, selectedOption);
                          // getCatalogList(
                          //   selectedType ? "" : e,
                          //   false,
                          //   selectedType ? e : ""
                          // );
                        }}
                        loading={
                          selectedOption === "location"
                            ? locationLoading
                            : selectedOption === "metric"
                            ? metricLoading
                            : lineItemLoading
                        }
                      />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>

            <Form.Item
              name="content_catalog_id"
              label="Content Catalog"
              rules={[
                {
                  required: true,
                  message: "Please select the Content Catalog!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={catalogList?.map((d) => ({
                  label: (
                    <div>
                      {d?.title}
                      &nbsp;-&nbsp;{d?.type == 1 ? "Info" : "Video"}
                    </div>
                  ),
                  value: d?.id,
                }))}
                loading={catalogLoading}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="ms-3"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCaralog;
