import { Checkbox, Col, Input, Modal, Row, Select, message } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { useEffect } from "react";
import { LoadingModal } from "../../../../../components/upload-modals";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [inputValue, setInputValue] = useState("");

  const [menusList, setMenusList] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);

  const [metricGroupList, setMetricGroupList] = useState([]);
  const [metricGroupLoading, setMetricGroupLoading] = useState(true);

  const [metricList, setMetricList] = useState([]);
  const [metricLoading, setMetricLoading] = useState(false);

  const [selectedValue, setSelectedValue] = useState({
    menu_item_id: null,
    metric_id: null,
    metric_group_id: null,
  });
  const getMetricList = async (id) => {
    setMetricLoading(true);
    const response = await MakeApiCall(
      `metric?field_name=metric_name&sort_by=asc&isAll=1&metric_group_id=${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricList(
        response?.data?.records?.map((d) => ({
          label: `${d?.metric_name} - ${d?.metric_type}`,
          value: d?.id,
        }))
      );
      setMetricLoading(false);
    } else {
      setMetricLoading(false);
      message.warning(response?.message);
    }
  };
  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      setSelectedValue({
        menu_item_id: selectedRow?.menu_item_id,
        metric_id: parseInt(selectedRow?.metricData?.id),
        metric_group_id: parseInt(selectedRow?.metricData?.metric_group_id),
      });
      if (selectedRow?.metricData?.metric_group_id) {
        getMetricList(selectedRow?.metricData?.metric_group_id);
      }
    }
  }, [selectedRow]);

  const getList = async () => {
    setMenuLoading(true);
    const response = await MakeApiCall(
      `menu-item?isAll=0&per-page=100`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMenusList(response?.data?.records);
      setMenuLoading(false);
    } else {
      setMenuLoading(false);
      message.warning(response?.message);
    }
  };
  const getMetricGroupList = async () => {
    setMetricGroupLoading(true);
    const response = await MakeApiCall(
      `metric-group?isAll=1&field_name=group_name&sort_by=asc`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricGroupList(
        response?.data?.records?.map((d) => ({
          label: d?.group_name,
          value: d?.id,
        }))
      );
      setMetricGroupLoading(false);
    } else {
      setMetricGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList();
    getMetricGroupList();
    return () => {};
  }, []);

  const UpdateAction = async (data) => {
    try {
      LoadingModal("Metrics Menu Item Mapping", "Updating...");
      const response = await MakeApiCall(
        `metric-menu-item/${selectedRow?.id}`,
        "put",
        {
          menu_item_id: selectedValue?.menu_item_id,
          metric_id: selectedValue?.metric_id,
        },
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        setTimeout(() => {
          callAPI();
          onClose();
        }, 1500);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };
  const AddUpdate = async (data) => {
    try {
      LoadingModal("Metrics Menu Item Mapping", "Adding...");
      const response = await MakeApiCall(
        `metric-menu-item`,
        "post",
        {
          menu_item_id: selectedValue?.menu_item_id,
          metric_group_id: selectedValue?.metric_group_id,
        },
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        setTimeout(() => {
          callAPI();
          onClose();
        }, 1500);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const handleOk = () => {
    if (!selectedValue?.menu_item_id || !selectedValue?.metric_group_id) {
      message.destroy();
      return message.warning("Please Fill the All Fields");
    }
    message.destroy();

    if (modalType === "Add") {
      AddUpdate(inputValue);
    } else {
      UpdateAction(inputValue);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={`${modalType} Metric Menu Item Groups`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="mb-5 d-grid">
        <label className="mt-3 mb-1">
          <span>Menu Item</span>
        </label>
        <Select
          placeholder="Enter Menu Item"
          value={selectedValue?.menu_item_id}
          onChange={(e) => {
            setSelectedValue({
              ...selectedValue,
              menu_item_id: e,
            });
          }}
          options={menusList?.map((d) => {
            if (d?.children?.length !== 0) {
              return {
                label: d?.title,
                title: d?.title,
                options: d?.url
                  ? [
                      {
                        label: d?.title,
                        value: d?.id,
                      },
                      ...d?.children?.map((r) => ({
                        label: r?.title,
                        value: r?.id,
                      })),
                    ]
                  : d?.children?.map((r) => ({
                      label: r?.title,
                      value: r?.id,
                    })),
              };
            }
            return {
              label: d?.title,
              value: d?.id,
            };
          })}
          loading={menuLoading}
        />
      </div>
      <div className="mb-5 d-grid">
        <label className="mt-3 mb-1">
          <span>Metric Group</span>
        </label>
        <Select
          options={metricGroupList}
          loading={metricGroupLoading}
          value={selectedValue?.metric_group_id}
          onChange={(e) => {
            setSelectedValue({
              ...selectedValue,
              metric_group_id: e,
            });
            setMetricLoading(true);
            getMetricList(e);
          }}
          placeholder="Enter Metric Group"
        />
      </div>
      {/* <div className="mb-5 d-grid">
        <label className="mt-3 mb-1">
          <span>Metric</span>
        </label>

        <div
          style={{
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          <Checkbox.Group
            onChange={(e) => {
              setSelectedValue({
                ...selectedValue,
                metric_id: e.length > 8 ? selectedValue?.metric_id : e,
              });
            }}
            value={selectedValue?.metric_id}
          >
            <Row gutter={[0, 8]}>
              {metricList?.map((item) => (
                <Col span={24} key={item.value}>
                  <Checkbox
                    value={item.value}
                    disabled={
                      selectedValue?.metric_id?.length >= 8 &&
                      !selectedValue?.metric_id?.includes(item.value)
                    }
                  >
                    {item.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
        <Select
          loading={metricLoading}
          options={metricList}
          placeholder="Enter Metric"
          value={selectedValue?.metric_id}
          onChange={(e) => {
            setSelectedValue({
              ...selectedValue,
              metric_id: e,
            });
          }}
        />
      </div> */}
    </Modal>
  );
};

export default AddData;
