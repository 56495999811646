import {
  Button,
  Checkbox,
  message,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { TableWrapper } from "../business/style";
import { NumberWithCommas, RenderTable } from "../../../config";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { LoadingModal } from "../../../components/upload-modals";

const LineItems = (props) => {
  const {
    filters,
    onClose,
    filterListLoading,
    filterList,
    selectedFilterTab,
    setSelectedFilterTab,
    AdsPremiumContent,
    objTypes,
  } = props;
  const { week_number, start_date, end_date } = filters;
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableLabel, setTableLabel] = useState({});
  const [metricName, setMetricName] = useState(null);

  const listFilters = ["Targeting", "Medium", "Destination", "Placement"];
  const options = filterList?.[selectedFilterTab?.ad_product];

  const getLineItems = async (data) => {
    setTableLoading(true);
    setTableLabel({});
    setTableData([]);
    setMetricName(null);
    const response = await MakeApiCall(
      `ads/premium-line-items?start_date=${data?.start_date || ""}&end_date=${
        data?.end_date || ""
      }&metric_name=${data?.metric_name || ""}&ad_product=${
        data?.ad_product || ""
      }&sip_targeting_type=${data?.sip_targeting_type || ""}&sip_medium=${
        data?.sip_medium || ""
      }&sip_destination=${data?.sip_destination || ""}&sip_placement=${
        data?.sip_placement || ""
      }&metric_value=${data?.metric_value || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableData(response?.data?.line_items || []);
      setTableLabel(response?.data?.line_items_label || {});
      setMetricName(response?.data?.metric_name || "");
      setTableLoading(false);
    } else {
      setMetricName(null);
      setTableLoading(false);
      setTableLabel({});
      setTableData([]);
      message.warning(response?.message);
    }
  };
  function getWeekNumberFromString(weekString) {
    const match = weekString.match(/\d+/); // Extract the first number from the string
    return match ? parseInt(match[0], 10) : null; // Convert to number if found, otherwise return null
  }

  useEffect(() => {
    getLineItems(filters);
    return () => {};
  }, []);

  const getFilterValue = (
    key,
    confirm,
    clearFilters,

    setSelectedKeys,
    selectedKeys,
    defaultFilter
  ) => {
    const options_ = options?.[tableLabel?.[key]]?.map((d) => ({
      text: d?.mapped_ad_type,
      value: d?.mapped_ad_type,
    }));

    return (
      <div style={{ padding: 8 }}>
        <Radio.Group
          value={selectedKeys[0] || defaultFilter?.[0] || ""}
          className="px-3 d-grid mt-3"
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);

            setSelectedFilterTab({
              ...selectedFilterTab,
              [key]: e.target.value,
            });

            getLineItems({
              ...filters,
              ...selectedFilterTab,
              [key]: e.target.value,
            });
            AdsPremiumContent({
              ...selectedFilterTab,
              [key]: e.target.value,
            });

            confirm();
          }}
        >
          {options_.map((status, i) => (
            <Radio key={i} value={status?.value} style={{ marginBottom: 8 }}>
              {status?.text}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  };
  const getFilterValueLocal = (
    key,
    confirm,
    clearFilters,

    setSelectedKeys,
    selectedKeys,
    filterValues
  ) => {
    return (
      <div style={{ padding: 8, minWidth: 200 }}>
        <Checkbox.Group
          options={filterValues}
          value={selectedKeys}
          onChange={(checkedValues) => {
            setSelectedKeys(checkedValues);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            style={{ width: "45%" }}
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ width: "45%" }}
            onClick={() => {
              confirm();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  const filterIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#696974"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const filterIconSelected = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#00000000"
        fill="#1f97d3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const columns = Object.keys(tableLabel || {}).map((key) => {
    const filterValues = [
      ...new Set(
        tableData
          ?.map((item) => item[key])
          .filter((value) => value !== null && value !== undefined)
      ),
    ];

    const defaultFilter = options?.[tableLabel?.[key]]
      ?.filter((d) => filterValues?.includes(d?.mapped_ad_type))
      ?.map((d) => d?.mapped_ad_type);

    const baseColumn = {
      title: tableLabel?.[key] || key,
      dataIndex: key,
      key: key,
      ...(listFilters?.includes(tableLabel?.[key])
        ? {
            filterMode: "menu",
            filterSearch: true,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) =>
              getFilterValue(
                key,
                confirm,
                clearFilters,
                setSelectedKeys,
                selectedKeys,
                defaultFilter
              ),
            filterIcon: (filtered) =>
              filtered ? filterIconSelected : filterIcon,

            onFilter: (value, record) =>
              record[key] === value ||
              String(record[key])
                .toLowerCase()
                .includes(String(value).toLowerCase()),
          }
        : {
            filterMode: "tree",
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) =>
              getFilterValueLocal(
                key,
                confirm,
                clearFilters,
                setSelectedKeys,
                selectedKeys,
                filterValues
              ),
            filterIcon: (filtered) =>
              filtered ? filterIconSelected : filterIcon,
            onFilter: (value, record) =>
              record[key] === value ||
              String(record[key])
                .toLowerCase()
                .includes(String(value).toLowerCase()),
          }),

      sorter: (a, b) =>
        typeof a?.[key] === "number"
          ? a?.[key] - b?.[key]
          : String(a?.[key])?.localeCompare(b?.[key]),

      render: (value) =>
        typeof value === "number"
          ? NumberWithCommas(Number(value || 0).toFixed(2))
          : value,
    };

    return baseColumn;
  });

  console.log(metricName, filters, "metricName");

  const downloadFileLineItems = async () => {
    try {
      message.destroy();
      LoadingModal("Line Items", "Exporting...");
      const response = await MakeApiCall(
        `download-manager/export`,
        "post",
        {
          source_type: "Ads 360",
          record_type: metricName,
          start_date: filters?.start_date,
          end_date: filters?.end_date,
          param_filter: {
            start_date: filters?.start_date,
            end_date: filters?.start_date,
            metric_name: filters?.metric_name,
            ad_product: filters?.ad_product,
            sip_destination: filters?.sip_destination,
            sip_medium: filters?.sip_medium,
            sip_placement: filters?.sip_placement,
            sip_targeting_type: filters?.sip_targeting_type,
          },
        },
        true
      );

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        history.push("/download-manager");
        console.log(response, "response");
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  return (
    <TableWrapper
      id="kt_content_container"
      className="container-fluid custom-ui mt-5"
    >
      <div className="card">
        <div
          className="card-header"
          style={{
            background: "white",
            zIndex: 9,
            top: "0px",
            position: "sticky",
          }}
        >
          <div className="card-title" style={{ textTransform: "capitalize" }}>
            <div className="me-3">
              <span
                className="svg-icon svg-icon-muted svg-icon-2hx"
                style={{ cursor: "pointer" }}
                onClick={() => onClose()}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                  />
                  <path
                    d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            {metricName || ""}
            {/* <Button type="primary" onClick={() => {}} className="ms-4">
              Reset Filters
            </Button> */}
          </div>
          <div className="card-toolbar">
            <Tag
              color="blue"
              style={{ fontSize: "14px", fontWeight: 400, padding: "4px 6px" }}
              bordered={false}
            >
              Wk. {getWeekNumberFromString(week_number)}{" "}
              {dayjs(start_date, "YYYY-MM-DD").format("MM/DD/YYYY")} To{" "}
              {dayjs(end_date, "YYYY-MM-DD").format("MM/DD/YYYY")}
            </Tag>
            <div
              style={{
                background: "#00B660",
                borderRadius: 6,
                padding: "6px 15px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "#FAFAFB",
                  fontSize: 13,

                  fontWeight: 500,
                  letterSpacing: 0.1,
                  wordWrap: "break-word",
                }}
                onClick={() => downloadFileLineItems()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  className="me-3"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                    fill="white"
                  />
                </svg>
                Export
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none px-10 mb-5">
          <div className="col-12">
            <div className="d-flex gap-3">
              {["Targeting", "Medium", "Destination", "Placement"]?.map(
                (d, i) => {
                  return (
                    <Select
                      loading={filterListLoading}
                      className="w-200px"
                      options={filterList?.[selectedFilterTab?.ad_product]?.[
                        d
                      ]?.map((r) => ({
                        label: r?.mapped_ad_type,
                        value: r?.mapped_ad_type,
                      }))}
                      onChange={(e) => {
                        setSelectedFilterTab({
                          ...selectedFilterTab,
                          [objTypes?.[d]]: e,
                        });

                        getLineItems({
                          ...filters,
                          ...selectedFilterTab,
                          [objTypes?.[d]]: e,
                        });
                        AdsPremiumContent({
                          ...selectedFilterTab,
                          [objTypes?.[d]]: e,
                        });
                      }}
                      allowClear
                      value={selectedFilterTab?.[objTypes?.[d]]}
                      size="middle"
                      placeholder={d}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          <Table
            dataSource={tableData}
            loading={tableLoading}
            rowKey={"key"}
            scroll={{ x: "max-content" }}
            columns={columns?.map((d) => {
              if (tableData?.length === 0) {
                return {
                  ...d,
                  className: "minWidth-table",
                  width: d?.title?.length + 22 * 8,
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d, 10),
                };
              }
              return {
                ...d,
                className: "minWidth-table",
                render: (props, row, index) =>
                  RenderTable(props, row, index, d, 2),
              };
            })}
            rowClassName={(_, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            pagination={false}
            fixed={true}
            sticky={{
              offsetHeader: "68px",
            }}
          />
        </div>
      </div>
    </TableWrapper>
  );
};

export default LineItems;
