import { Popover, Table, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import { TableLoading } from "../../../components/table-animation";
import ArgoTooltip from "../../../components/tooltip";
import { Wrapper } from "./style";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { RenderTable } from "../../../config";

const SystemLog = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const [sortFilters, setSortFilters] = useState({
    field_name: "",
    sort_by: "",
  });

  const getList = async (data) => {
    const response = await MakeApiCall(
      `system-event-logs?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records);
      setPageSize(response?.data?.pagination?.page_size);
      setTotalPage(response?.data?.pagination?.totalCount);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });

  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.key : "",
      sort_by: sortType?.[sorter?.order] || "",
    };

    setSortFilters(obj);
    getList({ ...obj });
  };

  useEffect(() => {
    getList({ ...sortFilters });
    return () => {};
  }, [page]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                handleReset(clearFilters);
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const columns = [
    {
      title: "#",
      align: "center",

      render: (_, __, i) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {(page - 1) * pageSize + 1 + i}
          </div>
        );
      },
    },
    {
      title: "Date & Time Log Created (ET)",
      width: 250,
      align: "left",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      render: (d) => {
        return (
          <span>
            {moment(new Date(d * 1000))
              .tz("America/New_York")
              .format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      width: 220,
      align: "left",
      dataIndex: "event_name",
      ...getColumnSearchProps("event_name"),
      key: "event_name",
      render: (d) => {
        return <span>{d || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      width: 150,
      align: "left",
      dataIndex: "event_type",
      key: "event_type",
      ...getColumnSearchProps("event_type"),
      render: (d) => {
        return <span>{d || "-"}</span>;
      },
    },
    {
      title: "Event Message",
      width: 180,
      align: "left",
      dataIndex: "error_message",
      key: "error_message",
      ...getColumnSearchProps("error_message"),
      ellipsis: false,
      render: (d) => {
        return (
          <ArgoTooltip rule row={3} title={d}>
            {d}
          </ArgoTooltip>
        );
      },
    },
    {
      title: "Event Data",
      width: 150,
      align: "left",
      ellipsis: false,
      dataIndex: "error_data",
      key: "error_data",
      ...getColumnSearchProps("error_data"),
      render: (d) => {
        if (!d) {
          return "-";
        }

        return (
          <Popover
            placement="left"
            title=""
            getPopupContainer={() =>
              document.getElementById("kt_content_container").parentNode
            }
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d}</div>
          </Popover>
        );
      },
    },
    {
      title: "Seller Name",
      width: 220,
      align: "left",
      dataIndex: "seller_name",
      key: "seller_name",
      ...getColumnSearchProps("seller_name"),
      render: (d) => {
        return <span>{d || "-"}</span>;
      },
    },
    {
      title: "Email",
      width: 220,
      align: "left",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      render: (d) => {
        return <span>{d || "-"}</span>;
      },
    },
  ];

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid pt-5"
    >
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <div className="card-title">Application Log</div>
                <div className="card-toolbar"></div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body scroll-show pt-0 pb-0">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : (
                  <div>
                    <Table
                      columns={columns?.map((d) => ({
                        ...d,
                        className:
                          d?.title === "#"
                            ? "maxWidth-table"
                            : "minWidth-table",
                        ...PropsFilter(d?.key),
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      dataSource={list}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      rowKey="id"
                      onChange={handleChange}
                      loading={loading}
                      pagination={false}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                      scroll={{ y: "calc(100vh - 410px)", x: "max-content" }}
                    />
                  </div>
                )}
              </div>
              <div className="card-footer pt-0 pb-5">
                <Pagination
                  // loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize(e);
                    setPage(1);
                    getList({
                      page: 1,
                      pageSize: e,
                      ...sortFilters,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage(e);
                    getList({
                      page: e,
                      ...sortFilters,
                    });
                  }}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
};

export default SystemLog;
