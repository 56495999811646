import {
  Button,
  Checkbox,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Radio,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { TableWrapper } from "../business/style";
import { NumberWithCommas, RenderTable } from "../../../config";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import DatePickerView from "../data-sources/lib/date-picker-view";
import { Wrapper } from "../data-sources/style";
import { LoadingModal } from "../../../components/upload-modals";

const getUniqueFilters = (data, key) => {
  const uniqueValues = [
    ...new Set(data.map((item) => item[key]).filter(Boolean)),
  ];

  return uniqueValues.map((value) => ({
    // label: (
    // <span
    //   style={{
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //     width: "200px",
    //     display: "inline-block",
    //     position: "relative",
    //     top: "6px",
    //   }}
    // >
    //   {value}
    // </span>
    // ),
    text: value,
    value,
  }));
};

const KeywordLineItems = (props) => {
  const {
    onClose,
    filterLineItems,
    apiCallSearchView = null,
    setFilterLineItems,
  } = props;
  const history = useHistory();
  const [lineItemsData, setLineItemsData] = useState([]);
  const [lineItemsLoading, setLineItemsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const getLineItems = async (data) => {
    setLineItemsData([]);
    setLineItemsLoading(true);
    try {
      const response = await MakeApiCall(
        `${apiCallSearchView || "ads/keyword-list"}?source_type=${
          data?.source_type || ""
        }&reporting_type=${data?.reporting_type || ""}&month_no=${
          data?.month_no || ""
        }&week_no=${data?.week_no || ""}&report_year=${
          data?.report_year || ""
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setLineItemsLoading(false);
        setLineItemsData(response?.data || []);
      } else {
        setLineItemsData([]);
        setLineItemsLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLineItemsLoading(false);
      setLineItemsData([]);
    }
  };

  const filterableColumns = [
    "keyword",
    "search_term",
    "campaign",
    "match_type",
    "impressions",
    "clicks",
    "click_through_rate",
    "keyword_bid",
    "sales",
    "purchases14d",
    "conversion_rate",
    "cost_per_click",
    "ad_spend",
    "acos",
    "roas",
    "cpa",
    "sip_targeting_type",
    "sip_placement",
    "sip_medium",
    "sip_destination",
  ];

  // Generate filter options for each column dynamically
  const columnFilters = useMemo(() => {
    return filterableColumns.reduce((acc, key) => {
      acc[key] = getUniqueFilters(lineItemsData, key);
      return acc;
    }, {});
  }, [lineItemsData]);

  const dynamicOnFilter = (key) => (value, record) => {
    console.log(value, record, key, "test");

    return Array.isArray(value)
      ? value?.some((selected) =>
          typeof value === "string"
            ? record?.[key]?.includes(selected)
            : record?.[key]?.toString()?.includes(value?.toString())
        )
      : record?.[key]?.toString()?.includes(value?.toString());
  };

  const filterDropdownFilter =
    (key) =>
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const handleSelect = (value) => {
        const newSelectedKeys = selectedKeys.includes(value)
          ? selectedKeys.filter((key) => key !== value)
          : [...selectedKeys, value];
        setSelectedKeys(newSelectedKeys);
      };

      const opsList = getUniqueFilters(lineItemsData, key)
        ?.filter((r) => r?.text)
        .filter((option) =>
          typeof option?.text === "string"
            ? option?.text
                ?.toString()
                ?.toLowerCase()
                ?.includes((searchText || "").toLowerCase())
            : option?.text
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchText || "")
        );
      return (
        <div style={{ padding: 8, width: 300 }}>
          <Input
            placeholder={`Search ${key?.split("_")?.join(" ")}...`}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={() => confirm()}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
              padding: 4,
              borderRadius: 4,
              border: "1px solid #d9d9d9",
            }}
          />
          <div style={{ maxHeight: 200, overflowY: "auto" }}>
            <div
              // key={option.value}
              style={{
                padding: "6px 12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                onChange={() => {
                  console.log(opsList, "opsListopsList");
                  //   const newSelectedKeys = selectedKeys.includes(value)
                  //   ? selectedKeys.filter((key) => key !== value)
                  //   : [...selectedKeys, value];
                  setSelectedKeys(
                    opsList?.length === selectedKeys?.length
                      ? []
                      : opsList?.map((d) => d?.text)
                  );
                  // confirm();
                }}
                checked={opsList?.length === selectedKeys?.length}
                // onChange={() => handleSelect(option.value)}
              >
                <b
                  style={{
                    position: "relative",
                    top: "1px",
                  }}
                >
                  {opsList?.length === selectedKeys?.length
                    ? "Deselect All"
                    : "Select All"}
                </b>
              </Checkbox>
            </div>
            {opsList?.map((option) => (
              <div
                key={option.value}
                style={{
                  padding: "6px 12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selectedKeys.includes(option.value)}
                  onChange={() => handleSelect(option.value)}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "220px",
                      display: "inline-block",
                      position: "relative",
                      top: "3px",
                    }}
                  >
                    {option.text}
                  </span>
                </Checkbox>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            {/* <Button
              color="default"
              variant="filled"
              onClick={() => {
                clearFilters();
                setSearchText("");
              }}
              style={{ padding: "4px 8px" }}
            >
              Reset
            </Button> */}
            <Button
              color="primary"
              variant="solid"
              size="small"
              onClick={() => {
                confirm();
                setSearchText("");
              }}
              style={{ padding: "4px 8px", width: "90px" }}
            >
              Apply
            </Button>
          </div>
        </div>
      );
    };

  useEffect(() => {
    setFilterLineItems(filterLineItems);
    getLineItems(filterLineItems);
    return () => {};
  }, []);

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      sorter: (a, b) => a?.keyword?.localeCompare(b?.keyword),
      filters: columnFilters["keyword"],
      onFilter: dynamicOnFilter("keyword"),
      filterDropdown: filterDropdownFilter("keyword"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterSearch: true,
    },
    {
      title: "Search Term",
      dataIndex: "search_term",
      key: "search_term",
      sorter: (a, b) => a?.search_term?.localeCompare(b?.search_term),
      width: 350,
      filters: columnFilters["search_term"],
      onFilter: dynamicOnFilter("search_term"),
      filterSearch: true,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("search_term"),
    },
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      sorter: (a, b) => a?.campaign?.localeCompare(b?.campaign),
      filters: columnFilters["campaign"],
      onFilter: dynamicOnFilter("campaign"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      render: (e) => {
        return (
          <Popover
            content={
              <>
                {e?.split(",")?.map((d) => (
                  <div>{d}</div>
                ))}
              </>
            }
            trigger={["click"]}
          >
            <div className="one-line-ellipsis">{e || ""}</div>
          </Popover>
        );
      },
      filterDropdown: filterDropdownFilter("campaign"),
    },
    {
      title: "Type",
      dataIndex: "match_type",
      key: "match_type",
      filters: columnFilters["match_type"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("match_type"),
      filterDropdown: filterDropdownFilter("match_type"),
      sorter: (a, b) => a?.match_type?.localeCompare(b?.match_type),
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      filters: columnFilters["impressions"],
      onFilter: dynamicOnFilter("impressions"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("impressions"),
      sorter: (a, b) => (a?.impressions || 0) - (b?.impressions || 0),
      render: (e) => {
        return NumberWithCommas(e || 0);
      },
    },
    {
      title: "Click",
      dataIndex: "clicks",
      filters: columnFilters["clicks"],
      onFilter: dynamicOnFilter("clicks"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("clicks"),
      key: "clicks",
      sorter: (a, b) => (a?.clicks || 0) - (b?.clicks || 0),
      render: (e) => {
        return NumberWithCommas(e || 0);
      },
    },
    {
      title: "CTR",
      dataIndex: "click_through_rate",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      key: "click_through_rate",
      filters: columnFilters["click_through_rate"],
      onFilter: dynamicOnFilter("click_through_rate"),
      filterDropdown: filterDropdownFilter("click_through_rate"),
      sorter: (a, b) =>
        (a?.click_through_rate || 0) - (b?.click_through_rate || 0),
      render: (text) => `${NumberWithCommas(text || 0)}%`,
    },
    {
      title: "Avg Bid",
      dataIndex: "keyword_bid",
      key: "keyword_bid",
      filters: columnFilters["keyword_bid"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("keyword_bid"),
      onFilter: dynamicOnFilter("keyword_bid"),
      sorter: (a, b) => (a?.keyword_bid || 0) - (b?.keyword_bid || 0),
      render: (text) => `${NumberWithCommas(text || 0)}`,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      filters: columnFilters["sales"],
      filterDropdown: filterDropdownFilter("sales"),
      onFilter: dynamicOnFilter("sales"),
      sorter: (a, b) => (a?.sales || 0) - (b?.sales || 0),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      render: (text) => `$${NumberWithCommas(text || 0)}`,
    },
    {
      title: "Purchases",
      dataIndex: "purchases14d",
      key: "purchases14d",
      filters: columnFilters["purchases14d"],
      filterDropdown: filterDropdownFilter("purchases14d"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("purchases14d"),
      sorter: (a, b) => (a?.purchases14d || 0) - (b?.purchases14d || 0),
    },
    {
      title: "Avg CR",
      dataIndex: "conversion_rate",
      key: "conversion_rate",
      filters: columnFilters["conversion_rate"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("conversion_rate"),
      onFilter: dynamicOnFilter("conversion_rate"),
      sorter: (a, b) => (a?.conversion_rate || 0) - (b?.conversion_rate || 0),
      render: (text) => `${NumberWithCommas(text || 0)}%`,
    },
    {
      title: "Avg CPC",
      dataIndex: "cost_per_click",
      key: "cost_per_click",
      filterDropdown: filterDropdownFilter("cost_per_click"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filters: columnFilters["cost_per_click"],
      onFilter: dynamicOnFilter("cost_per_click"),
      sorter: (a, b) => (a?.cost_per_click || 0) - (b?.cost_per_click || 0),
      render: (text) => `$${NumberWithCommas(text || 0)}`,
    },
    {
      title: "Ad Spend",
      dataIndex: "ad_spend",
      key: "ad_spend",
      filters: columnFilters["ad_spend"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("ad_spend"),
      onFilter: dynamicOnFilter("ad_spend"),
      sorter: (a, b) => (a?.ad_spend || 0) - (b?.ad_spend || 0),
      render: (text) => `$${NumberWithCommas(text || 0)}`,
    },
    {
      title: "ACOS",
      dataIndex: "acos",
      key: "acos",
      filters: columnFilters["acos"],
      onFilter: dynamicOnFilter("acos"),
      filterDropdown: filterDropdownFilter("acos"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      sorter: (a, b) => (a?.acos || 0) - (b?.acos || 0),
      render: (text) => `${NumberWithCommas(text || 0)}%`,
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      key: "roas",
      filters: columnFilters["roas"],
      filterDropdown: filterDropdownFilter("roas"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("roas"),
      sorter: (a, b) => (a?.roas || 0) - (b?.roas || 0),
      render: (text) => `${NumberWithCommas(text || 0)}`,
    },
    {
      title: "Avg CPA",
      dataIndex: "cpa",
      key: "cpa",
      filters: columnFilters["cpa"],
      onFilter: dynamicOnFilter("cpa"),
      filterDropdown: filterDropdownFilter("cpa"),
      sorter: (a, b) => (a?.cpa || 0) - (b?.cpa || 0),
      render: (text) => `$${NumberWithCommas(text || 0)}`,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
    },
    {
      title: "SIP Target",
      dataIndex: "sip_targeting_type",
      key: "sip_targeting_type",
      filters: columnFilters["sip_targeting_type"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filterDropdown: filterDropdownFilter("sip_targeting_type"),
      onFilter: dynamicOnFilter("sip_targeting_type"),
      sorter: (a, b) =>
        a?.sip_targeting_type?.localeCompare(b?.sip_targeting_type),
    },
    {
      title: "SIP Placement",
      dataIndex: "sip_placement",
      key: "sip_placement",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      filters: columnFilters["sip_placement"],
      filterDropdown: filterDropdownFilter("sip_placement"),
      onFilter: dynamicOnFilter("sip_placement"),
      sorter: (a, b) => a?.sip_placement?.localeCompare(b?.sip_placement),
    },
    {
      title: "SIP Medium",
      dataIndex: "sip_medium",
      key: "sip_medium",
      filters: columnFilters["sip_medium"],
      filterDropdown: filterDropdownFilter("sip_medium"),
      onFilter: dynamicOnFilter("sip_medium"),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      sorter: (a, b) => a?.sip_medium?.localeCompare(b?.sip_medium),
    },
    {
      title: "SIP Destination",
      dataIndex: "sip_destination",
      key: "sip_destination",
      filterDropdown: filterDropdownFilter("sip_destination"),
      filters: columnFilters["sip_destination"],
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setSearchText("");
        }
      },
      onFilter: dynamicOnFilter("sip_destination"),
      sorter: (a, b) => a?.sip_destination?.localeCompare(b?.sip_destination),
    },
  ];

  const downloadFileLineItems = async () => {
    try {
      message.destroy();
      LoadingModal("Line Items", "Exporting...");
      const response = await MakeApiCall(
        `download-manager/export`,
        "post",
        {
          source_type: filterLineItems?.source_type,
          source_type: "Ads 360",
          record_type: "Keyword Tracking",
          start_date: filterLineItems?.start_date,
          end_date: filterLineItems?.start_date,
          param_filter: {
            source_type: filterLineItems?.source_type,
            month_no: filterLineItems?.month_no,
            week_no: filterLineItems?.week_no,
            report_year: filterLineItems?.report_year,
            reporting_type: filterLineItems?.reporting_type,
          },
        },
        true
      );
      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        history.push("/download-manager");
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const getDays = (start_date, end_date) => {
    const date1 = new Date(start_date);
    const date2 = new Date(end_date);

    const timeDifference = date1 - date2; // Difference in milliseconds
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert to days

    return dayDifference;
  };

  return (
    <TableWrapper
      id="kt_content_container"
      className="container-fluid custom-ui mt-5"
    >
      <div className="card">
        <div
          className="card-header"
          style={{
            background: "white",
            zIndex: 9,
            top: "0px",
            position: "sticky",
          }}
        >
          <div className="card-title" style={{ textTransform: "capitalize" }}>
            <div className="me-3">
              <span
                className="svg-icon svg-icon-muted svg-icon-2hx"
                style={{ cursor: "pointer" }}
                onClick={() => onClose()}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                  />
                  <path
                    d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            Keyword Tracking
          </div>
          <div className="card-toolbar">
            <Wrapper
              style={{ width: "auto", display: "flex", alignItems: "center" }}
            >
              <Tag color="blue-inverse">{filterLineItems?.reporting_type}</Tag>
              <DatePickerView
                // allowClear
                onChange={(e) => {
                  const reporting_type =
                    getDays(
                      dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                      dayjs(e?.start_date)?.format("YYYY-MM-DD")
                    ) === 0 ||
                    isNaN(
                      getDays(
                        dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                        dayjs(e?.start_date)?.format("YYYY-MM-DD")
                      )
                    )
                      ? null
                      : getDays(
                          dayjs(e?.end_date)?.format("YYYY-MM-DD"),
                          dayjs(e?.start_date)?.format("YYYY-MM-DD")
                        ) > 15
                      ? "Monthly"
                      : "Weekly";

                  const obj = {
                    ...filterLineItems,

                    start_date: e?.start_date
                      ? dayjs(e?.start_date)?.format("YYYY-MM-DD")
                      : null,
                    end_date: e?.end_date
                      ? dayjs(e?.end_date)?.format("YYYY-MM-DD")
                      : null,
                    reporting_type: reporting_type,
                    month_no:
                      reporting_type === "Monthly"
                        ? dayjs(e?.start_date)?.format("M")
                        : null,
                    report_year: dayjs(e?.start_date)?.format("YYYY"),
                    week_no:
                      reporting_type === "Weekly"
                        ? dayjs(e?.end_date).week()
                        : null,
                  };

                  getLineItems(obj);
                  setFilterLineItems(obj);
                }}
                value={
                  filterLineItems?.start_date && filterLineItems?.end_date
                    ? [filterLineItems?.start_date, filterLineItems?.end_date]
                    : null
                }
              />
            </Wrapper>
            <div
              style={{
                background: "#00B660",
                borderRadius: 6,
                padding: "6px 15px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  color: "#FAFAFB",
                  fontSize: 13,

                  fontWeight: 500,
                  letterSpacing: 0.1,
                  wordWrap: "break-word",
                }}
                onClick={() => downloadFileLineItems()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  className="me-3"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                    fill="white"
                  />
                </svg>
                Export
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-0 pb-0">
          <Table
            dataSource={lineItemsData}
            rowKey={"id"}
            loading={lineItemsLoading}
            scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
            columns={columns?.map((d) => {
              return {
                ...d,
                className: "minWidth-table",
                render: (props, row, index) =>
                  RenderTable(props, row, index, d, 2),
              };
            })}
            rowClassName={(_, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
            }}
            fixed={true}
            sticky={{
              offsetHeader: "68px",
            }}
          />
        </div>
      </div>
    </TableWrapper>
  );
};

export default KeywordLineItems;
