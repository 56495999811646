import React from "react";
import Icons from "../../../components/icons";
import { Button, Checkbox, Collapse, Popconfirm, Steps, message } from "antd";
import InfoVideo from "../../../components/header-icons";

export const PremiumContent = (props) => {
  const { contextValue, PCAUpdate } = props;
  const selectedTab =
    contextValue?.data?.user_?.pca_current_stage === ""
      ? 0
      : contextValue?.data?.user_?.pca_current_stage ===
        "SIP Invite Sent, Pending Acceptance"
      ? 1
      : contextValue?.data?.user_?.pca_current_stage ===
        "SIP Invite Accepted, Pending Permissions"
      ? 2
      : 3;

  const styleSelected = {
    border: "1px solid #00B660",
    backgroundColor: "#FFF",
    color: "#00B660",
    width: 30,
    height: 30,
    borderRadius: "100px",
  };
  const styleNone = {
    border: "1px solid #E7E7E7",
    backgroundColor: "#F7F7F7",
    color: "#B4B4B4",
    width: 30,
    height: 30,
    borderRadius: "100px",
  };
  const selectedCard = {
    border: "1px solid #00B660",
    backgroundColor: "#EEF7EE",
    borderRadius: "8px",
    padding: "10px",
  };
  const selectCard = {
    border: "1px solid #00B660",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    padding: "10px",
  };
  const NoneSelectCard = {
    border: "1px solid #F6F6F6",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    padding: "10px",
  };
  const borderSelected = {
    border: "1px dashed #00B660",
    position: "relative",
    bottom: "-55px",
    height: "20px",
  };
  const borderNotSelected = {
    border: "1px dashed #D4D4D4",
    position: "relative",
    bottom: "-55px",
    height: "20px",
  };

  if (
    contextValue?.data?.user_?.pca_connected === 1 &&
    contextValue?.data?.user_?.pca_close_status !== 1
  ) {
    return (
      <div
        style={{
          background: "#EEF7EE",
          border: "1px solid #E5ECEA",
          display: "flex",
          padding: "15px",
          marginTop: "15px",
          borderRadius: "7px",
          position: "relative",
          alignItems: "center",
        }}
      >
        <Icons type="connected-pic" />
        <div className="ms-10">
          <div className="mt-5 mb-3 w-300px">
            <Icons type="PCA_Diamonds" />{" "}
            <span
              style={{
                fontWeight: "bold",
                position: "relative",
                top: "7px",
                fontSize: "21px",
                marginLeft: "10px",
              }}
            >
              Premium Content
            </span>
          </div>
          <button className="btn btn-success btn-sm d-flex align-items-center">
            <Icons type="info-PCA" />
            <span className="ms-3">Learn More</span>
          </button>
        </div>
        <p className="fs-6 " style={{ lineHeight: "1.7" }}>
          <b>Welcome to SIP's Premium Content.</b>
          You've unlocked a treasure trove of invaluable insights, strategies.
          and in-depth analysis curated to supercharge your Amazon journey. Dive
          into our Premium Content and elevate your selling game.
        </p>
        <div
          style={{
            position: "absolute",
            top: "6px",
            right: "11px",
            fontSize: "22px",
            cursor: "pointer",
          }}
          onClick={() => {
            const obj = {
              pca_close_status: 1,
            };
            message.destroy();

            PCAUpdate(obj);
          }}
        >
          <Icons type="close-pca" />
        </div>
      </div>
    );
  }
  if (
    contextValue?.data?.user_?.pca_connected === 1 &&
    contextValue?.data?.user_?.pca_close_status === 1
  ) {
    return (
      <div
        style={{
          background: "#EEF7EE",
          border: "1px solid #E5ECEA",
          display: "flex",
          padding: "15px",
          marginTop: "15px",
          borderRadius: "7px",
          position: "relative",
          alignItems: "center",
        }}
      >
        <div className="">
          <div className="">
            <Icons type="PCA_Diamonds" />{" "}
            <span
              style={{
                fontWeight: "bold",
                position: "relative",
                top: "7px",
                fontSize: "21px",
                marginLeft: "10px",
              }}
            >
              Premium Content
            </span>
          </div>
        </div>
      </div>
    );
  }
  if ("new_ui" === "new_ui") {
    return (
      <>
        <div className="mt-5 mb-3">
          <Icons type="PCA_Diamonds" />{" "}
          <span
            style={{
              position: "relative",
              top: "7px",
              fontWeight: 700,
              fontSize: "24px",
              color: "#000",
              fontFamily: "Montserrat",
              marginLeft: "10px",
            }}
          >
            Premium Content Access (PCA)
          </span>
        </div>
        <div
          className="card mb-5"
          style={{
            backgroundColor: "#F6FBFF",
            border: "1px solid #9AD0EF",
            boxShadow: "none",
            padding: "15px 15px 5px",
          }}
        >
          <div className="card-body p-0">
            <div
              className={`row  ${
                contextValue?.data?.user_?.pca_close_status === 1
                  ? ""
                  : "d-none"
              }`}
            >
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <h4 className="mb-0 me-3">Premium Content</h4>
                  <Icons type="crown" />
                </div>
              </div>
            </div>
            <div
              className={`row ${
                contextValue?.data?.user_?.pca_close_status !== 1
                  ? ""
                  : "d-none"
              }`}
            >
              <div
                className={`${
                  contextValue?.data?.user_?.pca_connected !== 1
                    ? "col-xxl-6 col-md-12"
                    : "col-12"
                } mb-md-3 d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${
                    contextValue?.data?.user_?.pca_connected === 1
                      ? "min-w-200px d-grid justify-items-center"
                      : ""
                  }`}
                >
                  {contextValue?.data?.user_?.pca_connected === 1 && (
                    <div className="d-flex align-items-center justify-content-evenly">
                      <h4 className="mb-0 me-3">Premium Content</h4>
                      <Icons type="crown" />
                    </div>
                  )}
                  {contextValue?.data?.user_?.pca_connected === 1 && (
                    <img
                      src={`assets/media/Image 1.png`}
                      style={{
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
                <div className="">
                  {contextValue?.data?.user_?.pca_connected === 1 ? (
                    <div>
                      <p className="fs-6 " style={{ lineHeight: "1.7" }}>
                        <b>Welcome to SIP's Premium Content.</b>
                        You've unlocked a treasure trove of invaluable insights,
                        strategies. and in-depth analysis curated to supercharge
                        your Amazon journey. Dive into our Premium Content and
                        elevate your selling game.
                      </p>
                      <Button
                        onClick={() =>
                          window.open(
                            "/User_Permissions_for_Amazon_Seller_Central_Access.pdf"
                          )
                        }
                        type="primary"
                      >
                        Learn more
                      </Button>
                    </div>
                  ) : (
                    <div className="d-grid">
                      {/* <span
                        className="mb-1  lh-base "
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bolder",
                          color: "#525252",
                        }}
                      >
                        Premium Content Access (PCA)
                      </span> */}
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#141414",
                          fontWeight: 700,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Unlock the Power of Exclusive Insights with Premium
                        Content Access
                      </span>
                      <span
                        style={{
                          color: "#272727",
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Elevate your Amazon selling strategy to unprecedented
                        heights with our Premium Content Access (PCA) module.
                      </span>
                      <span
                        style={{
                          color: "#272727",
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Designed exclusively for ambitious sellers focused on
                        maximizing sales and profits, PCA offers you an
                        unparalleled advantage. Dive into a treasure trove of
                        meticulously curated data and historical brand
                        analytics.
                      </span>
                      <div className="d-flex">
                        {/* <div className="">
                          <img
                            src={`assets/media/Image 2.jpeg`}
                            style={{
                              width: "110px",
                              objectFit: "contain",
                            }}
                          />
                        </div> */}
                        <div className="">
                          <ul className="ps-2">
                            <span className="d-flex mt-3 justify-content-start">
                              <Icons type="Search_Query_Performance" />
                              <div className="d-grid ms-2">
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    fontFamily: "Montserrat",
                                    color: "#000000",
                                  }}
                                >
                                  Brand Analytics:
                                </span>{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Montserrat",
                                    color: "#585656",
                                  }}
                                >
                                  Access Search Query Performance, Top Search
                                  Terms and many others to analyze past trends
                                  and forecast future demands.
                                </span>
                              </div>
                            </span>
                            {/* list-pca */}
                            <span className="d-flex mt-3 justify-content-start">
                              <Icons type="list-pca" />
                              <div className="d-grid ms-2">
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    fontFamily: "Montserrat",
                                    color: "#000000",
                                  }}
                                >
                                  Combined Campaign Data with Brand Analytics:
                                </span>{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Montserrat",
                                    color: "#585656",
                                  }}
                                >
                                  Discover the most lucrative keywords driving
                                  traffic and sales and optimize your bids
                                  tailored to boost your listings' visibility.
                                </span>
                              </div>
                            </span>
                            <span className="d-flex mt-3 justify-content-start">
                              <Icons type="search-pca" />
                              <div className="d-grid ms-2">
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    fontFamily: "Montserrat",
                                    color: "#000000",
                                  }}
                                >
                                  And many more:
                                </span>{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Montserrat",
                                    color: "#585656",
                                  }}
                                >
                                  Evaluate your portfolio against market
                                  movements, pinpoint improvement areas and
                                  optimize your offerings.
                                </span>
                              </div>
                            </span>
                          </ul>
                        </div>
                      </div>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                          color: "#585656",
                        }}
                      >
                        PCA goes beyond data access—it turns insights into
                        results. Get actionable recommendations to refine your
                        strategy and enhance your listings for increased
                        profitability.
                      </span>
                      <b
                        style={{
                          color: "#585656",
                          fontSize: "14px",
                          fontWeight: 700,
                          fontFamily: "Montserrat",
                        }}
                        className="mt-4"
                      >
                        Dominate your niche—sign up for PCA now and drive your
                        Amazon success with data-driven decisions!
                      </b>
                    </div>
                  )}

                  {contextValue?.data?.user_?.pca_connected === 1 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "11px",
                        fontSize: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const obj = {
                          pca_close_status: 1,
                        };
                        message.destroy();

                        PCAUpdate(obj);
                      }}
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className="ki-duotone ki-cross-circle"
                      >
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`col-xxl-6 col-md-12 ms-auto ${
                  contextValue?.data?.user_?.pca_connected === 1 ? "d-none" : ""
                }`}
              >
                {/* 
            Permissions Assigned, Pending Verification
            SIP Invite Accepted, Pending Permissions
            
            */}
                <div
                  style={{
                    background: "#FFF",
                    border: "1px solid #E9E9E9",
                    padding: "10px 15px 15px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#141414",
                    }}
                  >
                    Steps
                  </div>
                  <div
                    className="my-2"
                    style={{
                      height: "1px",
                      backgroundColor: "rgb(232 232 232)",
                    }}
                  ></div>
                  <div>
                    <div className="d-grid" style={{ rowGap: "20px" }}>
                      <div
                        className="d-flex position-relative"
                        style={selectedTab === 0 ? selectCard : selectedCard}
                      >
                        <div
                          style={
                            selectedTab > 0 ? borderSelected : borderNotSelected
                          }
                        ></div>
                        <div style={{ position: "relative", top: "7px" }}>
                          {selectedTab === 0 ? (
                            <div
                              style={styleSelected}
                              className="d-flex justify-content-center align-items-center"
                            >
                              1
                            </div>
                          ) : (
                            <Icons type="pca-right" />
                          )}
                        </div>

                        <div className="ms-3 d-flex w-100 justify-content-between">
                          <div className="d-grid">
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#000000",
                                fontWeight: 500,
                              }}
                            >
                              Invite
                            </div>
                            <span
                              style={{ color: "#00000073", fontWeight: 100 }}
                            >
                              Send Sellercentral invite to{" "}
                              <a className="text-dark">
                                sipadmin@argometrix.com
                              </a>
                            </span>
                          </div>
                          <div
                            className="d-grid"
                            style={{ justifyItems: "end" }}
                          >
                            <div>
                              <Checkbox
                                className="PCA-checked-custom"
                                onChange={(e) => {
                                  const obj = {
                                    pca_current_stage:
                                      "SIP Invite Sent, Pending Acceptance", // Seller Central Invite Sent  // Waiting for acceptance // Permissions Assigned
                                    pca_connected: 2,
                                    action_type: "invite",
                                  };
                                  message.destroy();

                                  PCAUpdate(obj);
                                }}
                                disabled={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Sent, Pending Acceptance" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "Permissions Assigned, Pending Verification"
                                }
                                checked={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Sent, Pending Acceptance" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "Permissions Assigned, Pending Verification"
                                }
                              />
                              <span className="ms-2">
                                {selectedTab > 0 ? "" : "Mark "} Complete
                                {selectedTab > 0 ? "d" : ""}
                              </span>
                            </div>
                            <a
                              className=" text-decoration-underline"
                              style={{ color: "#148CC9" }}
                            >
                              Learn how
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        className="d-flex position-relative"
                        style={
                          selectedTab === 1
                            ? selectCard
                            : selectedTab > 1
                            ? selectedCard
                            : NoneSelectCard
                        }
                      >
                        <div
                          style={
                            selectedTab > 1 ? borderSelected : borderNotSelected
                          }
                        ></div>
                        <div style={{ position: "relative", top: "7px" }}>
                          {selectedTab > 1 ? (
                            <Icons type="pca-right" />
                          ) : (
                            <div
                              style={
                                selectedTab === 1 ? styleSelected : styleNone
                              }
                              className="d-flex justify-content-center align-items-center"
                            >
                              2
                            </div>
                          )}
                        </div>

                        <div className="ms-3 d-flex w-100 justify-content-between">
                          <div className="d-grid w-100">
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#000000",
                                fontWeight: 500,
                              }}
                            >
                              Waiting for acceptance
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: "#00000073" }}>
                                Watch for acceptance of your invite. Usually
                                takes up to 12 hours
                              </span>
                              <div>
                                {(contextValue?.data?.user_
                                  ?.pca_current_stage ===
                                  "SIP Invite Accepted, Pending Permissions" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "Permissions Assigned, Pending Verification") && (
                                  <div>
                                    <Checkbox
                                      className="PCA-checked-custom"
                                      disabled
                                      checked={true}
                                    />
                                    <span className="ms-2">
                                      {selectedTab > 1 ? "" : "Mark "} Complete
                                      {selectedTab > 1 ? "d" : ""}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="d-flex position-relative"
                        style={
                          selectedTab === 2
                            ? selectCard
                            : selectedTab > 2
                            ? selectedCard
                            : NoneSelectCard
                        }
                      >
                        <div
                          style={
                            selectedTab > 2 ? borderSelected : borderNotSelected
                          }
                        ></div>
                        <div style={{ position: "relative", top: "7px" }}>
                          {/* <Icons type="pca-right" /> */}
                          {selectedTab > 2 ? (
                            <Icons type="pca-right" />
                          ) : (
                            <div
                              style={
                                selectedTab === 2 ? styleSelected : styleNone
                              }
                              className="d-flex justify-content-center align-items-center"
                            >
                              3
                            </div>
                          )}
                        </div>

                        <div className="ms-3 d-flex w-100 justify-content-between">
                          <div className="d-grid">
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#000000",
                                fontWeight: 500,
                              }}
                            >
                              Permission
                            </div>
                            <span
                              style={{ color: "#00000073", fontWeight: 100 }}
                            >
                              Assign Permissions on Seller Central
                            </span>
                          </div>
                          <div
                            className="d-grid"
                            style={{ justifyItems: "end" }}
                          >
                            <div>
                              <Checkbox
                                className="PCA-checked-custom"
                                onChange={(e) => {
                                  if (
                                    contextValue?.data?.user_
                                      ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions"
                                  ) {
                                    const obj = {
                                      pca_current_stage:
                                        "Permissions Assigned, Pending Verification",
                                      pca_connected: 2,
                                      action_type: "permission",
                                    };
                                    message.destroy();

                                    PCAUpdate(obj);
                                  }
                                }}
                                disabled={
                                  !(
                                    contextValue?.data?.user_
                                      ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions"
                                  )
                                }
                                checked={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                  "Permissions Assigned, Pending Verification"
                                }
                              />
                              <span className="ms-2">
                                {selectedTab > 2 ? "" : "Mark "}Complete
                                {selectedTab > 2 ? "d" : ""}
                              </span>
                            </div>
                            <a
                              className=" text-decoration-underline"
                              style={{ color: "#148CC9" }}
                            >
                              Learn how
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        className="d-flex"
                        style={
                          selectedTab === 3
                            ? selectCard
                            : selectedTab > 3
                            ? selectedCard
                            : NoneSelectCard
                        }
                      >
                        <div style={{ position: "relative", top: "7px" }}>
                          {selectedTab > 3 ? (
                            <Icons type="pca-right" />
                          ) : (
                            <div
                              style={
                                selectedTab === 3 ? styleSelected : styleNone
                              }
                              className="d-flex justify-content-center align-items-center"
                            >
                              4
                            </div>
                          )}
                        </div>

                        <div className="ms-3 d-flex w-100 justify-content-between">
                          <div className="d-grid">
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#000000",
                                fontWeight: 500,
                              }}
                            >
                              Verification
                            </div>
                            <span
                              style={{ color: "#00000073", fontWeight: 100 }}
                            >
                              Permissions Assigned, pending verification.
                              Usually takes up to 12 hours
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-none">
                  <Collapse expandIcon={() => null} defaultActiveKey={["1"]}>
                    <Collapse.Panel
                      style={{
                        // border: "none",
                        backgroundColor: "#FFF",
                        borderRadius: 0,
                      }}
                      header={<h3 className="mb-0">Steps</h3>}
                      key="1"
                    >
                      {/* <Steps
                      direction="vertical"
                      current={selectedTab}
                      items={[
                        {
                          title: "Invite",

                          description: (
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: "#00000073" }}>
                                Send Sellercentral invite to{" "}
                                <a className="text-primary">
                                  sipadmin@argometrix.com
                                </a>
                              </span>
                              <div className="d-flex">
                                <a className="me-3 text-primary text-decoration-underline">
                                  Learn how
                                </a>

                                <div>
                                  <Checkbox
                                    onChange={(e) => {
                                      const obj = {
                                        pca_current_stage:
                                          "SIP Invite Sent, Pending Acceptance", // Seller Central Invite Sent  // Waiting for acceptance // Permissions Assigned
                                        pca_connected: 2,
                                        action_type: "invite",
                                      };
                                      message.destroy();
                                     
                                      PCAUpdate(obj);
                                    }}
                                    disabled={
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "SIP Invite Sent, Pending Acceptance" ||
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "SIP Invite Accepted, Pending Permissions" ||
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "Permissions Assigned, Pending Verification"
                                    }
                                    checked={
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "SIP Invite Sent, Pending Acceptance" ||
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "SIP Invite Accepted, Pending Permissions" ||
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                        "Permissions Assigned, Pending Verification"
                                    }
                                  />
                                  <span className="ms-2">
                                    {selectedTab > 0 ? "" : "Mark "} Complete
                                    {selectedTab > 0 ? "d" : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          title: "Waiting for acceptance",

                          description: (
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: "#00000073" }}>
                                Watch for acceptance of your invite. Usually
                                takes up to 12 hours
                              </span>
                              {(contextValue?.data?.user_?.pca_current_stage ===
                                "SIP Invite Accepted, Pending Permissions" ||
                                contextValue?.data?.user_?.pca_current_stage ===
                                  "Permissions Assigned, Pending Verification") && (
                                <div>
                                  <Checkbox disabled checked={true} />
                                  <span className="ms-2">
                                    {selectedTab > 1 ? "" : "Mark "} Complete
                                    {selectedTab > 1 ? "d" : ""}
                                  </span>
                                </div>
                              )}
                            </div>
                          ),
                        },
                        {
                          title: "Permission",

                          description: (
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: "#00000073" }}>
                                Assign Permissions on Seller Central
                              </span>
                              <div className="d-flex">
                                <a className="me-3 text-primary text-decoration-underline">
                                  Learn how
                                </a>
                                <div>
                                  <Checkbox
                                    onChange={(e) => {
                                      if (
                                        contextValue?.data?.user_
                                          ?.pca_current_stage ===
                                        "SIP Invite Accepted, Pending Permissions"
                                      ) {
                                        const obj = {
                                          pca_current_stage:
                                            "Permissions Assigned, Pending Verification",
                                          pca_connected: 2,
                                          action_type: "permission",
                                        };
                                        message.destroy();
                                 
                                        PCAUpdate(obj);
                                      }
                                    }}
                                    disabled={
                                      !(
                                        contextValue?.data?.user_
                                          ?.pca_current_stage ===
                                        "SIP Invite Accepted, Pending Permissions"
                                      )
                                    }
                                    checked={
                                      contextValue?.data?.user_
                                        ?.pca_current_stage ===
                                      "Permissions Assigned, Pending Verification"
                                    }
                                  />
                                  <span className="ms-2">
                                    {selectedTab > 2 ? "" : "Mark "}Complete
                                    {selectedTab > 2 ? "d" : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          title: "Verification",

                          description: (
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: "#00000073" }}>
                                Permissions Assigned, pending verification.
                                Usually takes up to 12 hours
                              </span>
                            </div>
                          ),
                        },
                      ]}
                    /> */}
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      className="card mb-5 mt-5"
      style={{
        backgroundColor: "#F3F9FA",
        border: "1px solid #e5e5e5",
        boxShadow: "none",
      }}
    >
      <div className="card-body">
        <div
          className={`row  ${
            contextValue?.data?.user_?.pca_close_status === 1 ? "" : "d-none"
          }`}
        >
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
              <h4 className="mb-0 me-3">Premium Content</h4>
              <Icons type="crown" />
            </div>
            {/* <Popconfirm
              placement="left"
              title="Cancel PCA"
              description={
                <>
                  <div style={{ wordBreak: "keep-all", width: "400px" }}>
                    This will cancel your access to Premium Content and some of
                    your metrics will no longer be updated. You can reconnect
                    again if you wish. Are you sure you wish to continue?
                  </div>
                </>
              }
              onConfirm={() => {
                const obj = {
                  pca_current_stage: "",
                  pca_connected: 0,
                  pca_close_status: 0,
                  action_type: "disConnected", // right
                };
                message.destroy();
                
                PCAUpdate(
                  obj,
                  "Your Premium Content Access has been cancelled."
                );
              }}
              okText="Yes"
              cancelText="No"
            >
              <div
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="ki-duotone ki-cross-circle"
                >
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
            </Popconfirm> */}
          </div>
        </div>
        <div
          className={`row ${
            contextValue?.data?.user_?.pca_close_status !== 1 ? "" : "d-none"
          }`}
        >
          <div
            className={`${
              contextValue?.data?.user_?.pca_connected !== 1
                ? "col-xxl-6 col-md-12"
                : "col-12"
            } mb-md-3 d-flex align-items-center justify-content-between`}
          >
            <div
              className={`${
                contextValue?.data?.user_?.pca_connected === 1
                  ? "min-w-200px d-grid justify-items-center"
                  : ""
              }`}
            >
              {contextValue?.data?.user_?.pca_connected === 1 && (
                <div className="d-flex align-items-center justify-content-evenly">
                  <h4 className="mb-0 me-3">Premium Content</h4>
                  <Icons type="crown" />
                </div>
              )}
              {contextValue?.data?.user_?.pca_connected === 1 && (
                <img
                  src={`assets/media/Image 1.png`}
                  style={{
                    width: "130px",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="ms-5">
              {contextValue?.data?.user_?.pca_connected === 1 ? (
                <div>
                  <p className="fs-6 " style={{ lineHeight: "1.7" }}>
                    <b>Welcome to SIP's Premium Content.</b>
                    You've unlocked a treasure trove of invaluable insights,
                    strategies. and in-depth analysis curated to supercharge
                    your Amazon journey. Dive into our Premium Content and
                    elevate your selling game.
                  </p>
                  <Button
                    onClick={() =>
                      window.open(
                        "/User_Permissions_for_Amazon_Seller_Central_Access.pdf"
                      )
                    }
                    type="primary"
                  >
                    Learn more
                  </Button>
                </div>
              ) : (
                <div className="d-grid">
                  <span
                    className="mb-1  lh-base "
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bolder",
                      color: "#525252",
                    }}
                  >
                    Premium Content Access (PCA)
                  </span>
                  <b>
                    Unlock the Power of Exclusive Insights with Premium Content
                    Access
                  </b>
                  <span>
                    Elevate your Amazon selling strategy to unprecedented
                    heights with our Premium Content Access (PCA) module.
                  </span>
                  <p>
                    Designed exclusively for ambitious sellers focused on
                    maximizing sales and profits, PCA offers you an unparalleled
                    advantage. Dive into a treasure trove of meticulously
                    curated data and historical brand analytics.
                  </p>
                  <div className="d-flex">
                    <div className="">
                      <img
                        src={`assets/media/Image 2.jpeg`}
                        style={{
                          width: "110px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="">
                      <ul>
                        <li>
                          <b>Search Query Performance:</b> Track and analyze
                          past trends, forecast future demands and stay ahead of
                          the curve.
                        </li>
                        <li>
                          <b>Top Search Terms:</b> Discover the most lucrative
                          keywords driving traffic and sales, tailored to boost
                          your listings' visibility.
                        </li>
                        <li>
                          <b>Search Catalog Performance:</b> Evaluate your
                          portfolio against market movements, pinpoint
                          improvement areas and optimize your offerings.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span>
                    PCA goes beyond data access—it turns insights into results.
                    Get actionable recommendations to refine your strategy and
                    enhance your listings for increased profitability.
                  </span>
                  <b>
                    Dominate your niche—sign up for PCA now and drive your
                    Amazon success with data-driven decisions!
                  </b>
                </div>
              )}

              {contextValue?.data?.user_?.pca_connected === 1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "11px",
                    right: "11px",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const obj = {
                      pca_close_status: 1,
                    };
                    message.destroy();

                    PCAUpdate(obj);
                  }}
                >
                  <i
                    style={{ fontSize: "20px" }}
                    className="ki-duotone ki-cross-circle"
                  >
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
              )}
            </div>
          </div>
          <div
            className={`col-xxl-6 col-md-12 ms-auto ${
              contextValue?.data?.user_?.pca_connected === 1 ? "d-none" : ""
            }`}
          >
            <Collapse defaultActiveKey={["1"]}>
              {/* 
              Permissions Assigned, Pending Verification
              SIP Invite Accepted, Pending Permissions
              
              */}
              <Collapse.Panel header="Action" key="1">
                <Steps
                  direction="vertical"
                  current={selectedTab}
                  items={[
                    {
                      title: "Invite",

                      description: (
                        <div className="d-flex align-items-center justify-content-between">
                          <span style={{ color: "#00000073" }}>
                            Send Sellercentral invite to{" "}
                            <a className="text-primary">
                              sipadmin@argometrix.com
                            </a>
                          </span>
                          <div className="d-flex">
                            <a className="me-3 text-primary text-decoration-underline">
                              Learn how
                            </a>

                            <div>
                              <Checkbox
                                onChange={(e) => {
                                  const obj = {
                                    pca_current_stage:
                                      "SIP Invite Sent, Pending Acceptance", // Seller Central Invite Sent  // Waiting for acceptance // Permissions Assigned
                                    pca_connected: 2,
                                    action_type: "invite",
                                  };
                                  message.destroy();

                                  PCAUpdate(obj);
                                }}
                                disabled={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Sent, Pending Acceptance" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "Permissions Assigned, Pending Verification"
                                }
                                checked={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Sent, Pending Acceptance" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions" ||
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                    "Permissions Assigned, Pending Verification"
                                }
                              />
                              <span className="ms-2">
                                {selectedTab > 0 ? "" : "Mark "} Complete
                                {selectedTab > 0 ? "d" : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      title: "Waiting for acceptance",

                      description: (
                        <div className="d-flex align-items-center justify-content-between">
                          <span style={{ color: "#00000073" }}>
                            Watch for acceptance of your invite. Usually takes
                            up to 12 hours
                          </span>
                          {(contextValue?.data?.user_?.pca_current_stage ===
                            "SIP Invite Accepted, Pending Permissions" ||
                            contextValue?.data?.user_?.pca_current_stage ===
                              "Permissions Assigned, Pending Verification") && (
                            <div>
                              <Checkbox disabled checked={true} />
                              <span className="ms-2">
                                {selectedTab > 1 ? "" : "Mark "} Complete
                                {selectedTab > 1 ? "d" : ""}
                              </span>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      title: "Permission",

                      description: (
                        <div className="d-flex align-items-center justify-content-between">
                          <span style={{ color: "#00000073" }}>
                            Assign Permissions on Seller Central
                          </span>
                          <div className="d-flex">
                            <a className="me-3 text-primary text-decoration-underline">
                              Learn how
                            </a>
                            <div>
                              <Checkbox
                                onChange={(e) => {
                                  if (
                                    contextValue?.data?.user_
                                      ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions"
                                  ) {
                                    const obj = {
                                      pca_current_stage:
                                        "Permissions Assigned, Pending Verification",
                                      pca_connected: 2,
                                      action_type: "permission",
                                    };
                                    message.destroy();

                                    PCAUpdate(obj);
                                  }
                                }}
                                disabled={
                                  !(
                                    contextValue?.data?.user_
                                      ?.pca_current_stage ===
                                    "SIP Invite Accepted, Pending Permissions"
                                  )
                                }
                                checked={
                                  contextValue?.data?.user_
                                    ?.pca_current_stage ===
                                  "Permissions Assigned, Pending Verification"
                                }
                              />
                              <span className="ms-2">
                                {selectedTab > 2 ? "" : "Mark "}Complete
                                {selectedTab > 2 ? "d" : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      title: "Verification",

                      description: (
                        <div className="d-flex align-items-center justify-content-between">
                          <span style={{ color: "#00000073" }}>
                            Permissions Assigned, pending verification. Usually
                            takes up to 12 hours
                          </span>
                        </div>
                      ),
                    },
                  ]}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};
