import {
  Breadcrumb,
  Button,
  Input,
  Modal,
  Popconfirm,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect } from "react";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import { useState } from "react";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import AddData from "./lib";
import {
  configModal,
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import ArgoTooltip from "../../../../components/tooltip";
import { LoadingModal } from "../../../../components/upload-modals";

const Metric = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: "metric_name",
    sort_by: "asc",
  });

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  const deleteAction = async (id) => {
    LoadingModal("Metrics", "Deleting...");
    try {
      const response = await MakeApiCall(`metric/${id}`, "delete", null, true);

      if (response?.status) {
        Modal.destroyAll();
        message.destroy();
        message.success(response?.message);
        setLoading(true);
        setPage(1);
        getList({ page: 1 });
      } else {
        Modal.destroyAll();
        message.destroy();
        await modal.warning(configModal("Delete Metric", response?.message));
        // message.destroy();
        // message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const getList = async (data) => {
    // `page=${data?.page || page || 1}&per-page=${data?.pageSize || pageSize}&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`  --- pagination

    const response = await MakeApiCall(`metric?isAll=1`, "get", null, true);

    if (response?.status) {
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      setList([]);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const changeStatus = async (id, data) => {
    try {
      message.destroy();
      LoadingModal("Metrics", "Changing status...");
      const response = await MakeApiCall(`metric/${id}`, "put", data, true);

      if (response?.status) {
        Modal.destroyAll();
        getList({
          ...sortFilters,
        });
        message.destroy();
        message.success(response?.message);
      } else {
        Modal.destroyAll();
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      Modal.destroyAll();
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };

  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  const columns = [
    {
      title: "#",
      align: "center",
      render: (_, __, i) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {(page - 1) * pageSize + 1 + i}
          </div>
        );
      },
    },
    {
      title: "Metric ID",
      dataIndex: "id",
      filterIndex: "id",
      align: "center",
      key: "id",
      render: (e) => {
        return <div className="w-100">{e}</div>;
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(text);
            }}
            className="me-2"
          >
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete this Metric"
            description={`Are you sure to delete this ${text?.metric_name}?`}
            onConfirm={() => {
              message.destroy();

              deleteAction(text?.id);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },
    {
      title: "Metric Group",
      dataIndex: "metric_group",
      filterIndex: "metric_group",
      defaultSortOrder: "ascend",
      key: "metric_group",
    },
    {
      title: "Metric",
      dataIndex: "metric_name",
      filterIndex: "metric_name",

      key: "metric_name",
    },
    {
      title: "Metric Unit",
      dataIndex: "metric_unit",
      filterIndex: "metric_unit",
      key: "metric_unit",
    },
    {
      title: "Param Type",
      dataIndex: "param_type",
      filterIndex: "param_type",
      key: "param_type",
    },
    {
      title: "Default Lower Value",
      dataIndex: "default_lower_value",
      filterIndex: "default_lower_value",
      key: "default_lower_value",
    },
    {
      title: "Default Upper Value",
      dataIndex: "default_upper_value",
      filterIndex: "default_upper_value",
      key: "default_upper_value",
    },
    {
      title: "Inverse",
      dataIndex: "is_inverse",
      filterIndex: "is_inverse",
      key: "is_inverse",
      render: (e, row) => {
        return (
          <Tag
            onClick={() =>
              changeStatus(row?.id, {
                is_inverse: e === 1 ? 0 : 1,
              })
            }
            style={{ cursor: "pointer" }}
            color={e ? "green" : "red"}
            bordered={false}
          >
            {e ? "True" : "False"}
          </Tag>
        );
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      filterIndex: "description",
      key: "description",
      width: 250,
      ellipsis: false,
      render: (d) => {
        return (
          <ArgoTooltip rule row={3} title={d}>
            {d}
          </ArgoTooltip>
        );
      },
    },
    {
      title: "Slug",
      dataIndex: "metric_slug",
      filterIndex: "metric_slug",
      key: "metric_slug",
      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "metric_type",
      filterIndex: "metric_type",
      key: "metric_type",
      render: (e, row) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Ready",
      dataIndex: "is_ready",
      filterIndex: "is_ready",
      key: "is_ready",
      render: (e, row) => {
        return (
          <Tag
            onClick={() =>
              changeStatus(row?.id, {
                is_ready: e === 1 ? 0 : 1,
              })
            }
            style={{ cursor: "pointer" }}
            color={e === 1 ? "green" : "red"}
            size="small"
          >
            {e === 1 ? "Done" : "Pending"}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      filterIndex: "status",

      render: (e, row) => {
        return (
          <Switch
            checked={e === 1}
            onChange={(checked) =>
              changeStatus(row?.id, {
                status: e === 1 ? 0 : 1,
              })
            }
          />
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   filterIndex: "status",
    //   key: "status",
    //   render: (e) => {
    //     return (
    //       <Tag color={e === 1 ? "green" : "blue"}>
    //         {e === 1 ? "Active" : "Pause"}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    getList({
      ...sortFilters,
    });
    return () => {};
  }, []);

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title">Metrics</div>
          <div className="card-toolbar">
            <a
              className="add-btn "
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Metric
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className=" ">
              <Table
                onChange={handleChange}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={columns?.map((d) => ({
                  ...d,
                  className:
                    d?.title === "#" ? "maxWidth-table" : "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                dataSource={list}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                  defaultPageSize: 100,
                }}
                scroll={{ x: "max-content" }}
              />
            </div>
          )}
        </div>
      </div>
      {addModal && (
        <AddData
          visible={addModal}
          selectedRow={selectedRow}
          modalType={modalType}
          onClose={() => {
            setSelectedRow({});
            setAddModal(false);
          }}
          callAPI={() => {
            setPage(1);
            getList({ page: 1 });
          }}
        />
      )}
      {contextHolder}
    </Wrapper>
  );
};

export default Metric;
